import { Component, OnInit } from '@angular/core';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';
import { UserApiService } from '../../services/api/user.api.service';
import { Dropdown } from '../../../interfaces/global.interfaces';
import { Contacts as Contact } from '../../../interfaces/user.interfaces';
import { Location } from '@angular/common';
import { ChatService } from '../../services/chats/chat.service';
import { TemplateApiService } from '../../services/api/template.api.service';
import { Template, TemplateParam } from '../../../interfaces/template.interfaces';
import * as moment from 'moment';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-template-manager',
  templateUrl: './template-manager.component.html',
  styleUrls: ['./template-manager.component.scss']
})
export class TemplateManagerComponent implements OnInit {

  public selectedTemplate: Template | undefined | null = null;
  public templates: Template[] = [];
  public templateDropdownOptions: Dropdown[] = [];
  public templateParams: TemplateParam[] | null = null;
  public selectedContact: Contact | undefined | null = null;
  public contacts: Contact[] = [];
  public contactDropdownOptions: Dropdown[] = [];

  public showSpinner: boolean = false;
  public sendingTemplate: boolean = false;

  constructor(
    private userApiService: UserApiService,
    private templateApiService: TemplateApiService,
    private toastMessageService: ToastMessageService,
    private chatService: ChatService,
    private error: ErrorService,
    private location: Location,
  ) { }

  async ngOnInit(): Promise<void> {
    this.showSpinner = true;
    await this.getAllContacts();
    this.showSpinner = false;
  }

  private async getAllContacts(): Promise<void> {
    try {
      const resp = await this.userApiService.getContacts();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get contacts', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.contacts = resp.result;
        this.contactDropdownOptions = [];
        resp.result.map((user: Contact) => {
          this.contactDropdownOptions.push({
            value: user,
            label: this.chatService.getFormattedName(user.firstName, user.lastName, user.whatsappUserNumber) ?? user.whatsappUserNumber
          });
        });
      await this.getAllTemplates();
      }
    } catch (err) {
      this.error.handleError('Failed to get chat rooms.', err, 'template-manager.getAllContacts()');
      this.toastMessageService.showToastMessage('Failed to get contacts', 'toast-message-error');
    }
  }
  
  private async getAllTemplates(): Promise<void> {
    try {
      const resp = await this.templateApiService.getAllTemplates();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get templates', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.templates = resp.result;
        this.templateDropdownOptions = [];
        resp.result.map((template: Template) => {
          this.templateDropdownOptions.push({
            value: template,
            label: template.templateName
          });
        });
      }
    } catch (err) {
      this.error.handleError('Failed to get templates.', err, 'template-manager.getAllTemplates()');
      this.toastMessageService.showToastMessage('Failed to get templates', 'toast-message-error');
    }
  }

  public onContactSelected(contact: Contact) {
    this.selectedContact = this.contacts?.find(c => c.whatsappUserId === contact.whatsappUserId);
  }

  public async getTemplateParams(template: Template) {
    if (!template) {
      this.selectedTemplate = null;
      this.templateParams = null;
      return;
    }
    try {
      const resp = await this.templateApiService.getTemplateParams(template.templateId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get template params', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.templateParams = resp.result;
        this.selectedTemplate = this.templates.find(t => t.templateId === template.templateId);
      }
    } catch (err) {
      this.error.handleError('Failed to get templates params.', err, 'template-manager.getTemplateParams()');
      this.toastMessageService.showToastMessage('Failed to get template params', 'toast-message-error');
    }
  }

  public async sendTemplate() {
    this.sendingTemplate = true;
    try {
      if (!this.selectedTemplate || !this.templateParams || !this.selectedContact) return;

      const replacedTemplateBody = this.findAndReplaceTemplateBody(this.selectedTemplate.body, this.templateParams);
      if (!replacedTemplateBody) return;

      this.selectedTemplate.body = replacedTemplateBody;
      
      const resp = await this.templateApiService.sendTemplateToWhatsappUser(this.selectedTemplate, this.selectedContact);
      if (!resp) this.toastMessageService.showToastMessage('Failed to send template', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        const sentTo = this.chatService.getFormattedName(this.selectedContact.firstName, this.selectedContact.lastName, this.selectedContact.whatsappUserNumber);
        this.toastMessageService.showToastMessage(`Templated message has been sent to ${sentTo}`, 'toast-message-success');
        this.clear();
      }
    } catch (err) {
      this.error.handleError('Failed to send template.', err, 'template-manager.sendTemplate()');
      this.toastMessageService.showToastMessage('Failed to send template', 'toast-message-error');
    }
    this.sendingTemplate = false;
  }

  public getVariableValueOrVariableName(variableName: string): string {
    const param = this.templateParams?.find(p => p.variableName === variableName);
    if (param?.value) {
      if (param.variableType == 'date') return moment(param.value).format('yyyy/MM/DD');
      return param.value;
    } else {
      return `#${variableName.trim()}#`;
    }
  }

  private findAndReplaceTemplateBody(body: string, templateParams: TemplateParam[]): string | null {
    try {
      for (const param of templateParams) {
        const name = param.variableName;
        const type = param.variableType;
        let value = param.value;

        //if there is a new template and the type doesn't exist here add it 
        //can format the value however needed
        //for future, could have a template param type with enums so we know the type of param it is
        if (type == 'date') {
          value = moment(value).format('YYYY/MM/DD');
        } else if (type == 'datetime') {
          value = moment(value).format('YYYY/MM/DD hh:mm a');
        } else {
          throw new Error(`Template parm not implemented on ui. templateId: ${param.templateId} parmId: ${param.id} type: ${type}`);
        }
        body = body.replace('#' + name + '#', value);
      }
      return body;
    } catch (err) {
      this.error.handleError('Failed to find and replace template body.', err, 'template-manager.findAndReplaceTemplateBody()');
      this.toastMessageService.showToastMessage('Failed to do find and replace for the template', 'toast-message-error');
    }
    return null;
  }

  private clear() {
    this.selectedTemplate = null;
    this.templateParams = null;
    this.selectedContact = null;
  }

  public goBack() {
    this.location.back();
  }
}