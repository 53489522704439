import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Message } from '../../../interfaces/message.interfaces';
import * as _ from 'lodash';
import { MessageService } from '../../services/message.service';
import { ClaimedChatsService } from '../../services/chats/claimed.chats.service';

@Component({
  selector: 'message-window',
  templateUrl: './message-window.component.html',
  styleUrls: ['./message-window.component.scss']
})
export class MessageWindowComponent implements OnInit, OnChanges {

  @Input() public messages: Message[] = [];
  @Input() public searchTerm: string | null = '';
  @Input() public findNextResult: EventEmitter<any> = new EventEmitter();
  @Input() public findPreviousResult: EventEmitter<any> = new EventEmitter();
  @Input() public navigateToNextResultEmitter: EventEmitter<null> = new EventEmitter();
  @Input() public navigateToPreviousResultEmitter: EventEmitter<null> = new EventEmitter();
  @Input() public scrollToBottomEmitter: EventEmitter<null> = new EventEmitter();
  @Output() public messageSearch: EventEmitter<MessageSearch> = new EventEmitter();
  @ViewChild('messageWindow', { static: false }) public messageWindow: ElementRef | null = null;
  @ViewChild('image', { static: false }) public image: ElementRef | null = null;

  public searchResults: Message[] = [];
  public currentResultIndex: number = 0;
  private selectedChatId: number | null = null;
  public showScrollIcon: boolean = false;
  public imagePreview: string | null = null;
  public currentImageRotation: number = 0;
  private shouldAutoScroll: boolean = true;

  constructor(public messageService: MessageService, private claimedChatService: ClaimedChatsService) { }

  ngOnInit() {
    this.subscribeToParentEmitters();
    if (this.messages?.length == 0) return;
    this.setup();
  }

  private setup() {
    this.selectedChatId = this.messages[0]?.chatId;
    this.messageService.messages = [...this.messages];
    this.shouldAutoScroll = true;
    this.messageService.handleDisplayMessages();
    this.scrollToBottom();
  }

  public subscribeToParentEmitters(): void {
    this.navigateToNextResultEmitter?.subscribe(() => {
      this.navigateToNextResult();
    });

    this.navigateToPreviousResultEmitter?.subscribe(() => {
      this.navigateToPreviousResult();
    });

    this.scrollToBottomEmitter?.subscribe(() => {
      this.scrollToBottom();
    });
  }

  ngOnChanges() {
    //will update the messages if the chat has changed
    if (this.selectedChatId !== this.claimedChatService.getSelectedClaimChat?.chatId || this.messageService.messages?.length !== this.messages.length) {
      this.imagePreview = null;
      this.setup();
    } else {
      this.searchChatMessages();
      this.messageSearchChange();
    }
  }

  ngAfterViewChecked() {
    this.autoScrollToBottom();
  }

  public searchChatMessages() {
    const search = this.searchTerm?.trim()?.toLowerCase();

    //search only kicks in if the search length is more than or equal to 2
    if (!search || search.length <= 2) {
      this.searchResults = [];
      this.currentResultIndex = 0;
      this.messageSearchChange();
      return;
    }

    this.searchResults = _.filter(this.messages, (message) => message.body.trim().toLowerCase().includes(search));
    this.messageSearchChange();
  }

  public navigateToNextResult(): void {
    if (this.searchResults.length === 0) {
      return; // No search results
    }

    this.currentResultIndex = (this.currentResultIndex + 1) % this.searchResults.length;
    this.scrollToResult();
    this.messageSearchChange();
  }

  public navigateToPreviousResult(): void {
    if (this.searchResults.length === 0) {
      return; // No search results
    }

    this.currentResultIndex = (this.currentResultIndex - 1 + this.searchResults.length) % this.searchResults.length;
    this.scrollToResult();
    this.messageSearchChange();
  }

  public isMessageSearchMatch(messageId: number): boolean {
    if (!this.searchResults) return false;
    return this.searchResults.some(message => message.messageId === messageId);
  }

  public onScroll() {
    const scrollThreshold = 150;
    if (!this.messageWindow) return;
    const distanceFromBottom = this.messageWindow.nativeElement.scrollHeight - (this.messageWindow.nativeElement.scrollTop + this.messageWindow.nativeElement.clientHeight);
    this.showScrollIcon = distanceFromBottom > scrollThreshold;
  }

  public autoScrollToBottom() {
    if (!this.shouldAutoScroll) return;
    this.scrollToBottom();
    this.shouldAutoScroll = false;
  }

  public scrollToBottom() {
    if (this.messageWindow) this.messageWindow.nativeElement.scrollTop = this.messageWindow.nativeElement.scrollHeight;
  }

  private scrollToResult(): void {
    const resultIndex = this.searchResults[this.currentResultIndex].messageId;
    if (!this.messageWindow) return;
    const element = this.messageWindow.nativeElement.querySelector(`#message-${resultIndex}`);
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  public smoothScrollToBottom() {
    if (!this.messageWindow) return;

    this.messageWindow.nativeElement.scrollTo({
      top: this.messageWindow.nativeElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  public messageSearchChange() {
    const payload: MessageSearch = {
      searchResults: this.searchResults.length,
      currentResultIndex: this.currentResultIndex
    };
    this.messageSearch.emit(payload);
  }

  public rotateImage(degrees: number) {
    if (!this.image) return;

    this.currentImageRotation += degrees;

    const newRotation = `rotate(${this.currentImageRotation}deg)`;

    this.image.nativeElement.style.transform = newRotation;
  }

  public initializeRotation() {
    if (!this.image) return null;

    this.image.nativeElement.style.transform = `rotate(${this.currentImageRotation}deg)`;
  }

  public clearSearch() {
    this.searchTerm = '';
    this.searchResults = [];
    this.currentResultIndex = 0;
    this.messageSearchChange();
  }
}

export interface MessageSearch {
  searchResults: number;
  currentResultIndex: number;
}