import { Component, Inject, OnInit } from '@angular/core';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { ClaimedChatsService } from '../../../services/chats/claimed.chats.service';
import { DialogService } from '../dialog.service';
import { DialogComponent } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserApiService } from 'src/app/services/api/user.api.service';
import { EditWhatsappUserDialog } from '../../../../interfaces/dialog.interfaces';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'edit-whatsapp-user-dialog',
  templateUrl: './edit-whatsapp-user-dialog.component.html',
  styleUrls: ['./edit-whatsapp-user-dialog.component.scss']
})
export class EditWhatsappUserDialogComponent implements OnInit {

  public firstName: string = '';
  public lastName: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    public claimedChatsService: ClaimedChatsService,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: EditWhatsappUserDialog
  ) { }

  ngOnInit(): void {
    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;
  }

  public async updateWhatsAppUser(): Promise<void> {
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.userApiService.updateWhatsappUserDetails(this.data.whatsappUserId, this.firstName, this.lastName);
      if (!resp) this.toastMessageService.showToastMessage('Failed to update WhatsApp User details.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
        this.data.firstName = this.firstName;
        this.data.lastName = this.lastName;
        this.dialogRef.close(this.data);
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to update WhatsApp User details.', 'toast-message-error');
      this.error.handleError('Failed to update WhatsApp User details.', err, 'edit-whatsapp-user-dialog.updateWhatsAppUser()');
    }
    this.dialogService.showSpinner = false;
  }

  public cancel() {
    this.dialogRef.close();
  }
}