import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Message } from '../../../interfaces/message.interfaces';
import { ChatService } from '../../services/chats/chat.service';
import { MessageService } from '../../services/message.service';
import { ToastMessageService } from '../toast-message/toast.message.service';

@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit, OnChanges {

  @Input() public message: Message | null = null;
  @Input() public messageMatch: boolean = false; //used for if the user is searching for a message and the message matches
  @Input() public searchTerm: string | null = null;
  @Output() public previewImage: EventEmitter<string | null> = new EventEmitter();

  public messageBody: SafeHtml | null = null;
  public messageTimeStamp: string = '';
  public messageStatusIcon: string = '';
  public messageStatusIconClass: string = '';
  public isMessageMedia: boolean = false;
  public isMessageAFile: boolean = false;
  public isMessageAnImage: boolean = false;
  public isMessageAudio: boolean = false;
  public isMessageVideo: boolean = false;
  public imagePreview: boolean = false;
  private currentModifiedMessageBody: string = '';
  private unmodifiedMessageBody: string = '';

  constructor(
    private chatService: ChatService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private toastMessageService: ToastMessageService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.handleMessageSentBy();
    this.makeTextBold();
    this.handleMessageTimeStamp();
    this.handleMessageFileType();
  }

  ngOnChanges(): void { this.highlightText(); }

  private handleMessageFileType() {
    if (!this.message?.mediaUrl) return;

    const result = this.messageService.isMessageAFile(this.message.extension);
    if (!result) return;
    this.isMessageMedia = result.isMessageMedia;
    this.isMessageAnImage = result.isMessageAnImage;
    this.isMessageAFile = result.isMessageAFile;
    this.isMessageVideo = result.isMessageVideo;
    this.isMessageAudio = result.isMessageAudio;
  }

  private handleMessageSentBy() {
    if (!this.message) return;

    if (this.message.direction == 1 && this.message.systemUser) {
      return this.chatService.getFormattedName(this.message.systemUser) ?? '';
    } else {
      return this.chatService.getFormattedName(this.message.whatsappUserFirstName, this.message.whatsappUserLastName, this.message.whatsappUserNumber) ?? '';
    }
  }

  private makeTextBold() {
    if (!this.message) return;

    const boldRegex = /\*(.*?)\*/g;
    const italicRegex = /_(.*?)_/g;

    let formattedMessage = this.message.body.replace(boldRegex, '<strong>$1</strong>');
    formattedMessage = this.convertLinksToHtml(formattedMessage);
    // formattedMessage = formattedMessage.replace(italicRegex, '<em>$1</em>');

    this.currentModifiedMessageBody = formattedMessage;
    this.messageBody = this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
    this.unmodifiedMessageBody = formattedMessage;
  }

  private convertLinksToHtml(text: string): string {
    if (!this.message) return '';

    const linkRegex = /(https?:\/\/\S+)/gi;
    const textColour: string = this.message.direction ? 'white' : 'black';
    return text.replace(linkRegex, (match) => `<a style="color: ${textColour}" href="${match}" target="_blank">${match}</a>`);
  }

  private highlightText(): void {

    if (!this.message) return;

    if (this.messageMatch && this.searchTerm) {
      const highlightText = this.highlight(this.searchTerm, this.unmodifiedMessageBody);
      if (highlightText !== this.currentModifiedMessageBody) {
        this.messageBody = this.sanitizer.bypassSecurityTrustHtml(highlightText);
        this.currentModifiedMessageBody = highlightText;
      }
    } else if (!this.messageMatch && this.currentModifiedMessageBody !== this.message.body) {
      this.currentModifiedMessageBody = this.message.body;
      this.makeTextBold();
    }
  }

  private highlight(search: string, text: string): string {
    const regex = new RegExp(search.toLowerCase().trim(), 'gi');
    return text.replace(regex, (match) => `<span style="background-color: lightgrey";>${match}</span>`);
  }

  public convertHttpResponseToBase64() {
    if (!this.message || !this.message.mediaUrl || !this.message.mimeType) return;

    try {
      this.http.get(this.message.mediaUrl, { responseType: "blob" }).subscribe(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = async (event: any) => {
          const base64 = event.target.result.replace(`data:${this.message?.mimeType};base64,`, '');
          if (!this.message?.fileName || !this.message.mimeType) return;
          this.save(this.message.fileName, base64, this.message.mimeType);
        };
        reader.onerror = (event: any) => {
          this.toastMessageService.showToastMessage('Failed to download file.', 'toast-message-error');
        };
      }, (err: any) => {
        this.toastMessageService.showToastMessage('Failed to download file.', 'toast-message-error');
      });
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to download file.', 'toast-message-error');
    }
  }

  private save(name: string, base64: string, mimeType: string) {
    const blob = this.base64ToBlob(base64, 512, mimeType);

    const objectURL = window.URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    //set the link's href to the blob's url
    downloadLink.href = objectURL;
    //set the download attribute using the document name
    downloadLink.download = name;

    //append the download link to the documents body
    document.body.appendChild(downloadLink);
    //automatically click on the download link so it opens up in a new tab for the user
    downloadLink.click();
    //remove the download link from the document's body to clean up
    document.body.removeChild(downloadLink);
  }

  private base64ToBlob(b64Data: string, sliceSize: number = 512, type: string) {
    let byteCharacters = atob(b64Data);
    let byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: type });
  }

  public openFile() { window.open(this.message?.mediaUrl); }

  private handleMessageTimeStamp(): void { if (this.message?.sendDate) this.messageTimeStamp = moment(new Date(this.message.sendDate)).format('HH:mm a'); }
}