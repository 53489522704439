import { Injectable } from "@angular/core";
import { fromEvent } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  private _browserFocused: boolean = false;
  private _visiblility: string = '';

  public get browserFocused(): boolean { return this._browserFocused; }

  public get visiblility(): string { return this._visiblility; }

  public set browserFocused(value: boolean) { this._browserFocused = value; }

  public set visiblility(value: string) { this._visiblility = value; }

  constructor() { this.setupFocusDetection(); this.initializePageVisibility(); }
  
  private setupFocusDetection(): void {
    const focus$ = fromEvent(window, 'focus');
    const blur$ = fromEvent(window, 'blur');

    focus$.subscribe(() => {
      this.browserFocused = true;
    });

    blur$.subscribe(() => {
      this.browserFocused = false;
    });
  }

  private initializePageVisibility(): void {
    document.addEventListener('visibilitychange', () => {
      this.visiblility = document.visibilityState;
    });

    this.visiblility = document.visibilityState;
  }
}