<spinner *ngIf="showSpinner"></spinner>

<div *ngIf="claimedChatsService.getSelectedClaimChat" #chatWindow (dragenter)="onDragEnter($event)"
  (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onFilesDropped($event)" class="chat-window">
  <div #toolbar class="tool-bar">
    <mat-icon>person</mat-icon>
    <label class="whatsapp-username">{{ whatsAppUserName }}</label>
    <label
      class="chat-time {{ claimedChatsService.hasChatExpired ? 'expired' : 'open' }}">{{ chatWindowRemainingTime }}</label>
    <button mat-icon-button matTooltip="Search Chat Messages" matTooltipPosition="left"
      (click)="openSearchMessagesOverlay()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <mat-icon>search</mat-icon>
    </button>
    <ng-template *ngIf="searchMessagesOverlay" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="searchMessagesOverlay">
      <div class=" search-chat-window" (keydown)="handleOverlayKeydown($event)">
        <div class="content">
          <div class="search">
            <mat-icon matPrefix>search</mat-icon>
            <input #searchChatMessagesInput (keyup)="handleMessageSearchTyping($event)" matInput [(ngModel)]="search"
              type="text" placeholder="Search within chat">
            <label>
              {{ searchResults == 0 ? 0 : currentResultIndex + 1 }}
              of
              {{ searchResults }}
            </label>
          </div>
          <div class="actions">
            <button matSuffix mat-icon-button aria-label="Clear" (click)="navigateToPreviousResult()">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="navigateToNextResult()">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <button (click)="searchMessagesOverlay = false;" mat-icon-button [matMenuTriggerFor]="chatOptionsMenu"
      matTooltip="Chat Options" matTooltipPosition="left">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu style="display: none;" #chatOptionsMenu="matMenu">
      <div class="chat-options">
        <div class="heading">
          <label>Chat options</label>
        </div>
        <button class="option" mat-menu-item *ngFor="let option of chatsOptions" (click)="chatOption(option)"
          [disabled]="option.disabled">
          <mat-icon>{{ option.icon }}</mat-icon>
          {{ option.label }}
        </button>
      </div>
    </mat-menu>
  </div>
  <div *ngIf="!isDragging" class="message-window" #messageWindow [style]="{'height': calculateHeight()}">
    <message-window [navigateToNextResultEmitter]="navigateToNextResultEmitter" (messageSearch)="messageSearch($event)"
      [navigateToPreviousResultEmitter]="navigateToPreviousResultEmitter"
      [scrollToBottomEmitter]="scrollToBottomEmitter" [messages]="claimedChatsService.getSelectedClaimChat.messages"
      [searchTerm]="search">
    </message-window>
  </div>
  <div *ngIf="isDragging" class="message-window drop-zone"
    [style]="{'height': messageWindowHeight + 'px', 'max-height': messageWindowHeight + 'px'}">
    <div>
      <h2 *ngIf="!fileDropError">Drop files here.</h2>
      <ng-container *ngIf="fileDropError">
        <h4>{{ fileDropError }}</h4>
      </ng-container>
    </div>
  </div>
  <mat-card *ngIf="messageFileUrl" class="message-file-preview">
    <div class="header">
      <label>Preview {{ messageFile.fileName }}</label>
      <button mat-icon-button (click)="messageFile = null; messageFileUrl = null; fileToSend = null;"
        matTooltip="Cancel file" matTooltipPosition="left">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="image-container" *ngIf="messageFile.mimeType.includes('image')">
        <img [src]="messageFileUrl">
      </div>
      <video *ngIf="messageFile.mimeType.includes('video')" controls [src]="messageFileUrl" style="color: white;">
        Your browser does not support the video element.
      </video>
      <audio *ngIf="messageFile.mimeType.includes('audio')" controls [src]="messageFileUrl">
        Your browser does not support the audio element.
      </audio>
      <label
        *ngIf="!messageFile.mimeType.includes('image') && !messageFile.mimeType.includes('video') && !messageFile.mimeType.includes('audio')">File
        preview not
        supported</label>
    </div>
  </mat-card>
  <div #footer class="footer">
    <ng-container *ngIf="!chatService.hasChatExpired(claimedChatsService.getSelectedClaimChat.chatExpiryDate) ">
      <button *ngIf="!recording" (click)="fileInput.click()" mat-icon-button matTooltip="Attach"
        [disabled]="sendingMessage" matTooltipPosition="left">
        <input #fileInput type="file" hidden accept="{{ mimeTypes }}" (change)="onFileSelected($event.target.files)">
        <mat-icon>attach_file</mat-icon>
      </button>
      <textarea *ngIf="!recording && !messageFile?.mimeType?.includes('video')"
        (keydown.enter)="!sendingMessage ? validateMessage($event) : return;" matInput placeholder="Type a message"
        [(ngModel)]="message" (input)="handleFooterAndMessageWindowHeight()" [disabled]="sendingMessage"></textarea>
      <label *ngIf="messageFile?.mimeType?.includes('video')">
        Caption not supported with {{ messageFile?.extension }} files. Please message seperately.
      </label>
      <button *ngIf="!sendingMessage && (message || messageFile)" mat-icon-button (click)="validateMessage()"
        [matTooltip]="'Send message (Enter)\nNew line (Shift + Enter)'" matTooltipPosition="left">
        <mat-icon class="vn-buttons send-icon">send</mat-icon>
      </button>
      <button *ngIf="!message && !messageFile && !recording" mat-icon-button (click)="startRecording()"
        matTooltip="Record voice message" matTooltipPosition="left">
        <mat-icon>mic</mat-icon>
      </button>
      <button *ngIf="recording" mat-icon-button (click)="stopRecording()" matTooltip="Delete voice note"
        matTooltipPosition="left">
        <mat-icon class="vn-cancel">highlight_off</mat-icon>
      </button>
      <label *ngIf="recording">{{ recordTime }}</label>
      <button *ngIf="recording" mat-icon-button (click)="submitRecording()" matTooltip="Send voice note"
        matTooltipPosition="left">
        <mat-icon class="vn-submit">check_circle_outline</mat-icon>
      </button>
      <button *ngIf="sendingMessage" mat-icon-button [disabled]="true">
        <mat-spinner [diameter]="30"></mat-spinner>
      </button>
    </ng-container>
    <div *ngIf="chatService.hasChatExpired(claimedChatsService.getSelectedClaimChat.chatExpiryDate)"
      class="chat-expired">
      <label>Chat is expired.</label>
    </div>
  </div>
</div>
