import { Pipe, PipeTransform } from '@angular/core';
import { RoomChat } from 'src/interfaces/chat.interfaces';
import { RoomChatFilterBy, RoomChatOrderBy } from '../enums';
import * as _ from 'lodash';
import { ChatRoomService } from '../services/chats/chat.room.service';

@Pipe({
  name: 'chatRoomFilter'
})
export class ChatRoomFilterPipe implements PipeTransform {

  constructor(private chatRoomService: ChatRoomService) { }

  transform(chats: RoomChat[], selectedChatFilter: RoomChatFilterBy, selectedOrderFilter: RoomChatOrderBy): RoomChat[] {
    return this.chatRoomService.filterChats(chats, selectedChatFilter, selectedOrderFilter);
  }
}