import { Injectable } from '@angular/core';
import { HttpResponse } from '../../../interfaces/result.interfaces';
import { BaseApiService } from '../base-api.service';
import { Template } from '../../../interfaces/template.interfaces';
import { Contacts } from '../../../interfaces/user.interfaces';

@Injectable({
  providedIn: 'root'
})
export class TemplateApiService {

  private route: string = 'template/';

  constructor(private base: BaseApiService) { }

  public async getAllTemplates(): Promise<HttpResponse | null> {
    return await this.base.get('template.api.service.getAllTemplates', `${this.route}getAllTemplates`);
  }

  public async getTemplateParams(templateId: number): Promise<HttpResponse | null> {
    return await this.base.get('data.service.getTemplateParams', `${this.route}getTemplateParams?templateId=${templateId}`);
  }

  public async sendTemplateToWhatsappUser(template: Template, whatsappUser: Contacts): Promise<HttpResponse | null> {
    const payload = {
      template: template,
      whatsappUser: whatsappUser
    };

    return await this.base.post('template.api.service.sendTemplateToWhatsappUser', `${this.route}sendTemplateToWhatsappUser`, payload);
  }
}