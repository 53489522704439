import { Injectable } from '@angular/core';
import { HttpResponse } from '../../../interfaces/result.interfaces';
import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  private route: string = 'user/';

  constructor(private base: BaseApiService) { }

  public async getUserProducts(): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getUserProducts', `${this.route}getUserProducts`);
  }

  public async getOnlineUsersInGroup(groupId: number): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getOnlineUsersInGroup', `${this.route}getOnlineUsersInGroup?groupId=${groupId}`);
  }

  public async getAllGroups(producId: number): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getAllGroups', `${this.route}getAllGroups?productId=${producId}`);
  }

  public async getContacts(): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getContacts', `${this.route}getContacts`);
  }
  
  public async getWhatsAppUsers(): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getAllWhatsappUsers', `${this.route}getAllWhatsappUsers`);
  }

  public async getOnlineUsers(): Promise<HttpResponse | null> {
    return await this.base.get("user.api.service.getOnlineUsers", `${this.route}getOnlineUsers`);
  }

  public async getSystemUsers(): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getSystemUsers', `${this.route}getSystemUsers`);
  }
  
  public async getUsersGroups(): Promise<HttpResponse | null> {
    return await this.base.get('user.api.service.getUsersGroups', `${this.route}getUsersGroups`);
  }

  public async updateWhatsappUserDetails(whatsappUserId: number, firstName: string, lastName: string): Promise<HttpResponse | null> {
    const payload = {
      whatsappUserId: whatsappUserId,
      firstName: firstName,
      lastName: lastName
    };

    return await this.base.post('user.api.service.updateWhatsappUserDetails', `${this.route}updateWhatsappUserDetails`, payload);
  }

  public async addWhatsappUser(firstname: string, lastName: string, cellPhoneNumber: string): Promise<HttpResponse | null> {
    const payload = {
      firstName: firstname,
      lastName: lastName,
      cellPhoneNumber: cellPhoneNumber
    };

    return await this.base.post('user.api.service.addWhatsappUser', `${this.route}addWhatsappUser`, payload);
  }
  
  public async changeProduct(productId: number): Promise<HttpResponse | null> {
    const payload = {
      productId: productId
    };

    return await this.base.post('user.api.service.changeProduct', `${this.route}changeProduct`, payload);
  }
}