import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog.component';
import { DialogService } from '../dialog.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { Dropdown, Groups } from '../../../../interfaces/global.interfaces';
import { UserApiService } from '../../../services/api/user.api.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ReassignChatDialog } from '../../../../interfaces/dialog.interfaces';
import * as _ from 'lodash';
import { ClaimedChatsService } from '../../../services/chats/claimed.chats.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'reassign-chat-dialog',
  templateUrl: './reassign-chat-dialog.component.html',
  styleUrls: ['./reassign-chat-dialog.component.scss']
})
export class ReassignChatDialogComponent implements OnInit {

  public userOptions: Dropdown[] = [];
  public selectedUserOptionId: number | null = null;
  public groupOptions: Dropdown[] = [];
  public selectedGroupOptionId: number | null = null;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    private userApiService: UserApiService,
    private authService: AuthService,
    private chatApiService: ChatApiService,
    private claimedChatsService: ClaimedChatsService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: ReassignChatDialog
  ) { }

  async ngOnInit() {
    this.dialogService.showSpinner = true;
    await this.getUsersInGroup();
    await this.getAllGroups();
    this.dialogService.showSpinner = false;
  }

  private async getUsersInGroup() {
    try {
      const groupIds: number[] = _.uniq(this.data.chats.map(chat => chat.groupId));
      if (groupIds.length > 1) return;
      const resp = await this.userApiService.getOnlineUsersInGroup(groupIds[0]);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get users in group.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        if (!resp.result || resp.result.length == 0) return;
        resp.result.map((user: { id: number; loginName: string; }) => {
          this.userOptions.push({
            value: user.id,
            label: user.loginName
          })
        });
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get users in group.', 'toast-message-error');
      this.error.handleError('Failed to get users in group.', err, 'reassign-chat-dialog.getUsersInGroup()');
    }
  }

  private async getAllGroups() {
    if (!this.authService.user?.selectedProduct?.productId) return;
    try {
      const resp = await this.userApiService.getAllGroups(this.authService.user.selectedProduct.productId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get all groups.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        resp.result.map((group: Groups) => {
          this.groupOptions.push({
            label: group.txt,
            value: group.id
          });
        });
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get all groups.', 'toast-message-error');
      this.error.handleError('Failed to get all groups.', err, 'reassign-chat-dialog.getAllGroups()');
    }
  }

  public async reassignChat(): Promise<void> {
    this.dialogService.showSpinner = true;
    try {
      //reassigning chat to a group
      if (this.selectedGroupOptionId) {
        const chatIds: number[] = this.data.chats.map(chat => chat.chatId);
        const resp = await this.chatApiService.reassignChatsToGroup(this.selectedGroupOptionId, chatIds);
        if (!resp) this.toastMessageService.showToastMessage('Failed to reassign chat to group.', 'toast-message-error');
        else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
        else {
          this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
          this.claimedChatsService.handleClosingAndReassigningChatToGroup();
          this.dialogRef.close(true);
        }
      }
      //reassigning chat to a user
      else if (this.selectedUserOptionId) {
        const resp = await this.chatApiService.reassignChatToUser(this.data.chats, this.selectedUserOptionId);
        if (!resp) this.toastMessageService.showToastMessage('Failed to reassign chat to user.', 'toast-message-error');
        else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
        else {
          this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
          const loginName: string | undefined = this.userOptions.find(u => u.value === this.selectedUserOptionId)?.label;
          this.dialogRef.close(true);
          if (loginName) {
            this.claimedChatsService.handleChatsReassignToUser(this.data.chats, this.selectedUserOptionId, loginName);
            this.claimedChatsService.setSelectedClaimChat = null;
          }
        }
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to reassign chat.', 'toast-message-error');
      this.error.handleError('Failed to reassign chat.', err, 'reassign-chat-dialog.reassignChat()');
    }
    this.dialogService.showSpinner = false;
  }

  public onUserSelect(userId: number): void { this.selectedUserOptionId = userId; }

  public onGroupSelect(groupId: number): void { this.selectedGroupOptionId = groupId; }

  public cancel(): void { this.dialogRef.close(); }
}