<div *ngIf="chat" [ngClass]="{ 'chat-selected': chat.chatSelected, 'reassigned': chat.reassigned }"
  class="chat-item text-black" (click)="chatSelect()">
  <div class="content">
    <div class="heading">
      <label class="display-name">{{ whatsAppDisplayName }}</label>
      <label [class.chatExpired]="chatExpired">{{ chatStatus }}</label>
      <label>{{ messageTimeStamp }}</label>
    </div>
    <div class="sub-heading">
      <label class="reference">{{ chat.refNo ? 'Reference: ' + chat.refNo : 'No ref number.' }}</label>
      <label class="claimed-by">{{ claimedBy }}</label>
    </div>
    <div class="items">
      <div class="message">
        <mat-icon
          class="message-status {{ messageService.handleMessageStatusIcon(lastMessage.sendDate, lastMessage.readDate, lastMessage.deliveredDate, lastMessage.undeliveredDate).messageStatusIconClass }}">
          {{ messageService.handleMessageStatusIcon(lastMessage.sendDate, lastMessage.readDate, lastMessage.deliveredDate, lastMessage.undeliveredDate).messageStatusIcon }}
        </mat-icon>
        <mat-icon *ngIf="messageMediaIcon">
          {{ messageMediaIcon }}</mat-icon>
        <label class="body">{{ messageBody }}</label>
        <label [matBadge]="chat.unreadMessages" class="message-status unread"></label>
      </div>
    </div>
  </div>
</div>
