<div class="side-menu text-white">
  <div class="side-menu-logo">
    <img [src]="productService.smallLogo" alt="Avecs" *ngIf="productService.smallLogo != null">
  </div>

  <hr>

  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/chats'" routerLinkActive="active" matTooltip="Chats"
        matTooltipPosition="right">
        <mat-icon> chat</mat-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/chat-rooms'" routerLinkActive="active" matTooltip="Rooms"
        matTooltipPosition="right">
        <mat-icon>meeting_rooms</mat-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/contacts'" routerLinkActive="active" matTooltip="Contacts"
        matTooltipPosition="right">
        <mat-icon>person_add</mat-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/online-users'" routerLinkActive="active" matTooltip="Online Users"
        matTooltipPosition="right">
        <mat-icon>lightbulb</mat-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/template-manager'" routerLinkActive="active" matTooltip="Template Manager"
        matTooltipPosition="right">
        <mat-icon>folder</mat-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/closed-chats'" routerLinkActive="active" matTooltip="Closed Chats"
        matTooltipPosition="right">
        <mat-icon>closed_caption</mat-icon>
      </a>
    </li>

    <!-- make sure logout is the last item because of styling reasons -->
    <li class="nav-item">
      <a class="nav-link" (click)="socketService.logout()" routerLinkActive="active" matTooltip="Logout"
        matTooltipPosition="right">
        <mat-icon>logout</mat-icon>
      </a>
    </li>
  </ul>
</div>
