import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { SocketService } from './services/socket.service';
import { ProductService } from './services/products.service';
import { ChatApiService } from './services/api/chat.api.service';
import { PermissionsService } from './services/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    public authService: AuthService,
    private permissionService: PermissionsService,
    private chatApiService: ChatApiService,
    public productService: ProductService, //used in app component html
    public socketService: SocketService) { }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe(async (loggedIn) => {
      //socket not connected and the user is logged in (refresh browser)
      if (!this.socketService.connected && loggedIn) {
        this.socketService.notConnectedReason = 'Establishing connection to server. Please wait';
        await this.chatApiService.claimChats();
        this.socketService.setup();
      }
      //user logged in
      else if (loggedIn) {
        this.socketService.setup();
      }
    });
    this.permissionService.requestNotificationsPermissions();
  }

  ngOnDestroy() {
    this.socketService.logout();
  }
}