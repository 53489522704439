import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private messages: string[] = [];
  private error: Error[] = [];
  private stack: string[] = [];

  private displaySource = new BehaviorSubject<boolean>(false);
  private alertSource = new BehaviorSubject<string | Error>("");
  private alertSourceHeader = new BehaviorSubject<string>("");

  constructor() { }

  public handleError(message: string, err: Error | unknown, caller: string) {
    this.messages.push(message);
    this.error.push(err as Error);
    this.stack.push(caller);
    console.error(message);
    console.error(err);
    this.showMessage(message, err as Error);
  }

  public showNotification(heading: string, message: string) {
    console.log(heading, '-> ', message);
    this.showMessage(heading, message);
  }

  public getHistoricalErrors() {
    return {
      messages: this.messages,
      error: this.error,
      stack: this.stack
    };
  }

  public clearErrors() {
    this.messages = [];
    this.error = [];
    this.stack = [];
  }

  private showMessage(heading: string, message: string | Error) {
    this.alertSourceHeader.next(heading);
    this.alertSource.next(message);
    this.displaySource.next(true);
  }
}
