import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ClaimedChatsService } from '../../services/chats/claimed.chats.service';
import { Chat } from '../../../interfaces/chat.interfaces';
import { Message } from '../../../interfaces/message.interfaces';
import { ChatService } from '../../services/chats/chat.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent implements OnInit {

  @Input() chat: Chat | null = null;
  @Input() multiSelectMode: boolean = false;
  @Output() onChatSelect: EventEmitter<Chat> = new EventEmitter();

  public whatsAppDisplayName: string | undefined | null = undefined;
  public lastMessage: Message | undefined | null = undefined;
  public messageBody: string | undefined | null  = undefined;
  public messageTimeStamp: string = '';
  public messageStatusIcon: string = '';
  public messageStatusIconClass: string = '';
  public messageMediaIcon: string = '';
  public chatExpired: boolean = false;
  public claimedBy: string | undefined = '';
  public chatStatus: string = '';

  constructor(
    public claimedChatsService: ClaimedChatsService,
    public messageService: MessageService,
    public chatService: ChatService
  ) { }

  ngOnInit(): void { this.setup(); }

  private setup() {
    this.lastMessage = this.chat?.messages[this.chat.messages.length - 1];
    this.messageBody = this.lastMessage?.body;
    if(this.chat) this.whatsAppDisplayName = this.chatService.getFormattedName(this.chat.firstName, this.chat.lastName, this.chat.whatsappUserNumber);
    this.handleMessageTimeStamp();
    this.handleMessageMediaIcon();
    this.handleClaimedBy();
    this.handleChatStatus();
  }

  public chatSelect() {
    if (!this.chat) return;

    this.chat.reassigned = false;
    if (this.multiSelectMode && this.chat) this.chat.chatSelected = !this.chat.chatSelected;
    else this.chat.chatSelected = true;
    if (!this.multiSelectMode) this.chat.unreadMessages = null;
    this.onChatSelect.emit(this.chat);
  }

  private handleMessageTimeStamp(): void {
    if (!this.lastMessage) return;

    const message = moment(new Date(this.lastMessage.sendDate));
    if (message.isSame(moment(), 'day')) this.messageTimeStamp = moment(message).format('hh:mm a')
    else if (message.isSame(moment().clone().subtract(1, 'day'), 'day')) this.messageTimeStamp = 'Yesterday';
    else this.messageTimeStamp = moment(message).format('YYYY/MM/DD');
  }

  private handleMessageMediaIcon(): void {
    if (!this.lastMessage) return;

    //last message is not a media message
    if (!this.lastMessage.mediaUrl) return;

    if (!this.messageBody) {
      this.messageBody = this.lastMessage.friendlyName;
      if (this.lastMessage.friendlyName) this.messageBody = this.lastMessage.friendlyName;
      else if (this.lastMessage.fileName) this.messageBody = this.lastMessage.fileName;
    }

    const result = this.messageService.isMessageAFile(this.lastMessage.extension);
    if (!result) return;

    //setting the message media icon  
    if (result.isMessageAnImage) this.messageMediaIcon = 'image';
    else if (result.isMessageAFile) this.messageMediaIcon = 'file_copy';
    else if (result.isMessageAudio) this.messageMediaIcon = 'mic';
    else if (result.isMessageVideo) this.messageMediaIcon = 'videocam';
    else this.messageMediaIcon = 'insert_drive_file';
  }

  private handleChatStatus() {
    if (!this.chat) return;

    const expired = this.chatService.hasChatExpired(this.chat.chatExpiryDate);
    this.chatExpired = expired;
    if (expired) this.chatStatus = 'Expired'
    else this.chatStatus = 'Open';
  }

  private handleClaimedBy() {
    if (!this.chat) return;
    this.claimedBy = this.chatService.getFormattedName(this.chat.claimedBy.split('@')[0]);
  }
}