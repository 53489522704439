import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DisplayMessages, Message, MessageFileResult, MessageStatus } from "../../interfaces/message.interfaces";
import * as _ from "lodash";
import { ClaimedChatsService } from "./chats/claimed.chats.service";
import { MessageStatusInternalMessage } from "../../interfaces/internal.message.interfaces";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private _messages: Message[] = [];
  private _displayMessages: DisplayMessages[] = [];

  public get messages(): Message[] { return this._messages; }
  public get displayMessages(): DisplayMessages[] { return this._displayMessages; }

  public set messages(messages: Message[]) { this._messages = messages; }
  public set displayMessages(messages: DisplayMessages[]) { this._displayMessages = messages; }

  constructor(private claimedChatService: ClaimedChatsService, private sanitizer: DomSanitizer) { }

  public handleDisplayMessages(): void {
    this.displayMessages = [];
    const messageDates = this.getMessageDates();

    for (const date of messageDates) {
      const messages = this.getMessagesForDate(date);
      this.displayMessages.push({
        date: date,
        messages: messages
      });
    }
  }

  private getMessageDates(): string[] {
    const uniqueDates = _.uniq(this.messages.map(message => moment(new Date(message.sendDate)).format('YYYY-MM-DD')));
    const sortedDates = _.sortBy(uniqueDates, date => moment(new Date(date)));
    return sortedDates.map(date => this.formatMessageDate(date));
  }

  private formatMessageDate(dateString: string): string {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    const messageDate = moment(new Date(dateString));

    if (messageDate.isSame(today, 'day')) {
      return 'Today';
    } else if (messageDate.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else if (messageDate.isSame(today, 'week')) {
      // if within the same week we going to display the day of the week
      return messageDate.format('dddd');
    } else {
      return messageDate.format('YYYY-MM-DD');
    }
  }

  public getMessagesForDate(date: string): Message[] {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');

    return this.messages.filter(message => {
      const messageDate = moment(new Date(message.sendDate));

      if (messageDate.isSame(today, 'day') && date === 'Today') {
        return true;
      } else if (messageDate.isSame(yesterday, 'day') && date === 'Yesterday') {
        return true;
      } else if (messageDate.isSame(today, 'week') && date === messageDate.format('dddd')) {
        return true;
      } else if (date === messageDate.format('YYYY-MM-DD')) {
        return true;
      }

      return false;
    });
  }

  public handleMessageStatusupdate(message: MessageStatusInternalMessage) {
    if (!this.claimedChatService.claimedChatsPanel) return;

    for (const chatPanel of this.claimedChatService.claimedChatsPanel) {
      for (const chat of chatPanel.chats) {
        const messageIndex = chat.messages.findIndex(m => m.messageId === message.messageId);

        if (messageIndex !== -1) {
          if (!chat.messages[messageIndex].deliveredDate) chat.messages[messageIndex].deliveredDate = message.deliveredDate;
          if (!chat.messages[messageIndex].readDate) chat.messages[messageIndex].readDate = message.readDate;
          if (!chat.messages[messageIndex].undeliveredDate) chat.messages[messageIndex].undeliveredDate = message.undeliveredDate;
          return;
        }
      }
    }
  }

  public handleMessageStatusIcon(sendDate: Date, readDate: Date, deliveredDate: Date, undeliveredDate: Date): MessageStatus {
    if (undeliveredDate) {
      return {
        messageStatusIcon: 'error',
        messageStatusIconClass: 'error'
      };
    } else if (readDate) {
      return {
        messageStatusIcon: 'done_all',
        messageStatusIconClass: 'read'
      };
    }
    else if (deliveredDate) return { messageStatusIcon: 'done_all', messageStatusIconClass: '' };
    else if (sendDate) return { messageStatusIcon: 'done', messageStatusIconClass: '' };
    else return { messageStatusIcon: '', messageStatusIconClass: '' };
  }

  public isMessageAFile(extension: string | null | undefined): MessageFileResult | null {

    if (!extension) return null;

    const result = {
      isMessageMedia: true,
      isMessageAnImage: false,
      isMessageAFile: false,
      isMessageVideo: false,
      isMessageAudio: false
    };

    const imageExtensions: string[] = ['jpg', 'png', 'jpeg'];
    const audioExtensions: string[] = ['mp3', 'ogg', 'amr', 'wav'];
    const mediaExtensions: string[] = ['mp4', 'mpeg'];

    if (imageExtensions.includes(extension)) result.isMessageAnImage = true;
    else if (extension == 'pdf') result.isMessageAFile = true;
    else if (mediaExtensions.includes(extension)) result.isMessageVideo = true;
    else if (audioExtensions.includes(extension)) result.isMessageAudio = true;

    return result;
  }

  public sanitizeImageUrl(imageUrl: string): SafeUrl { return this.sanitizer.bypassSecurityTrustUrl(imageUrl); }
}