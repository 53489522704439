import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog.component';
import { DialogService } from '../dialog.service';
import { ClaimedChatsService } from '../../../services/chats/claimed.chats.service';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { Chat } from '../../../../interfaces/chat.interfaces';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'add-edit-reference-number-dialog',
  templateUrl: './add-edit-reference-number-dialog.component.html',
  styleUrls: ['./add-edit-reference-number-dialog.component.scss']
})
export class AddEditReferenceNumberDialogComponent implements OnInit {
  public referenceNumber: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    public claimedChatsService: ClaimedChatsService,
    private chatApiService: ChatApiService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService
  ) { }

   ngOnInit(): void { this.referenceNumber = this.claimedChatsService.getSelectedClaimChat?.refNo ?? ''; }
  
  public async updateReferenceNumber(): Promise<void> {
    if (!this.claimedChatsService.getSelectedClaimChat) return;
    
    this.dialogService.showSpinner = true;
    try {
      const chat: Chat = this.claimedChatsService.getSelectedClaimChat;
      const resp = await this.chatApiService.updateChatRefNo(chat.chatId, this.referenceNumber);
      if (!resp) this.toastMessageService.showToastMessage('Failed to update chat reference number.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
        chat.refNo = this.referenceNumber;
        this.claimedChatsService.setSelectedClaimChat = { ...chat };
        this.dialogRef.close(true);
      }
    } catch (err) {
      this.error.handleError('Failed to update chat reference number', err, 'add-edit-reference-number-dialog.updateReferenceNumber()');
      this.toastMessageService.showToastMessage('Failed to update chat reference number.', 'toast-message-error');
    }
    this.dialogService.showSpinner = false;
  }

  public cancel() {
    this.dialogRef.close();
  }
}