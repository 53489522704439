<div class="content">
  <h2 class="title">Edit Whatsapp User</h2>
  <mat-form-field class="form-field">
    <mat-label>First Name</mat-label>
    <input name="firstName" required matInput [(ngModel)]="firstName">
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>Last Name</mat-label>
    <input name="lastName" required matInput [(ngModel)]="lastName">
  </mat-form-field>
  <div class="actions">
    <!-- <button mat-button *ngIf="data.showConversationButton" (click)="onConvoClick(data.number)" class="form-button"
      style="color: orange;">Go to
      conversation</button> -->
    <button mat-button [disabled]="!firstName" (click)="updateWhatsAppUser()">Save</button>
    <button mat-button (click)="cancel()" class="form-button">Cancel</button>
  </div>
</div>
