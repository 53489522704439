import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../../interfaces/message.interfaces';
import { ChatService } from './chats/chat.service';
import { ProductService } from './products.service';
import { ClaimedChatsService } from './chats/claimed.chats.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notification: Notification | null = null;

  constructor(
    private chatService: ChatService,
    private claimedChatService: ClaimedChatsService,
    private productService: ProductService
  ) { }

  public messageReceivedNotification(message: Message) {
    const whatsappUserName = this.chatService.getFormattedName(message.whatsappUserFirstName, message.whatsappUserLastName, message.whatsappUserNumber);
    const options: NotificationOptions = {
      body: `${whatsappUserName}\n${message.body}`,
      icon: this.productService.smallLogo ?? '',
      dir: 'auto',
      data: {
        chatId: message.chatId
      },
    };

    if (message.mediaUrl) options.image = message.mediaUrl;

    this.notification = new Notification('Whatsapp', options);
    this.notification.onclick = () => {
      const chatIndex = this.claimedChatService.getClaimedChats.findIndex(chat => chat.chatId === message.chatId);
      if (chatIndex === -1) return;

      this.claimedChatService.setSelectedClaimChat = this.claimedChatService.getClaimedChats[chatIndex];
      if(this.claimedChatService.getSelectedClaimChat) this.claimedChatService.getSelectedClaimChat.chatSelected = true;
    };
  }
}