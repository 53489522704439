import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as L from "leaflet";
import "leaflet.markercluster";
import { latLng, tileLayer, marker, icon, Popup, Layer, Control, Map, } from 'leaflet';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
import * as _ from 'lodash'

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  myLatLng: any = [];
  initialRenderComplete = false;

  fitBounds: any = undefined;
  map: Map;
  LAYER_OSM = {
    id: "openstreetmap",
    name: "Open Street Map",
    enabled: false,
    layer: L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
      attribution: "Open Street Map"
    })
  };


  layersControlOptions = { position: "bottomright" };
  baseLayers = {
    "Open Street Map": this.LAYER_OSM.layer
  };
  options = {
    zoom: 5,
    center: latLng([-29.8225845, 30.8591048]),
    attributionControl: false,
    maxZoom: 18,
  };

  markerClusterData: any[] = [];  

  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterOptions: L.MarkerClusterGroupOptions = {
   iconCreateFunction: (cluster) => {
     var markers = cluster.getAllChildMarkers();
     let hasAlert = false
      let found = _.find(markers, (d) => {
       return d.hasAlert == true
     })
      if (found) {
       hasAlert = true
     }
     var html = '<div><span>' + markers.length + '</span></div>';
     if (hasAlert) {
       return L.divIcon({ html: html, className: 'marker-cluster marker-cluster-large', iconSize: L.point(40, 40) });
     }
     else {
       return L.divIcon({ html: html, className: 'marker-cluster marker-cluster-small', iconSize: L.point(40, 40) });
     }
   },
  //  spiderfyOnMaxZoom: false, showCoverageOnHover: true, zoomToBoundsOnClick: false 
  };

 markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  constructor(private el: ElementRef,
    private _ngZone: NgZone,

    ) { }

  async ngOnInit() {
    this.map = L.map('map').setView([-32.9359151,27.7746228], 1);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'edupala.com © Angular LeafLet',
    }).addTo(this.map);
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.clearAllEventListeners();
      this.map.remove();
    }   
    this.el.nativeElement.remove();
  }

}
