import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog.component';
import { DialogService } from '../dialog.service';
import { UserApiService } from '../../../services/api/user.api.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { ErrorService } from '../../../services/error.service';
import { HttpResponse } from '../../../../interfaces/result.interfaces';

@Component({
  selector: 'add-whatsapp-user-dialog',
  templateUrl: './add-whatsapp-user-dialog.component.html',
  styleUrls: ['./add-whatsapp-user-dialog.component.scss']
})
export class AddWhatsappUserDialogComponent {

  public firstName: string = '';
  public lastName: string = '';
  public cellPhoneNumber: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService
  ) { }

  public async addWhatsappUser(): Promise<void> {
    const canAddNewWhatsAppUser = this.canAddNewWhatsAppUser();
    if (canAddNewWhatsAppUser.errorCode !== 0) {
      this.toastMessageService.showToastMessage(canAddNewWhatsAppUser.errorMessage, 'toast-message-error');
      return;
    }
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.userApiService.addWhatsappUser(this.firstName, this.lastName, this.cellPhoneNumber);
      if (!resp) this.toastMessageService.showToastMessage('Failed to add whatsapp user.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
        this.dialogRef.close();
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to add whatsapp user.', 'toast-message-error');
      this.error.handleError('Failed to add whatsapp user', err, 'add-whatsapp-user-dialog.addWhatsappUser()');
    }
    this.dialogService.showSpinner = false;
  }

  private canAddNewWhatsAppUser(): HttpResponse {
    const splitArr = this.cellPhoneNumber.split('+');

    //if the length is one there is no '+'
    if (splitArr.length == 1) {
      return {
        errorCode: -1,
        errorMessage: `Please put a '+' infront of the number`
      };
    }
    //not a number
    else if (!+splitArr[1].trim()) {
      return {
        errorCode: -1,
        errorMessage: 'The cell phone number is not a valid number'
      };
    }
    else return { errorCode: 0, errorMessage: '' };
  }

  public canAddWhatsAppUser() {
    if (this.lastName && this.cellPhoneNumber && this.firstName) return false;
    else if (this.lastName && !this.cellPhoneNumber) return true;
    else if (this.lastName && this.cellPhoneNumber) return true;
    else if (!this.cellPhoneNumber) return true;
    else return false;
  }

  public cancel() {
    this.dialogRef.close();
  }
}