<div class="background"></div>

<div *ngIf="waitingResponse" class="center disable-background">
  <spinner [reason]=""></spinner>
</div>

<div class="modal">
  <div class="box">
    <img src="./assets/logo.png" class="logo">
    <div class="login-form">
      <mat-form-field class="full-width">
        <mat-label>Username</mat-label>
        <input matInput type="text" required [(ngModel)]="userName" placeholder="*Username">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input matInput type="password" required [(ngModel)]="password" placeholder="*Password">
      </mat-form-field>
      <button mat-flat-button type="submit" (click)="login()" [disabled]="!userName || !password"
        class="button full-width">Login</button>
    </div>
  </div>
</div>
