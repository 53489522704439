import { Component, Inject, OnInit } from '@angular/core';
import { Message } from '../../../../interfaces/message.interfaces';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ChatService } from '../../../services/chats/chat.service';
import { DialogService } from '../dialog.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { DialogComponent } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewChatMessagesDialog } from '../../../../interfaces/dialog.interfaces';

@Component({
  selector: 'preview-messages-dialog',
  templateUrl: './preview-messages-dialog.component.html',
  styleUrls: ['./preview-messages-dialog.component.scss']
})
export class PreviewMessagesDialogComponent implements OnInit {
  
  public messages: Message[] = [];
  public whatsAppUserName: string | undefined = '';

  constructor(
    private chatApiService: ChatApiService,
    private chatService: ChatService,
    private dialogService: DialogService,
    private toastMessageService: ToastMessageService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreviewChatMessagesDialog
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getMessagesForChatId();
    this.whatsAppUserName = this.chatService.getFormattedName(this.data.chat.firstName, this.data.chat.lastName, this.data.chat.whatsappUserNumber);
  }

  private async getMessagesForChatId(): Promise<void> {
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.chatApiService.getMessagesForChatId(this.data.chat.chatId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get messages for chat.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else this.messages = resp.result;
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get messages for chat.', 'toast-message-error');
    }
    this.dialogService.showSpinner = false;
  }
}
