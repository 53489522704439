<spinner *ngIf="showSpinner"></spinner>

<div class="page-toolbar toolbar">
  <div class="left">
    <button mat-icon-button (click)="goBack()" matTooltip="Go back">
      <mat-icon class="toolbar-buttons">arrow_back</mat-icon>
    </button>
  </div>
  <div class="search-container">
    <div class="search">
      <mat-icon>search</mat-icon>
      <input matInput placeholder="Search Closed Chats" autocomplete="off"
        (keyup)="filterClosedChats($event.target.value)">
    </div>
  </div>
  <label>Closed Chats</label>
</div>

<!-- Search Filters -->
<div *ngIf="!showSpinner" class="search-filters">
  <mat-form-field appearance="fill" class="dateSearch" matTooltip="Select a date to filter closed chats">
    <mat-label>Choose a date to start*</mat-label>
    <input matInput [matDatepicker]="picker" readonly [(ngModel)]="selectedDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <drop-down (onDropdownOptionSelect)="selectedSystemUserId = $event" filter="true" placeHolder="Select a system user"
    filterPlaceHolder="Filter System Users" [options]="systemUsers" [showClear]="true"
    matTooltip="Select last claimed by system user">
  </drop-down>
  <drop-down (onDropdownOptionSelect)="selectedWhatsappUserId = $event" filter="true"
    placeHolder="Select a whatsapp user" filterPlaceHolder="Filter WhatsApp Users" [options]="whatsappUsers"
    [showClear]="true">
  </drop-down>
  <button mat-button (click)="getClosedChats()" [disabled]="!selectedDate || tableSpinner">
    <span *ngIf="!tableSpinner">
      Search
    </span>
    <mat-spinner *ngIf="tableSpinner" [diameter]="35" class="table-spinner"></mat-spinner>
  </button>

</div>

<div class="closed-chats">
  <table mat-table [dataSource]="closedChats" matSort>
    <ng-container matColumnDef="refNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference No.</th>
      <td mat-cell *matCellDef="let closedChat"> {{ !closedChat.refNo ? 'None' :  closedChat.refNo }} </td>
    </ng-container>

    <ng-container matColumnDef="whatsappUserName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>WhatsApp User</th>
      <td mat-cell *matCellDef="let closedChat">
        {{ closedChat.whatsappUserName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="body">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Body</th>
      <td mat-cell *matCellDef="let closedChat"> {{ closedChat.body }} </td>
    </ng-container>

    <ng-container matColumnDef="sendDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent Date</th>
      <td mat-cell *matCellDef="let closedChat"> {{ formatDate( closedChat.sendDate) }} </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
      <td mat-cell *matCellDef="let closedChat"> {{ closedChat.group }} </td>
    </ng-container>

    <ng-container matColumnDef="lastClaimedBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Claimed By</th>
      <td mat-cell *matCellDef="let closedChat"> {{ closedChat.lastClaimedBy || '-' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="onClosedChatClick(row, i)" *matRowDef="let row; columns: displayedColumns; let i = index;"
      [ngClass]="{'highlight': selectedRowIndex == i }"></tr>
  </table>

  <div class="no-records"
    *ngIf="!showSpinner && (closedChats.data.length === 0 || closedChats.filteredData.length == 0)">No
    Closed chats found</div>

  <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of closed chats">
  </mat-paginator>
</div>
