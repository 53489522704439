import { Injectable } from '@angular/core';
import { HttpResponse } from '../../../interfaces/result.interfaces';
import { BaseApiService } from '../base-api.service';
import { Chat, RoomChat } from '../../../interfaces/chat.interfaces';
import { MessageFile } from '../../../interfaces/message.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatApiService {

  private route: string = 'chat/';
  constructor(private base: BaseApiService) { }

  public async claimChats(): Promise<HttpResponse | null> {
    return await this.base.post('chat.api.service.claimChats', `${this.route}claimChats`, null);
  }

  public async getClaimedChats(): Promise<HttpResponse | null> {
    return await this.base.get('chat.api.service.getClaimedChats', `${this.route}getClaimedChats`);
  }

  public async getMessagesForChatId(chatId: number): Promise<HttpResponse | null> {
    return await this.base.get('chat.api.service.getMessagesForChatId', `${this.route}getMessagesForChatId?chatId=${chatId}`);
  }

  public async closeChats(chats: Chat[] | RoomChat[]): Promise<HttpResponse | null> {
    return await this.base.post('chat.api.service.closeChats', `${this.route}closeChats`, chats);
  }

  public async reassignChatsToGroup(groupId: number, chatIds: number[]): Promise<HttpResponse | null> {
    const payload = {
      chatIds: chatIds,
      groupId: groupId
    };

    return await this.base.post('chat.api.service.reassignChatsToGroup', `${this.route}reassignChatsToGroup`, payload);
  }

  public async reassignChatToUser(chats: Chat[], reassignToUserId: number): Promise<HttpResponse | null> {
    const payload = {
      chats: chats,
      reassignToUserId: reassignToUserId
    };

    return await this.base.post('chat.api.service.reassignChatToUser', `${this.route}reassignChatToUser`, payload);
  }

  public async updateChatNotes(chatId: number, notes: string): Promise<HttpResponse | null> {
    const payload = {
      chatId: chatId,
      notes: notes
    };

    return await this.base.post('chat.api.service.updateChatNotes', `${this.route}updateChatNotes`, payload);
  }

  public async updateChatRefNo(chatId: number, refNo: string): Promise<HttpResponse | null> {
    const payload = {
      chatId: chatId,
      refNo: refNo,
    };

    return await this.base.post('chat.api.service.updateChatRefNo', `${this.route}updateChatRefNo`, payload);
  }

  public async claimChat(chatIds: number[], groupId: number): Promise<HttpResponse | null> {
    const json = {
      chatIds: chatIds,
      groupId: groupId
    };
    return await this.base.post('chat.api.service.claimChat', `${this.route}claimChat`, json);
  }

  public async getChatRooms(): Promise<HttpResponse | null> {
    return await this.base.get('chat.api.service.getChatRooms', `${this.route}getChatRooms`);
  }

  public async getClosedChatsFromDateAndOptionallyUser(fromDate: Date, systemUserId?: number, whatsappUserId?: number): Promise<HttpResponse | null> {
    const payload = {
      fromDate: fromDate,
      systemUserId: systemUserId,
      whatsappUserId: whatsappUserId
    };

    return await this.base.post('chat.api.service.getClosedChatsFromDateAndOptionallyUser', `${this.route}getClosedChatsFromDateAndOptionallyUser`, payload);
  }

  public async sendWhatsappMessage(chatId: number, body: string, messageFile?: MessageFile | null): Promise<HttpResponse | null> {
    const payload = {
      chatId: chatId,
      body: body,
      messageFile: messageFile
    };

    return await this.base.post('chat.api.service.sendWhatsappMessage', `${this.route}sendWhatsappMessage`, payload);
  }
}