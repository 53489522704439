import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../dialog.service';
import { DialogComponent } from '../dialog.component';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ClaimedChatsService } from '../../../services/chats/claimed.chats.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'add-edit-chat-notes-dialog',
  templateUrl: './add-edit-chat-notes-dialog.component.html',
  styleUrls: ['./add-edit-chat-notes-dialog.component.scss']
})
export class AddEditChatNotesDialogComponent implements OnInit {

  public notes: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    public claimedChatsService: ClaimedChatsService,
    private chatApiService: ChatApiService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService
  ) { }

  ngOnInit(): void { this.notes = this.claimedChatsService.getSelectedClaimChat?.notes ?? ''; }

  public async saveNotes(): Promise<void> {
    if (!this.claimedChatsService.getSelectedClaimChat) return;
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.chatApiService.updateChatNotes(this.claimedChatsService.getSelectedClaimChat.chatId, this.notes);
      if (!resp) this.toastMessageService.showToastMessage('Failed to save notes.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
        this.claimedChatsService.getSelectedClaimChat.notes = this.notes;
        this.dialogRef.close();
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to save chat notes.', 'toast-message-error');
      this.error.handleError('Failed to save chat notes', err, 'add-edit-chat-notes-dialog.saveNotes()');
    }
    this.dialogService.showSpinner = false;
  }

  cancel() {
    this.dialogRef.close();
  }
}