import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserApiService } from '../../services/api/user.api.service';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';
import { SystemUser } from '../../../interfaces/user.interfaces';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.scss']
})
export class OnlineUsersComponent implements OnInit {

  public onlineUsers: SystemUser[] = [];
  public showSpinner: boolean = false;

  constructor(
    private location: Location,
    private userApiService: UserApiService,
    private error: ErrorService,
    private toastMessageService: ToastMessageService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.refreshOnlineUsers();
  }

  public async refreshOnlineUsers(): Promise<void> {
    this.showSpinner = true;
    await this.getOnlineUsers();
    this.showSpinner = false;
  }

  private async getOnlineUsers(): Promise<void> {
    try {
      const resp = await this.userApiService.getOnlineUsers();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get online users', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else this.onlineUsers = resp.result;
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get online users', 'toast-message-error');
      this.error.handleError('Failed to get online users.', err, 'online-users.getOnlineUsers()');
    }
  }

  public goBack() {
    this.location.back();
  }
}