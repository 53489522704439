<ng-container *ngIf="dialogService.showSpinner">
  <spinner></spinner>
</ng-container>

<div class="container">
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div [ngSwitch]="data.dialogTypeId">
    <close-chat-dialog *ngSwitchCase="dialogType.CLOSE_CHAT"></close-chat-dialog>
    <reassign-chat-dialog *ngSwitchCase="dialogType.REASSIGN_CHAT"></reassign-chat-dialog>
    <add-edit-chat-notes-dialog *ngSwitchCase="dialogType.EDIT_WHATSAPP_CHAT_NOTES"></add-edit-chat-notes-dialog>
    <add-edit-reference-number-dialog *ngSwitchCase="dialogType.ADD_UPDATE_REFERENCE_NUMBER">
    </add-edit-reference-number-dialog>
    <edit-whatsapp-user-dialog *ngSwitchCase="dialogType.EDIT_WHATSAPP_USER"></edit-whatsapp-user-dialog>
    <messages-preview-claim-chat-dialog *ngSwitchCase="dialogType.PREVIEW_CLAIM_CHAT">
    </messages-preview-claim-chat-dialog>
    <preview-messages-dialog *ngSwitchCase="dialogType.PREVIEW_CHAT_MESSAGES">
    </preview-messages-dialog>
    <claim-room-chat-dialog *ngSwitchCase="dialogType.BULK_CLAIM_CHAT"></claim-room-chat-dialog>
    <add-whatsapp-user-dialog *ngSwitchCase="dialogType.ADD_WHATSAPP_USER"></add-whatsapp-user-dialog>
  </div>
</div>
