import { Injectable } from '@angular/core';
import { HttpResponse } from '../../../interfaces/result.interfaces';
import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private base: BaseApiService) { }

  public async getMimeTypes(): Promise<HttpResponse | null> {
    return await this.base.get('api.service.getMimeTypes', `getMimeTypes`);
  }
}