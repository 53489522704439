

export enum Product {
  NOVADT_OLD = 1,
  KENYA = 4,
  UAT = 5,
  UAT2 = 6,
  SAEX = 7,
  NOVADT = 8
}

//IF YOU ARE GOING TO RENAME THE ENUMS
//BE AWARE THAT SOME OF THESE ENUMS ARE USED IN THE DIALOG COMPONENT HTML
export enum DialogType {
  EDIT_WHATSAPP_USER = 1,
  REASSIGN_CHAT = 2,
  CHAT_REASSIGNMENT = 3,
  LOGGED_TIMED_OUT = 4,
  ADD_UPDATE_REFERENCE_NUMBER = 5,
  CLOSE_CHAT = 6,
  USER_GROUPS = 7,
  LOGGED_IN_USERS = 8,
  PREVIEW_CHAT_MESSAGES = 9,
  LOGOUT_CONFIRMATION = 10,
  EDIT_WHATSAPP_CHAT_NOTES = 11,
  PREVIEW_CLAIM_CHAT = 12,
  BULK_CLAIM_CHAT = 13,
  ADD_WHATSAPP_USER = 14
}

export enum RoomChatFilterBy {
  CHAT_DATE = 1,
  EXPIRED_CHATS = 2,
  ACTIVE_CHATS = 3,
  TALK_TO_AN_AGENT = 4
}

export enum RoomChatOrderBy {
  ASCENDING = 1,
  DESCENDING = 2
}

export enum MessageType {
  TEMPLATE = 1,
  AUTO_RESPONDER = 2,
  SYSTEM_USER = 3,
  WHATSAPP_USER = 4,
  TEMPLATE_RESPONSE = 5,
  SAEX_CLOSE_CHAT = 6
}

export enum InternalMessageType {
  REASSIGN_CHAT = 1,
  DISCONNECT = 2,
  NOTIFY_USERS_CHAT_CLAIMED = 3,
  MESSAGE_STATUS_UPDATE = 4,
  CHAT_CLOSE = 6,
  USER_LOGGED_CLAIM_CHATS = 8,
  ACCEPT_REASSIGNMENT_OF_CHAT = 9,
  PRODUCT_CHANGE = 10,
  INBOUND_MESSAGE = 11
}

export enum ChatStatus {
  OPEN = 1,
  TIMED_OUT = 2,
  CLOSED_FROM_A_SYSTEM_USER = 3,
  CLOSED_FROM_AUTO_RESPONDER_BY_WHATSAPP_USER = 4,
  CLOSED_BY_THE_SYSTEM = 5,
  OPEN_BUT_REQUESTED_AGENT = 6,
  CLOSED_FROM_AVECS_LEGAL_ENTITY = 7,
  CLOSED_FROM_CLOSE_JOB = 8
}