import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClaimedChatsService } from '../../services/chats/claimed.chats.service';
import * as _ from 'lodash';
import { Subject, Subscription, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { MenuOptions } from '../../../interfaces/global.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogType } from '../../enums';
import { ChatService } from '../../services/chats/chat.service';
import { ChatApiService } from '../../services/api/chat.api.service';
import { ToastMessageService } from '../toast-message/toast.message.service';
import { Chat } from '../../../interfaces/chat.interfaces';
import { EditWhatsappUserDialog } from '../../../interfaces/dialog.interfaces';
import { MessageSearch } from '../message-window/message-window.component';
import { Message, MessageFile } from '../../../interfaces/message.interfaces';
import { ApiService } from '../../services/api/api.service';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public selectedClaimChatId: number | null = null;
  @ViewChild('chatWindow', { static: false }) public chatWindow: ElementRef | null = null;
  @ViewChild('messageWindow', { static: false }) public messageWindow: ElementRef | null = null;
  @ViewChild('footer', { static: false }) public footer: ElementRef | null = null;
  @ViewChild('toolbar', { static: false }) public toolbar: ElementRef | null = null;
  @ViewChild('searchChatMessagesInput') public searchChatMessagesInput: ElementRef | null = null;
  public navigateToNextResultEmitter: EventEmitter<null> = new EventEmitter<null>();
  public navigateToPreviousResultEmitter: EventEmitter<null> = new EventEmitter<null>();
  public scrollToBottomEmitter: EventEmitter<null> = new EventEmitter<null>();

  public chatWindowRemainingTime: string = '';
  public fileToSend: File | null = null;
  public messageFile: MessageFile | null = null;
  public messageFileUrl: SafeUrl | null = null;
  public fileDropError: string = '';
  private maxLinesForMaxHeight: number = 10;
  private currentMessageLines: number = 2;
  private previousMessageLines: number = 2;
  public messageWindowHeight: number = 0;
  private detectChangesSub: Subject<unknown> = new Subject();
  public whatsAppUserName: string = '';
  public mimeTypes: string[] = [];
  public search: string = '';
  public message: string | undefined | null = null;
  public searchResults: number = 0;
  public currentResultIndex: number = 0;
  public chatsOptions: MenuOptions[] = [
    {
      icon: 'cancel',
      label: 'Close Chat',
      method: this.closeDialog.bind(this),
      disabled: false
    },
    {
      icon: 'refresh',
      label: 'Refresh Messages',
      method: async () => await this.getChatMessages(),
      disabled: false,
    },
    {
      icon: 'reply',
      label: 'Reassign Chat',
      method: this.reassignChatDialog.bind(this),
      disabled: false
    },
    {
      icon: 'note_add',
      label: 'Add / Edit Chat Notes',
      method: this.openDialog.bind(this, DialogType.EDIT_WHATSAPP_CHAT_NOTES),
      disabled: false
    },
    {
      icon: 'edit',
      label: 'Add / Edit Reference Number',
      method: this.editReferenceNumberDialog.bind(this),
      disabled: false
    },
    {
      icon: 'perm_contact_calendar',
      label: 'Edit WhatsApp User',
      method: this.editWhatsAppUserDialog.bind(this),
      disabled: false
    }
  ];

  //recording audio
  record: RecordRTC.StereoAudioRecorder | null = null;
  startTime: number | null = null;
  timer: ReturnType<typeof setInterval> | null = null;
  recordTime: string = '00:00';
  stream: MediaStream = {} as MediaStream;

  public isDragging: boolean = false;
  public showSpinner: boolean = false;
  public searchMessagesOverlay: boolean = false;
  public chatExpired: boolean = false;
  public recording: boolean = false;
  public sendingMessage: boolean = false;

  constructor(
    public claimedChatsService: ClaimedChatsService,
    private chatApiService: ChatApiService,
    private toastMessageService: ToastMessageService,
    public chatService: ChatService,
    private apiService: ApiService,
    private authService: AuthService,
    private error: ErrorService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {
    interval(1000)
      .pipe(takeUntil(this.detectChangesSub))
      .subscribe(() => {
        const newRemainingTime = this.claimedChatsService.selectedClaimChatWindowLeft;
        if (newRemainingTime !== this.chatWindowRemainingTime) {
          this.chatWindowRemainingTime = newRemainingTime;
          this.cd.markForCheck();
        }
      });
  }
  async ngOnInit() { await this.getMediaTypes(); }

  ngOnDestroy() {
    this.detectChangesSub.next();
    this.detectChangesSub.complete();
  }

  ngOnChanges() {
    if (!this.selectedClaimChatId) return;

    this.updateWhatsappUserName();
    this.handleChatOptions();
    this.clearSearch();
    if (this.claimedChatsService.getSelectedClaimChat) this.chatExpired = this.chatService.hasChatExpired(this.claimedChatsService.getSelectedClaimChat.chatExpiryDate);
  }

  private async getChatMessages(): Promise<void> {
    this.showSpinner = true;
    if (!this.claimedChatsService.getSelectedClaimChat) return;
    try {
      const resp = await this.chatApiService.getMessagesForChatId(this.claimedChatsService.getSelectedClaimChat.chatId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to refresh chat messages', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        const chat = this.claimedChatsService.getSelectedClaimChat;
        this.claimedChatsService.updateClaimedChatMessages(chat.chatId, resp.result);
        this.claimedChatsService.getSelectedClaimChat.messages = chat.messages;
      }
    } catch (err) {
      this.error.handleError('Failed to get chat messages', err, 'chats-window.getChatMessages()');
      this.toastMessageService.showToastMessage('Failed to refresh chat messages', 'toast-message-error');
    }
    this.showSpinner = false;
  }

  private async getMediaTypes(): Promise<void> {
    try {
      const resp = await this.apiService.getMimeTypes();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get mime types', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else this.mimeTypes = resp.result.map((x: { mimeType: string; }) => x.mimeType);
    } catch (err) {
      this.error.handleError('Failed to get media types', err, 'chats-window.getMediaTypes()');
      this.toastMessageService.showToastMessage('Failed to get mime types', 'toast-message-error');
    }
  }

  private handleChatOptions() {
    //use does not own the chat
    if (this.claimedChatsService.getSelectedClaimChat?.claimedBy.toLowerCase() !== this.authService.loginName?.toLowerCase()) {
      this.chatsOptions.filter(co => {
        if (co.label.toLowerCase() !== 'close chat' && co.label.toLowerCase() !== 'refresh messages') {
          co.disabled = true;
        }
      });
    } else {
      this.chatsOptions.filter(co => {
        co.disabled = false;
      });
    }
  }

  public openSearchMessagesOverlay() {
    this.searchMessagesOverlay = !this.searchMessagesOverlay;

    if (this.searchMessagesOverlay) {
      setTimeout(() => {
        if (!this.searchChatMessagesInput || !this.searchChatMessagesInput.nativeElement) return;
        this.searchChatMessagesInput.nativeElement.focus();
      }, 0);
    }
  }

  public handleMessageSearchTyping(event: KeyboardEvent) {
    if (!this.search || this.search.trim().length <= 2) return;

    const isEnter = event.key === 'Enter' || event.code === 'Enter';
    if (isEnter) this.navigateToNextResult();
  }

  public navigateToNextResult(): void {
    this.navigateToNextResultEmitter.emit();
  }

  public navigateToPreviousResult(): void {
    this.navigateToPreviousResultEmitter.emit();
  }

  public messageSearch(event: MessageSearch) {
    this.currentResultIndex = event.currentResultIndex;
    this.searchResults = event.searchResults;
  }

  public calculateHeight() {
    if (!this.footer || !this.toolbar || !this.messageWindow) {
      return;
    }

    const toolbarHeight = this.toolbar.nativeElement.offsetHeight;
    const footerHeight = this.footer.nativeElement.offsetHeight;
    const messageWindowHeight = window.innerHeight - toolbarHeight - footerHeight;

    //only if the calculated height is different to what it currently is then we will set message window height
    if (this.messageWindow.nativeElement.style.height !== messageWindowHeight) {
      this.messageWindowHeight = messageWindowHeight;
      this.messageWindow.nativeElement.style.height = `${this.messageWindowHeight}px`;
      this.messageWindow.nativeElement.style.maxHeight = `${this.messageWindowHeight}px`;
    }
  }

  //to sum up what this method is doing
  //whenever the user is making a new line we will expand the footer and make the message window height smaller
  //if the user is removing lines we will contract the footer and expand the message window height
  //there is a max height by checking the current lines and if the lines is greater than the max line height we won't expand
  public handleFooterAndMessageWindowHeight(): void {
    //get the footer element
    const footer = this.footer?.nativeElement as HTMLElement | null;
    //get the message window element
    const messageWindow = this.messageWindow?.nativeElement as HTMLElement | null;

    if (!footer || !messageWindow) return;

    //get the text area element
    const textarea = document.querySelector('.footer textarea') as HTMLTextAreaElement | null;

    if (!textarea) return;

    //get the line height for the textarea
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight || '0', 10);
    //the amount of lines textarea is at
    this.currentMessageLines = Math.ceil(textarea.scrollHeight / lineHeight);

    //get the min footer height
    const minFooterHeight = parseInt(window.getComputedStyle(footer).minHeight || '0', 10);
    //get the max footer height
    const maxFooterHeight = parseInt(window.getComputedStyle(footer).maxHeight || '0', 10);
    //get the max message window height
    const maxMessageWindowHeight = parseInt(window.getComputedStyle(messageWindow).maxHeight || '0', 10);

    //user cleared out everything in the text area
    if (_.isEmpty(this.message)) {
      //set footer and message window height back to normal
      messageWindow.style.height = `${maxMessageWindowHeight}px`;
      footer.style.height = `${minFooterHeight}px`;
      this.currentMessageLines = 2;
      this.previousMessageLines = 2;;
      return;
    }

    //don't do anything if the live lines is equal to the current message window lines
    // if it is it means the person hasn't made a new line and is typing on the same message
    if (this.currentMessageLines == this.previousMessageLines) return;

    //max lines is dependant on the max height of the footer
    //footer height goes up in 10px
    //the difference between the max and current height is 10 so that's where we get 6 from
    const increaseLineAmount: number = 10;

    //we are removing lines
    if (this.currentMessageLines < this.previousMessageLines) {
      // only decrease heights if the lines are not less than the minimum allowed
      if (this.currentMessageLines <= this.maxLinesForMaxHeight) {
        // decrease the footer height
        footer.style.height = `${footer.clientHeight - increaseLineAmount}px`;
        // increase the message window height
        messageWindow.style.height = `${messageWindow.clientHeight + increaseLineAmount}px`;
      }
    }
    //we are adding lines
    else if (this.currentMessageLines > this.previousMessageLines) {
      //this is to cater for a sudden change of current lines
      //eg being if the user copy pastes a message in the text area
      let multiplier: number = Math.ceil(this.currentMessageLines - this.previousMessageLines);
      if (multiplier > this.maxLinesForMaxHeight) multiplier = this.maxLinesForMaxHeight;
      //increase the height of the footer and decrease the height of the message window
      //only if the footer is not equal to the max height
      if (maxFooterHeight !== footer.offsetHeight) {
        //decrease the message window height
        messageWindow.style.height = `${messageWindow.clientHeight - (increaseLineAmount * multiplier)}px`;
        //increase the footer height
        footer.style.height = `${footer.clientHeight + (increaseLineAmount * multiplier)}px`;
      }
    }

    this.previousMessageLines = this.currentMessageLines;

    //emit scroll to bottom
    this.scrollToBottomEmitter.emit();
  }

  public async onFileSelected(fileList: FileList) {
    const files: File[] = Array.from(fileList || []);
    const mimeTypes = files.map(file => file.type);

    if (this.isValidFiles(mimeTypes)) {
      this.fileToSend = files[0];
      const resp = await this.handleFileUpload(this.fileToSend);
      if (!resp) return;
      this.handleFileDisplay();
    }
    else this.toastMessageService.showToastMessage(`Invalid file type. Accepted file types are: ${this.mimeTypes.join(', ')}`, 'toast-message-error', 6000);
  }

  public onDragEnter(event: DragEvent): void {
    event.preventDefault();
    if (this.fileToSend) return;
    else if (!this.isDragging) this.isDragging = true;
    else if (this.isStillInsideChatWindow(event)) return;

    const files: DataTransferItemList | undefined = event.dataTransfer?.items;

    if (!files || files.length > 1) {
      this.fileDropError = `Can only send one file at a time.`;
      return;
    }

    const header = files.length == 1 ? 'Invalid file type' : 'Invalid file types';
    const mimeTypes: string[] = Object.values(files).map(file => file.type);
    const validFile = this.isValidFiles(mimeTypes);
    if (!validFile) this.fileDropError = `${header}.\nAccepted file types are:\n${this.mimeTypes.join(', ')}`;
    else this.fileDropError = '';
  }

  public onDragOver(event: DragEvent) { event.preventDefault(); }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    if (this.isStillInsideChatWindow(event)) return;
    this.isDragging = false;
  }

  public async onFilesDropped(event: DragEvent) {
    event.preventDefault();
    if (this.fileToSend) return;

    //for sending media
    if (this.claimedChatsService.getSelectedClaimChat) {
      const chatExpired = this.chatService.hasChatExpired(this.claimedChatsService.getSelectedClaimChat.chatExpiryDate);
      if (chatExpired) {
        this.isDragging = false;
        this.toastMessageService.showToastMessage('Chat is expired. Cannot send file', 'toast-message-error');
        return;
      }
    }

    this.isDragging = false;
    if (this.fileDropError) return;

    const files: File[] = Array.from(event.dataTransfer?.files || []);
    this.fileToSend = files[0];
    await this.handleFileUpload(this.fileToSend);
    this.handleFileDisplay();
  }

  private handleFileDisplay() {
    if (!this.fileToSend) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target?.result as string;
      this.messageFileUrl = this.sanitizer.bypassSecurityTrustUrl(dataURL);
    };
    reader.readAsDataURL(this.fileToSend);
  }

  private isValidFiles(mimeTypes: string[]): boolean {
    if (mimeTypes?.length == 0) return false;
    return mimeTypes.every(file => this.mimeTypes.includes(file));
  }

  public async handleFileUpload(file: File): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > 15) {
        this.toastMessageService.showToastMessage(`${file.name} is too large to send`, 'toast-message-error');
        this.fileToSend = null;
        return resolve(false);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file as Blob);
      reader.onload = async () => {
        const base64 = reader.result?.toString().replace(`data:${file.type};base64,`, '');

        if (!base64) {
          this.toastMessageService.showToastMessage(`Unable to send ${file.name}`, 'toast-message-error', 4000);
          // continue;
          reject(false);
        }
        if (base64) {
          const extension: string | undefined = file?.name ? file.name.split('.').pop() : file.type.split('/').pop();

          if (!extension) return reject('can not find file extension');

          const selectedClaimedChat: Chat | null = this.claimedChatsService.getSelectedClaimChat;

          if (!selectedClaimedChat) return reject('no selected claim chat');

          this.messageFile = {
            base64: base64,
            fileName: file.name,
            mimeType: file.type,
            extension: extension,
            filePrefixName: selectedClaimedChat.refNo ? selectedClaimedChat.refNo : `${selectedClaimedChat.chatId}`
          };
          resolve(true);
        }
      };
    });
  }

  public async validateMessage(event: KeyboardEvent): Promise<void> {
    //prevent enter key from making a new line
    if (event) event.preventDefault();

    //empty message so do nothing and no message file
    if (_.isEmpty(this.message?.trim()) && !this.messageFile) return;

    await this.sendMessage();
  }

  public async sendMessage(): Promise<void> {
    this.sendingMessage = true;
    try {
      if (!this.claimedChatsService.getSelectedClaimChat) return;

      //for sending media
      const chatExpired = this.chatService.hasChatExpired(this.claimedChatsService.getSelectedClaimChat.chatExpiryDate);
      if (chatExpired) {
        this.toastMessageService.showToastMessage('Chat is expired. Cannot send messages', 'toast-message-error');
        return;
      }

      const body = this.message?.trim() ?? '';
      const resp = await this.chatApiService.sendWhatsappMessage(this.claimedChatsService.getSelectedClaimChat.chatId, body, this.messageFile);
      if (!resp) this.toastMessageService.showToastMessage('Failed to send whatsapp message', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        const message: Message = {
          messageId: resp.result.messageId,
          body: body,
          chatId: this.claimedChatsService.getSelectedClaimChat.chatId,
          direction: 1,
          systemUser: this.authService?.user?.loginName,
          systemUserId: this.authService?.user?.id,
          whatsappUserFirstName: this.claimedChatsService.getSelectedClaimChat.firstName,
          whatsappUserLastName: this.claimedChatsService.getSelectedClaimChat.lastName,
          whatsappUserNumber: this.claimedChatsService.getSelectedClaimChat.whatsappUserNumber,
          sendDate: new Date(),
          fileName: this.messageFile?.fileName,
          friendlyName: this.messageFile?.fileName,
          mediaUrl: resp.result?.mediaUrl,
          extension: this.messageFile?.extension,
          mimeType: this.messageFile?.mimeType
        };
        this.claimedChatsService.getSelectedClaimChat.messages = [...this.claimedChatsService.getSelectedClaimChat.messages, message];
        this.claimedChatsService.handleClaimedChatsPanel();
        this.scrollToBottomEmitter.emit();
        this.messageFile = null;
        this.messageFileUrl = null;
        this.message = null;
        this.handleFooterAndMessageWindowHeight();
      }
    } catch (err) {
      this.error.handleError('Failed to send message', err, 'chats-window.sendMessage()');
      this.toastMessageService.showToastMessage('Failed to send whatsapp message', 'toast-message-error');
    }
    this.sendingMessage = false;
  }

  public chatOption(option: MenuOptions) { option.method.call(this); }

  private openDialog(dialogTypeId: DialogType): void {
    this.dialog.open(DialogComponent, {
      data: { dialogTypeId },
      autoFocus: false
    });
  }

  private closeDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.CLOSE_CHAT, chats: [this.claimedChatsService.getSelectedClaimChat] },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !this.claimedChatsService.getSelectedClaimChat) return;

      const chatIds: number[] = [this.claimedChatsService.getSelectedClaimChat.chatId];
      this.claimedChatsService.handleRemovingClaimedChats(chatIds);
    });
  }

  private reassignChatDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.REASSIGN_CHAT, chats: [this.claimedChatsService.getSelectedClaimChat] },
      autoFocus: false
    });
  }

  private editReferenceNumberDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        dialogTypeId: DialogType.ADD_UPDATE_REFERENCE_NUMBER
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      if (!result) return;

      const chat: Chat | null = this.claimedChatsService.getSelectedClaimChat;

      if (chat) {
        const chatToUpdate = this.getChatToUpdate(chat.chatId);
        if (!chatToUpdate) return;
        else if (!this.claimedChatsService.claimedChatsPanel) return;

        const foundChat: Chat = this.claimedChatsService.claimedChatsPanel[chatToUpdate.groupIndex].chats[chatToUpdate.chatIndex];
        foundChat.refNo = chat.refNo;
        this.claimedChatsService.claimedChatsPanel[chatToUpdate.groupIndex].chats[chatToUpdate.chatIndex] = { ...foundChat };
      }

    });
  }

  private editWhatsAppUserDialog() {
    if (!this.claimedChatsService.getSelectedClaimChat) return;

    const chat: Chat | null = this.claimedChatsService.getSelectedClaimChat;
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        dialogTypeId: DialogType.EDIT_WHATSAPP_USER,
        whatsappUserId: chat.whatsappUserId,
        firstName: chat.firstName,
        lastName: chat.lastName
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((whatsappUser: EditWhatsappUserDialog | undefined) => {
      if (!whatsappUser || !this.claimedChatsService.getSelectedClaimChat) return;

      this.claimedChatsService.getSelectedClaimChat.firstName = whatsappUser.firstName;
      this.claimedChatsService.getSelectedClaimChat.lastName = whatsappUser.lastName;

      if (!this.claimedChatsService.claimedChatsPanel) return;

      const chatToUpdate = this.getChatToUpdate(chat.chatId);
      if (!chatToUpdate) return;

      const foundChat: Chat = this.claimedChatsService.claimedChatsPanel[chatToUpdate.groupIndex].chats[chatToUpdate.chatIndex];
      foundChat.firstName = whatsappUser.firstName;
      foundChat.lastName = whatsappUser.lastName;

      foundChat.messages.map(m => {
        m.whatsappUserFirstName = whatsappUser.firstName;
        m.whatsappUserLastName = whatsappUser.lastName;
      });

      this.claimedChatsService.claimedChatsPanel[chatToUpdate.groupIndex].chats[chatToUpdate.chatIndex] = { ...foundChat };

      this.updateWhatsappUserName();
    });
  }

  private updateWhatsappUserName() {
    if (!this.claimedChatsService.getSelectedClaimChat) return;

    const chat: Chat | null = this.claimedChatsService.getSelectedClaimChat;
    const whatsappUserName = this.chatService.getFormattedName(chat.firstName, chat.lastName, chat.whatsappUserNumber);

    this.whatsAppUserName = whatsappUserName ?? '';
  }

  private getChatToUpdate(chatId: number): ChatToUpdate | null {
    if (!this.claimedChatsService.claimedChatsPanel) return null;

    const groupIndex: number = this.claimedChatsService.claimedChatsPanel.findIndex(group =>
      group.chats.some(c => c.chatId === chatId)
    );

    if (groupIndex === -1) return null;

    const chatIndex: number = this.claimedChatsService.claimedChatsPanel[groupIndex].chats.findIndex(c =>
      c.chatId === chatId
    );

    if (chatIndex === -1) return null;

    return {
      groupIndex: groupIndex,
      chatIndex: chatIndex
    };
  }

  public clearSearch() {
    this.searchMessagesOverlay = false;
    this.search = '';
    this.searchResults = 0;
    this.currentResultIndex = 0;
  }

  //handling when the user pressing escape
  //need to clear the search
  public handleOverlayKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.clearSearch();
    }
  }

  private startTimer() {
    this.startTime = Date.now();

    this.timer = setInterval(() => {
      if (!this.startTime) return;
      const currentTime = Date.now();
      const elapsedTimeInSeconds = Math.floor((currentTime - this.startTime) / 1000);
      this.formatTimer(elapsedTimeInSeconds);
    }, 1000);
  }

  private formatTimer(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    this.recordTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  public startRecording() {
    try {
      this.startTimer();
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(this.successCallback.bind(this),
          this.toastMessageService.showToastMessage.bind('Can not record audio in your browser', 'toast-message-error')
        );
    } catch (err) {
      this.recording = false;
      this.toastMessageService.showToastMessage.bind('Failed to record audio in your browser', 'toast-message-error');
    }
  }

  public stopRecording() {
    this.recording = false;
    this.stopTimer();
    this.stream.getTracks().forEach(track => track.stop());
    this.record?.stop(this.stopTimer.bind(this));
    this.record = null;
  }

  private stopTimer() {
    this.recordTime = '00:00';
    this.timer = null;
    this.startTime = null;
  }

  private successCallback(mediaStream: MediaStream) {
    this.recording = true;
    const options: RecordRTC.Options = {
      mimeType: "audio/ogg",
      numberOfAudioChannels: 1
    };

    //starting audio recording
    this.record = new RecordRTC.StereoAudioRecorder(mediaStream, options);
    this.record.record();
    this.stream = mediaStream;


  }

  public submitRecording() {
    this.record?.stop(this.processRecording.bind(this));
  }

  //strange thing that happens
  //when recording audio we set it to audio/ogg but when we get the file it's not, it's set to audio/wav
  //but by setting the file name to have '.mp3' at the end works even though the file itself is audio/wav
  private async processRecording(file: Blob) {
    this.stopTimer();
    this.stopRecording();
    this.fileToSend = new File([file], `${this.newGuid()}.wav`, { type: file.type });
    const resp = await this.handleFileUpload(this.fileToSend);
    if (!resp) return;
    await this.sendMessage();
  }

  private newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private isStillInsideChatWindow(event: DragEvent): boolean { return this.chatWindow?.nativeElement.contains(event.relatedTarget as Node); }
}

export interface ChatToUpdate {
  groupIndex: number;
  chatIndex: number;
}