import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private _chatWindowLeft: string = '';

  public set chatWindowLeft(chatWindowLeft: string) { this._chatWindowLeft = chatWindowLeft; }
  public get chatWindowLeft(): string { return this._chatWindowLeft; }

  constructor() { }

  public hasChatExpired(chatExpiryDate: Date): boolean {
    const currentDate = moment();
    const formattedExpiryDate = moment(new Date(chatExpiryDate));
    const hasExpired: boolean = currentDate.isAfter(formattedExpiryDate);

    return hasExpired;
  }

  //you can pass in the whatsapp user details (first name, last name, number) and will return the correct formatting
  //or you can pass in the system user which will just be first name
  public getFormattedName(firstName: string, lastName?: string, whatsappUserNumber?: string): string | undefined {
    if (firstName && lastName) return `${this.capitalizeEachWord(firstName)} ${this.capitalizeEachWord(lastName)}`;
    else if (firstName) return this.capitalizeEachWord(firstName);
    else return whatsappUserNumber;
  }

  public capitalizeEachWord(word: string): string {
    const regex = /(\b[a-z](?!\s))/g;
    return word.replace(regex, function (x) { return x.toUpperCase(); });
  }
}