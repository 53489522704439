import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { ClosedChats as ClosedChat } from '../../../interfaces/chat.interfaces';
import { ChatApiService } from '../../services/api/chat.api.service';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';
import { SystemUser, WhatsappUser } from '../../../interfaces/user.interfaces';
import { UserApiService } from '../../services/api/user.api.service';
import { Dropdown } from '../../../interfaces/global.interfaces';
import { ChatService } from '../../services/chats/chat.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { DialogType } from '../../enums';
import { Location } from '@angular/common';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-closed-chats',
  templateUrl: './closed-chats.component.html',
  styleUrls: ['./closed-chats.component.scss']
})
export class ClosedChatsComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('picker') picker: any;
  public displayedColumns: string[] = ['refNo', 'whatsappUserName', 'body', 'sendDate', 'group', 'lastClaimedBy'];
  public closedChats: MatTableDataSource<ClosedChat> = new MatTableDataSource<ClosedChat>([]);
  public selectedDate!: Date;
  public systemUsers: Dropdown[] = [];
  public selectedSystemUserId!: number;
  public selectedWhatsappUserId!: number;
  public whatsappUsers: Dropdown[] = [];
  public selectedClosedChat: ClosedChat | null = null;
  public selectedRowIndex: number = -1;

  public tableSpinner: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    public chatService: ChatService,
    private chatApiService: ChatApiService,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService,
    public dialog: MatDialog,
    private error: ErrorService,
    private location: Location
  ) { }

  async ngOnInit() {
    this.showSpinner = true;
    await this.getSystemUsers();
    this.showSpinner = false;
  }

  ngAfterViewInit() {
    this.closedChats.paginator = this.paginator;
    this.closedChats.sort = this.sort;
  }

  public async getSystemUsers(): Promise<void> {
    try {
      const resp = await this.userApiService.getSystemUsers();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get system users', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        resp.result.map((user: SystemUser) => {
          this.systemUsers.push({
            value: user.id,
            label: user.loginName
          });
        });
        await this.getWhatsappUsers();
      }
    } catch (err) {
      this.error.handleError('Failed to get system users.', err, 'closed-chats.getSystemUsers()');
      this.toastMessageService.showToastMessage('Failed to get system users', 'toast-message-error');
    }
  }

  private async getWhatsappUsers(): Promise<void> {
    try {
      const resp = await this.userApiService.getWhatsAppUsers();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get whatsapp users', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        resp.result.map((user: WhatsappUser) => {
          this.whatsappUsers.push({
            value: user.whatsappUserId,
            label: this.chatService.getFormattedName(user.firstName, user.lastName, user.whatsappUserNumber) ?? user.whatsappUserNumber
          });
        });
      }
    } catch (err) {
      this.error.handleError('Failed to get whatsapp users.', err, 'closed-chats.getWhatsappUsers()');
      this.toastMessageService.showToastMessage('Failed to get whatsapp users', 'toast-message-error');
    }
  }

  public async getClosedChats(): Promise<void> {
    this.tableSpinner = true;
    try {
      const resp = await this.chatApiService.getClosedChatsFromDateAndOptionallyUser(this.selectedDate, this.selectedSystemUserId, this.selectedWhatsappUserId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get closed chats', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else if (!resp.result || resp.result.length === 0) this.toastMessageService.showToastMessage('No closed chats found', 'toast-message-info');
      else {
        this.closedChats.data = resp.result;
        this.closedChats.data.map(cc => {
          cc.whatsappUserName = this.chatService.getFormattedName(cc.firstName, cc.lastName, cc.whatsappUserNumber) ?? cc.whatsappUserNumber;
        });
      }
    } catch (err) {
      this.error.handleError('Failed to get closed chats.', err, 'closed-chats.getClosedChats()');
      this.toastMessageService.showToastMessage('Failed to get closed chats', 'toast-message-error');
    }
    this.tableSpinner = false;
  }

  public async onClosedChatClick(closedChat: ClosedChat, index: number) {
    this.selectedRowIndex = index;
    this.selectedClosedChat = closedChat;

    const dialogRef = this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.PREVIEW_CHAT_MESSAGES, chat: this.selectedClosedChat },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      this.selectedClosedChat = null;
      this.selectedRowIndex = -1;
    });
  }

  public formatDate(date: Date) { return moment(date).format('YYYY/MM/DD hh:mm a'); }

  public filterClosedChats(filter: string) { this.closedChats.filter = filter.trim().toLowerCase(); }

  public goBack() { this.location.back(); }
}