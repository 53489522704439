<div *ngIf="showSpinner">
  <spinner></spinner>
</div>

<div class="no-rooms-error"
  *ngIf="!showSpinner && (!chatRoomService.chatRooms || chatRoomService.chatRooms.length == 0)">
  <label>You have no rooms assigned to you.</label>
  <br>
  <label>Please contact support.</label>
</div>

<div class="content">
  <div #toolbar class="page-toolbar toolbar">
    <button mat-icon-button (click)="goBack()" matTooltip="Go back">
      <mat-icon class="toolbar-buttons">arrow_back</mat-icon>
    </button>
    <label>{{ chatRoomService.heading || 'Chat Rooms' }}</label>
    <button mat-icon-button (click)="refreshChats()" matTooltip="Refresh chat rooms">
      <mat-icon class="toolbar-buttons">refresh</mat-icon>
    </button>
  </div>

  <mat-tab-group *ngIf="chatRoomService.chatRooms?.length > 0" mat-align-tabs="start" animationDuration="0ms"
    (selectedIndexChange)="chatRoomService.handleChatFilterLabels($event)"
    [selectedIndex]="chatRoomService.selectedTab?.index">
    <mat-tab *ngFor="let chatRoom of chatRoomService.chatRooms; let i = index;" label="{{ chatRoom.roomTabName }}">
      <div class="room-content">
        <div #filters class="tab-filters">
          <ng-container *ngIf="multiSelectMode">
            <label>Options: </label>
            <button [disabled]="chatRoomService?.selectedChats.length == 0"
              (click)="handleCloseChatDialog(chatRoomService?.selectedChats)" mat-button>Close
              ({{ chatRoomService?.selectedChats.length }})</button>
            <button [disabled]="chatRoomService?.selectedChats.length == 0" (click)="bulkClaimChat()" mat-button>Claim
              ({{ chatRoomService?.selectedChats.length }})</button>
          </ng-container>
          <ng-container *ngIf="!multiSelectMode">
            <mat-form-field class="form-field">
              <mat-label>Search {{ chatRoom.roomName }} Chats...</mat-label>
              <input matInput [(ngModel)]="searchVal" placeholder="Firstname, lastname, ref, cell no" autocomplete="off"
                value="{{ chatRoom.txt }}" type="text" name="searchVal">
              <button mat-button *ngIf="searchVal" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchVal=undefined;">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <drop-down placeHolder="Select a filter" [options]="chatRoomService.chatFilterOptions"
              [selectedDropDown]="chatRoomService.chatFilter" filterPlaceHolder="Filter chat filters"
              (onDropdownOptionSelect)="onChatFilter($event)">
            </drop-down>
            <drop-down [disabled]="disabledOrderByFilter" placeHolder="Select an order by option"
              [selectedDropDown]="chatRoomService.orderFilter" [options]="chatRoomService.orderFilterOptions"
              (onDropdownOptionSelect)="onOrderBy($event)">
            </drop-down>
          </ng-container>
          <mat-checkbox [checked]="multiSelectMode" (change)="multiSelectModeChange()">
            Multiselect Mode
          </mat-checkbox>
        </div>
        <div class="no-chats" *ngIf="chatRoom && chatRoom.chats.length == 0">
          <label>
            No chats found in {{ chatRoom.roomName }}
          </label>
        </div>
        <div #chatListing class="chats" [style]="{'height': calculateHeight()}">
          <div class="listing">
            <ng-container *ngFor="let chat of chatRoom.chats | filter:searchVal |
              chatRoomFilter:chatRoomService.chatFilter:chatRoomService.orderFilter">
              <room-chat-item [chat]="chat" [multiselectMode]="multiSelectMode"
                (onChatSelection)="onChatSelection($event)" (claimChatMessages)="previewClaimChatMessages($event)"
                (onClose)="handleCloseChatDialog([$event])">
              </room-chat-item>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
