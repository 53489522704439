<div class="content">
  <h2 mat-dialog-title class="title">{{ whatsAppUserName }}'s Chat Messages</h2>
  <div class="message-window">
    <message-window [messages]="messages"></message-window>
  </div>
  <div class="claim">
    <drop-down *ngIf="groupOptions && groupOptions.length > 0" filter="true" placeHolder="Select a group"
      [options]="groupOptions" filterPlaceHolder="Filter groups" (onDropdownOptionSelect)="onGroupSelect($event)">
    </drop-down>

    <button mat-button [disabled]="!selectedGroupOptionId" (click)="claimChat()">Claim</button>
  </div>
</div>
