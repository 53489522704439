import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import * as _ from 'lodash';
import { ErrorService } from '../error.service';

@Injectable()
export class AuthManager implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private errorService: ErrorService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    try {
      if (this.auth.isTokenValid()) return true;

      this.auth.clearUser;
      this.router.navigate(['/login']);
      return false;
    } catch (err) {
      this.router.navigate(['/login']);
      this.errorService.handleError('Failed to check if can activate', err, 'AuthManager.canActive()');
      return false;
    }
  }
}