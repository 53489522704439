<div class="content">
  <h2 mat-dialog-title class="title">Claim {{ data.chats.length }} Chats</h2>
  <div class="claim">
    <drop-down *ngIf="groupOptions && groupOptions.length > 0" filter="true" placeHolder="Select a group"
      [options]="groupOptions" filterPlaceHolder="Filter groups" (onDropdownOptionSelect)="onGroupSelect($event)">
    </drop-down>

    <div class="actions">
      <button mat-button [disabled]="!selectedGroupOptionId" (click)="claimChat()">Claim</button>
      <button mat-button (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
