<mat-card class="chat-item text-black" (click)="chatSelect()"
  [ngClass]="{ 'selected': chat.chatSelected, 'active': !chatExpired, 'expired': chatExpired, 'multi-select-mode': multiselectMode }">
  <div class="content">
    <div class="heading">
      <label class="display-name">{{ whatsAppDisplayName }}</label>
      <label>{{ messageTimeStamp }}</label>
    </div>
    <div class="sub-heading">
      <label class="reference">{{ chat.refNo ? 'Reference: ' + chat.refNo : 'No ref number.' }}</label>
      <label>{{ lastClaimedBy }}</label>
    </div>
    <div class="items">
      <div class="message">
        <mat-icon class="message-status {{ messageStatusIconClass }}">
          {{ messageStatusIcon }}</mat-icon>
        <mat-icon *ngIf="messageMediaIcon">
          {{ messageMediaIcon }}</mat-icon>
        <label class="body">{{ messageBody }}</label>
      </div>
    </div>
    <div class="actions">
      <button [disabled]="multiselectMode" (click)="claim()" mat-button>Claim</button>
      <button [disabled]="multiselectMode" (click)="closeChat()" mat-button>Close</button>
    </div>
  </div>
</mat-card>
