import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment';
import { Chat, ClaimedChats } from '../../../interfaces/chat.interfaces';
import { ChatService } from './chat.service';
import { Message } from '../../../interfaces/message.interfaces';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';
import { MenuOptions } from '../../../interfaces/global.interfaces';
import { BrowserService } from '../browser.service';
import { ChatRoomService } from './chat.room.service';
import { ErrorService } from '../error.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimedChatsService {

  private _chatsHeading: string = 'My Claimed Chats';
  private _search: string = '';
  private _claimedChats: Chat[] = [];
  private _claimedChatsOriginal: Chat[] = [];
  private _selectedClaimChat: Chat | null = null;
  private _hasChatExpired: boolean = false;
  private _claimedChatsPanel: ClaimedChats[] | null = null;
  private _claimedChatIdFromRoom: number = 0;
  public filterChatsOptions: MenuOptions[] = [
    {
      icon: 'chat_bubble',
      label: 'All Claimed Chats',
      method: this.filterByAllClaimedChats.bind(this),
      disabled: false
    },
    {
      icon: 'how_to_reg',
      label: 'My Unread messages',
      method: this.filterByMyUnreadMessages.bind(this),
      disabled: false
    },
    {
      icon: 'done',
      label: 'All Unread messages',
      method: this.filterByAllUnreadMessages.bind(this),
      disabled: false
    },
    {
      icon: 'portrait', //portrait
      label: 'My Active Chats',
      method: this.filterByMyActiveChats.bind(this),
      disabled: false
    },
    {
      icon: 'question_answer',
      label: 'All Active Chats',
      method: this.filterByAllActiveChats.bind(this),
      disabled: false
    },
    {
      icon: 'voice_over_off',
      label: 'My Expired Chats',
      method: this.filterByMyExpiredChats.bind(this),
      disabled: false
    },
    {
      icon: 'volume_off',
      label: 'All Expired Chats',
      method: this.filterByAllExpiredChats.bind(this),
      disabled: false
    }
  ];

  private _selectedFilterChatOption: MenuOptions | null = null;

  public get selectedFilterChatOption(): MenuOptions | null { return this._selectedFilterChatOption; }

  public get search(): string { return this._search; }

  public get chatsHeading(): string { return this._chatsHeading; }
  
  public get getClaimedChats(): Chat[] { return this._claimedChats; }
  
  public get getClaimedChatsOriginal(): Chat[] { return this._claimedChatsOriginal; }

  public get getSelectedClaimChat(): Chat | null { return this._selectedClaimChat; }

  public get hasChatExpired(): boolean { return this._hasChatExpired; }

  public get claimedChatIdFromRoom() { return this._claimedChatIdFromRoom; };

  public get claimedChatsPanel() { return this._claimedChatsPanel; }

  public get selectedClaimChatWindowLeft(): string {
    if (!this.getSelectedClaimChat) return '';

    const currentDate = moment();
    const formattedExpiryDate = moment(new Date(this.getSelectedClaimChat.chatExpiryDate));
    const hasExpired: boolean = currentDate.isAfter(formattedExpiryDate);

    if (hasExpired) {
      this.hasChatExpired = true;
      return '';
    }

    this.hasChatExpired = false;

    const remainingTimeSeconds = formattedExpiryDate.diff(currentDate, 'seconds');
    const remainingTimeFormatted = moment.utc(moment.duration(remainingTimeSeconds, "seconds").asMilliseconds()).format("HH:mm");

    return `Time Left: ${remainingTimeFormatted}`;
  }

  public get claimedChatsPanelChatCount(): number {
    let count = 0;
    this.claimedChatsPanel?.map(gc => gc.groupChatsLength).map(c => count += c);
    return count;
  }

  public set hasChatExpired(value: boolean) { this._hasChatExpired = value; }

  public set selectedFilterChatOption(menuOption: MenuOptions | null) { this._selectedFilterChatOption = menuOption; }

  public set chatsHeading(chatsHeading: string) { this._chatsHeading = chatsHeading; }

  public set search(search: string) { this._search = search; }

  public set setClaimedChats(chats: Chat[]) { this._claimedChats = chats; }

  public set setClaimedChatsOriginal(chats: Chat[]) { this._claimedChatsOriginal = chats; }

  public set setSelectedClaimChat(chat: Chat | null) { this._selectedClaimChat = chat; };

  public set claimedChatIdFromRoom(chatId: number) { this._claimedChatIdFromRoom = chatId; }
  
  public set claimedChatsPanel(claimedChatsPanel: ClaimedChats[] | null) { this._claimedChatsPanel = claimedChatsPanel; }

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private chatRoomService: ChatRoomService,
    private browserService: BrowserService,
    private error: ErrorService,
    private toastMessageService: ToastMessageService
  ) { }
  
  public updateClaimedChatMessages(chatId: number, messages: Message[]) {
    const orignalClaimeChatsIndex = this.getClaimedChatsOriginal.findIndex(c => c.chatId === chatId);
    const claimeChatsIndex = this.getClaimedChats.findIndex(c => c.chatId === chatId);

    this.getClaimedChatsOriginal[orignalClaimeChatsIndex].messages = messages;
    this.getClaimedChats[claimeChatsIndex].messages = messages;
    
    if (this.getSelectedClaimChat?.chatId === chatId) {
      this.getSelectedClaimChat.messages = messages;
    }
  }

  public handleRemovingClaimedChats(chatIds: number[]): void {
    if (!this.getClaimedChatsOriginal) return;
    this.setClaimedChatsOriginal = this.getClaimedChatsOriginal.filter(chat => !chatIds.includes(chat.chatId));

    //if the chat that was closed from another user
    //make the selected claim chat null
    if (this.getSelectedClaimChat) {
      const foundChat = chatIds.find(chat => chat === this.getSelectedClaimChat?.chatId);
      if (foundChat) this.setSelectedClaimChat = null;
    }

    this.applyChatFilterIfThereIsAFilter();
    this.handleClaimedChatsPanel();
  }

  public applyChatFilterIfThereIsAFilter() {
    if (this.selectedFilterChatOption) {
      this.selectedFilterChatOption.method.call(this);
      this.chatsHeading = this.selectedFilterChatOption.label;
    } else {
      this.filterByMyClaimedChats();
      this.chatsHeading = 'My Claimed Chats';
    }
  }

  public filterChatOption(option: MenuOptions): void {
    if (this.selectedFilterChatOption == option) {
      this.selectedFilterChatOption = null;
      this.filterByMyClaimedChats();
      this.handleClaimedChatsPanel();
      this.chatsHeading = 'My Claimed Chats';
    } else {
      this.selectedFilterChatOption = option;
      option.method.call(this);
      this.handleClaimedChatsPanel();
      this.chatsHeading = option.label;
    }
    this.resetReassignedChats();
  }

  public filterByMyClaimedChats() {
    const claimedChats = this.getLoggedInUserClaimedChats();
    this.setClaimedChats = claimedChats;
  }

  public filterByAllClaimedChats() { this.setClaimedChats = this._claimedChatsOriginal; }
  
  public filterByMyUnreadMessages() {
    const loggedInUserClaimedChats = this.getLoggedInUserClaimedChats();
    this.setClaimedChats = loggedInUserClaimedChats.filter(cc => cc.unreadMessages && cc.unreadMessages > 0);
  }

  public filterByAllUnreadMessages() {
    const allClaimedChats = this._claimedChatsOriginal;
    this.setClaimedChats = allClaimedChats.filter(cc => cc.unreadMessages && cc.unreadMessages > 0);
  }

  public filterByMyActiveChats() {
    const loggedInUserClaimedChats = this.getLoggedInUserClaimedChats();
    const chats = loggedInUserClaimedChats.filter(cc => !this.chatService.hasChatExpired(cc.chatExpiryDate));
    this.setClaimedChats = chats;
  }

  public filterByAllActiveChats() {
    const allClaimedChats = this._claimedChatsOriginal;
    const chats = allClaimedChats.filter(cc => !this.chatService.hasChatExpired(cc.chatExpiryDate));
    this.setClaimedChats = chats;
  }

  public filterByMyExpiredChats() {
    const loggedInUserClaimedChats = this.getLoggedInUserClaimedChats();
    const chats = loggedInUserClaimedChats.filter(cc => this.chatService.hasChatExpired(cc.chatExpiryDate));
    this.setClaimedChats = chats;
  }

  public filterByAllExpiredChats() {
    const allClaimedChats = this._claimedChatsOriginal;
    const chats = allClaimedChats.filter(cc => this.chatService.hasChatExpired(cc.chatExpiryDate));
    this.setClaimedChats = chats;
  }

  public handleClaimedChatsPanel(): void {
    if (!this.getClaimedChats) return;

    //order the chats
    this.orderClaimedChatsByLatestMessage();

    let claimedChatsPanel: ClaimedChats[] = [];

    for (const chat of this.getClaimedChats) {
      //get an array of group ids from our group chats
      const groupIds = claimedChatsPanel.map(gc => gc.groupId);

      //group does not exist
      //so we can go ahead and add all the chats to this group
      if (!groupIds.includes(chat.groupId)) {
        //get all chats with the current chat group id
        let gc = this.getClaimedChats.filter(cc => cc.groupId === chat.groupId);

        const groupExpanded = this.claimedChatsPanel?.find(gc => gc.groupId === chat.groupId)?.groupExpanded;

        claimedChatsPanel.push({
          groupId: chat.groupId,
          groupName: `${chat.groupName} (${gc.length})`,
          groupChatsLength: gc.length,
          groupExpanded: groupExpanded ?? true,
          chats: gc
        });
      }
    }

    this.claimedChatsPanel = claimedChatsPanel;
  }

  public handleChatsReassignToUser(reassignedChats: Chat[], userId: number, loginName: string) {
    const claimedChatOrig = this.getClaimedChatsOriginal;

    for (const chat of claimedChatOrig) {
      const index = reassignedChats.findIndex(c => c.chatId === chat.chatId);

      chat.reassigned = false;

      if (index === -1) continue;

      chat.claimedBy = loginName;
      chat.claimedByUserId = userId;
      chat.reassigned = true;
      chat.chatSelected = false;
      chat.messages = [...reassignedChats[index].messages]
    }

    this.setClaimedChatsOriginal = [...claimedChatOrig];
    this.applyChatFilterIfThereIsAFilter();
    this.handleClaimedChatsPanel();
  }

  public handleClosingAndReassigningChatToGroup() {
    const index = this.getClaimedChats.findIndex(cc => cc.chatId == this.getSelectedClaimChat?.chatId);
    if (index >= 0) {
      //remove chat from claimed chats
      this.getClaimedChats.splice(index, 1);
      this.getClaimedChatsOriginal.splice(index, 1);
      this.setSelectedClaimChat = null;
      //redo the sort and group by
      this.handleClaimedChatsPanel();
    }
  }

  public handleUserDisconnect(userId: number, loginName: string) {
    this.setClaimedChatsOriginal = this.getClaimedChatsOriginal?.filter(cc => cc.claimedByUserId !== userId);
    this.setClaimedChats = this.getClaimedChats?.filter(cc => cc.claimedByUserId !== userId);
    this.resetReassignedChats();
    this.handleClaimedChatsPanel();
    this.toastMessageService.showToastMessage(`${loginName} Logged/Time out`, 'toast-message-info');
  }

  public handleUserLoggedClaimedChats(chats: Chat[], loginName: string) {
    this.toastMessageService.showToastMessage(`${loginName} logged in`, 'toast-message-info');
    const chatIds: number[] = chats.map(c => c.chatId);
    this.chatRoomService.handleRemovingChatsFromRoom(chatIds);

    if (!this.getClaimedChatsOriginal || !this.getClaimedChats) return;

    for (const chat of chats) {
      const foundOrigChat = this.getClaimedChatsOriginal.find(c => c.chatId === chat.chatId);
      const foundClaimedChat = this.getClaimedChats.find(c => c.chatId === chat.chatId);

      //if this chat does not exist in either the original or claimed chats array add chat
      if (!foundOrigChat) this.getClaimedChatsOriginal.push(chat);
      if (!foundClaimedChat) this.getClaimedChats.push(chat);
      
      const foundGroup = this.claimedChatsPanel?.find(gc => gc.groupId === chat.groupId);

      //group doesn't exist so add it
      if (!foundGroup) {
        this.claimedChatsPanel?.push({
          groupId: chat.groupId,
          groupName: `${chat.groupName}`,
          groupChatsLength: 0,
          groupExpanded: true,
          chats: [],
        });
      }

      this.claimedChatsPanel?.map(gc => {
        if (gc.groupId === chat.groupId) {
          const found = gc.chats.find(ch => ch.chatId === chat.chatId);
          //if the chat does not exist in the group then add it
          if (!found) {
            gc.chats.push(chat);
            gc.groupName = `${chat.groupName} (${gc.chats.length})`;
          }
        }
      });
    }

    if (!this.selectedFilterChatOption) {
      this.filterByMyClaimedChats();
    } else {
      this.selectedFilterChatOption.method.call(this);
    }

    this.handleClaimedChatsPanel();
  }

  //user has changed product
  //need to remove all the chats from the claimed chats
  public handleProductChange(userId: number, loginName: string, productName: string) {
    this.toastMessageService.showToastMessage(`${loginName} changed to ${productName}`, 'toast-message-info');
    this.setClaimedChatsOriginal = this.getClaimedChatsOriginal.filter(cc => cc.claimedByUserId !== userId);
    this.setClaimedChats = this.getClaimedChats.filter(cc => cc.claimedByUserId !== userId);
    this.handleClaimedChatsPanel();
  }

  public handleInboundWhatsappMessage(msg: Message) {
    const message: Message = {
      messageId: msg.messageId,
      body: msg.body,
      chatId: msg.chatId,
      direction: msg.direction,
      whatsappUserFirstName: msg.whatsappUserFirstName,
      whatsappUserLastName: msg.whatsappUserLastName,
      sendDate: msg.sendDate,
      whatsappUserNumber: msg.whatsappUserNumber,
      mediaUrl: msg.mediaUrl,
      extension: msg.extension,
      fileName: msg.fileName,
      friendlyName: msg.friendlyName
    };

    const chatIndex = this.getClaimedChats.findIndex(cc => cc.chatId === msg.chatId);

    if (chatIndex == -1) return;

    const chat = this.getClaimedChats[chatIndex];

    if (this.browserService.browserFocused && this.getSelectedClaimChat?.chatId === msg.chatId) {
      //don't increase
    } else if (this.getSelectedClaimChat?.chatId !== msg.chatId || (!this.browserService.browserFocused && this.getSelectedClaimChat?.chatId === msg.chatId)) {
      //increased unread messages count
      if (!chat.unreadMessages) chat.unreadMessages = 1;
      else chat.unreadMessages++;
    }

    //update chat expiry date
    chat.chatExpiryDate = new Date(moment(new Date()).add(1, 'day').format("YYYY/MM/DD HH:mm:ss"));
    //add new inbound message
    chat.messages.push(message);

    //refresh selected claimed chat messages
    if (this.getSelectedClaimChat?.chatId === msg.chatId) {
      this.getSelectedClaimChat.messages = [...chat.messages];
    }

    this.handleClaimedChatsPanel();
  }

  public searchClaimedChats(): void {
    try {
      this.resetReassignedChats();
      const filters = ['firstName', 'lastName', 'whatsappUserNumber', 'refNo'];

      //not searching
      if (!this.search) {
        //reset claimed chats to original
        this.setClaimedChats = [...this.getClaimedChatsOriginal];
        this.applyChatFilterIfThereIsAFilter();
        this.handleClaimedChatsPanel();
        return;
      }

      this.applyChatFilterIfThereIsAFilter();

      //filtering
      this.setClaimedChats = _.filter(this.getClaimedChats, (item) => {
        return _.some(filters, (filter) => {
          const value = item[filter];
          const searchValue = this.search.toLowerCase().trim();
          return (value && value.toLowerCase().includes(searchValue));
        });
      });

      this.handleClaimedChatsPanel();
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to search chats.', 'toast-message-error');
      this.error.handleError('Failed to search chats.', err, 'claimed.chats.service.searchClaimedChats()');
    }
  }

  public clearSearch(): void {
    if (!this.search) return;
    
    this.search = '';
    this.searchClaimedChats();
  }
  
  private orderClaimedChatsByLatestMessage(): void {
    this.setClaimedChats = _.orderBy(this.getClaimedChats, (chat) => {
      const latestMessage = _.maxBy(chat.messages, 'sendDate');
      return latestMessage?.sendDate ? new Date(latestMessage.sendDate).getTime() : 0;
    }, 'desc');
  }

  private getLoggedInUserClaimedChats(): Chat[] {
    if (!this.authService?.user || !this.authService?.user?.loginName) return [];
    const claimedChats = this.getClaimedChatsOriginal.filter(cc => cc.claimedBy.toLowerCase() === this.authService?.user?.loginName?.toLowerCase());
    return claimedChats;
  }

  private resetReassignedChats(): void {
    //resset all chats reassigned to false
    this.getClaimedChats.forEach(c => {
      if (c.reassigned !== false) c.reassigned = false;
    });
  }
}