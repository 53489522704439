<mat-form-field appearance="fill" class="drop-down">
  <mat-label>{{placeHolder}}</mat-label>
  <button mat-button *ngIf="showClear && selectedDropDown" matSuffix mat-icon-button aria-label="Clear"
    (click)="selectedDropDown=undefined; $event.stopPropagation(); dropDownOptionSelect($event)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-select [disabled]="disabled" #select="matSelect" [(ngModel)]="selectedDropDown" name="selectedDropDown"
    (selectionChange)="dropDownOptionSelect($event)" (openedChange)="focusInput()">
    <div *ngIf="filter" class="filter-search">
      <input [placeholder]="filterPlaceHolder" #filterInput (keyup)="onFilter()" name="filterSearch" matInput
        [(ngModel)]="filterSearch">
      <p *ngIf="filter && filteredOptions && filteredOptions.length == 0"> No options found </p>
    </div>
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
      <div> {{ option.label }} </div>
    </mat-option>
  </mat-select>
</mat-form-field>
