import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../../services/error.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	public userName: string = '';
	public password: string = '';
	public waitingResponse: boolean = false;

	constructor(
		private auth: AuthService,
		private error: ErrorService,
		private router: Router,
		private toastMessageService: ToastMessageService
	) { }

	public async login() {
		this.waitingResponse = true;
		try {
			const res = await this.auth.loginUser(this.userName, this.password);
			if (!res) this.toastMessageService.showToastMessage('Failed to login', 'toast-message-error');
			else if (res.errorCode !== 0) this.toastMessageService.showToastMessage(res.errorMessage, 'toast-message-error');
			else this.router.navigate(['/chats']);
		}
		catch (error) {
			this.toastMessageService.showToastMessage('Failed to login', 'toast-message-error');
			this.error.handleError('Failed to Login', error, 'login.login()');
		}
		this.waitingResponse = false;
	}
}