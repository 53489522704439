import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public requestNotificationsPermissions() {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }
    else if (Notification.permission === 'granted') {
      return;
    }
    //default = neither deined nor granted
    else if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }
}