import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RoomChat } from '../../../interfaces/chat.interfaces';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ChatService } from '../../services/chats/chat.service';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { DialogType, RoomChatFilterBy, RoomChatOrderBy } from '../../enums';
import * as _ from 'lodash';
import { ChatRoomService } from '../../services/chats/chat.room.service';

@Component({
  selector: 'app-chat-rooms',
  templateUrl: './chat-rooms.component.html',
  styleUrls: ['./chat-rooms.component.scss']
})
export class ChatRoomsComponent implements OnInit, OnDestroy {

  @ViewChild('toolbar', { static: false }) public toolbar: ElementRef | null = null;
  @ViewChild('filters', { static: false }) public filters: ElementRef | null = null;
  @ViewChild('chatListing', { static: false }) public chatListing: ElementRef | null = null;

  public multiSelectMode: boolean = false;
  public showSpinner: boolean = false;
  public disabledOrderByFilter: boolean = true;

  constructor(
    public chatRoomService: ChatRoomService,
    private location: Location,
    public dialog: MatDialog,
    public chatService: ChatService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.refreshChats();
  }

  ngOnDestroy(): void {
    this.chatRoomService.chatRooms.map(room => room.chats.map(chat => chat.chatSelected = false));
    this.chatRoomService.clear();
  }

  public async refreshChats() {
    this.showSpinner = true;
    await this.chatRoomService.getChatRooms();
    if (this.chatRoomService.chatRooms?.length > 0 && !this.chatRoomService.selectedTab) this.chatRoomService.handleChatFilterLabels(0);
    this.showSpinner = false;
  }

  public multiSelectModeChange() {
    this.multiSelectMode = !this.multiSelectMode;
    if (!this.multiSelectMode) {
      //set chat selected to false when we go off multi-select mode
      this.chatRoomService.chatRooms.map((chatRoom) => chatRoom.chats.map((chat) => chat.chatSelected = false));
      this.chatRoomService.selectedChats = [];
    }
  }

  public onChatSelection(chat: RoomChat) {
    if (chat.chatSelected) {
      this.chatRoomService.selectedChats.push(chat);
    } else {
      const index = this.chatRoomService.selectedChats.indexOf(chat);
      this.chatRoomService.selectedChats.splice(index, 1);
    }
  }

  //gets called in the html
  //used to single closing of a chat
  //and also when we are bulk closing chats
  public handleCloseChatDialog(chats: RoomChat[]) {
    const dialog = this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.CLOSE_CHAT, chats: chats },
      autoFocus: false
    });

    dialog.afterClosed().subscribe(result => {
      //user did not say yes for closing chat
      if (!result) {
        if (chats.length == 1) {
          chats.map(chat => chat.chatSelected = false);
          this.chatRoomService.selectedChats = [];
        }
        return;
      }

      //remove all the chats that were selected from the chats array in the room
      this.chatRoomService.chatRooms.map(chatRoom => chatRoom.chats = chatRoom.chats.filter(chat => !chats.includes(chat)));

      //refresh the room heading
      this.chatRoomService.handleRoomHeading();
      //recalculate the chats in room
      this.chatRoomService.calculateChatsInRoom();
      this.chatRoomService.selectedChats = [];
    });
  }

  public bulkClaimChat() {
    const dialog = this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.BULK_CLAIM_CHAT, chats: this.chatRoomService.selectedChats },
      autoFocus: false
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        if (this.chatRoomService.selectedChats.length == 1) {
          this.chatRoomService.selectedChats.map(chat => chat.chatSelected = false);
          this.chatRoomService.selectedChats = [];
        }
        return;
      }
    });
  }

  public previewClaimChatMessages(chat: RoomChat) {
    chat.chatSelected = true;

    const dialog = this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.PREVIEW_CLAIM_CHAT, chats: [chat] },
      autoFocus: false
    });

    dialog.afterClosed().subscribe(() => { chat.chatSelected = false; });
  }

  public onChatFilter(chatFilter: RoomChatFilterBy) {
    this.chatRoomService.chatFilter = chatFilter;

    if (!this.chatRoomService.chatFilter) {
      this.chatRoomService.orderFilter = RoomChatOrderBy.DESCENDING;
      this.disabledOrderByFilter = true;
    }

    if (this.chatRoomService.chatFilter != RoomChatFilterBy.CHAT_DATE) this.disabledOrderByFilter = true;
    else this.disabledOrderByFilter = false;

    this.chatRoomService.calculateChatsInRoom();
    this.chatRoomService.handleRoomHeading();
  }

  public calculateHeight() {
    if (!this.toolbar || !this.chatListing || !this.filters) {
      return;
    }

    const toolbarHeight = this.toolbar.nativeElement.offsetHeight;
    const filtersHeight = this.filters.nativeElement.offsetHeight;
    const messageWindowHeight = window.innerHeight - toolbarHeight - filtersHeight - 45;

    //only if the calculated height is different to what it currently is then we will set message window height
    if (this.chatListing.nativeElement.style.height !== messageWindowHeight) {
      return `${messageWindowHeight}px`;
    }

    return null;
  }


  public onOrderBy(orderBy: RoomChatOrderBy) {
    this.chatRoomService.orderFilter = orderBy;
  }

  public goBack() {
    this.location.back();
  }
}