import { Component, Inject, OnInit } from '@angular/core';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { DialogService } from '../dialog.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { DialogComponent } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimChatDialog } from '../../../../interfaces/dialog.interfaces';
import { Dropdown, Groups } from '../../../../interfaces/global.interfaces';
import { UserApiService } from '../../../services/api/user.api.service';
import { Router } from '@angular/router';
import { SocketService } from '../../../services/socket.service';
import { InternalMessage } from '../../../../interfaces/internal.message.interfaces';
import { InternalMessageType } from '../../../enums';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorService } from '../../../services/error.service';
@Component({
  selector: 'claim-room-chat-dialog',
  templateUrl: './claim-room-chat-dialog.component.html',
  styleUrls: ['./claim-room-chat-dialog.component.scss']
})
export class ClaimRoomChatDialogComponent implements OnInit {

  public groupOptions: Dropdown[] = [];
  public selectedGroupOptionId: number | null = null;

  constructor(
    private chatApiService: ChatApiService,
    private userApiService: UserApiService,
    private dialogService: DialogService,
    private toastMessageService: ToastMessageService,
    public dialogRef: MatDialogRef<DialogComponent>,
    private socketService: SocketService,
    private router: Router,
    private error: ErrorService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: ClaimChatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.dialogService.showSpinner = true;
    await this.getUsersGroups();
    this.dialogService.showSpinner = false;
  }

  private async getUsersGroups(): Promise<void> {
    try {
      const resp = await this.userApiService.getUsersGroups();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get groups.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        resp.result.map((group: Groups) => {
          this.groupOptions.push({
            label: group.txt,
            value: group.id
          });
        });
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get groups.', 'toast-message-error');
      this.error.handleError('Failed to get groups', err, 'claim-room-chat-dialog.getUserGroups()');
    }
  }

  public onGroupSelect(groupId: number): void { this.selectedGroupOptionId = groupId; }

  public async claimChat(): Promise<void> {
    if (!this.selectedGroupOptionId || !this.authService.userId) return;

    this.dialogService.showSpinner = true;
    try {
      const chatIds = this.data.chats.map(chat => chat.chatId);;
      const resp = await this.chatApiService.claimChat(chatIds, this.selectedGroupOptionId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to claim chat.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage('Chat claimed.', 'toast-message-success');
        const internalMessage: InternalMessage = {
          internalMessage: {
            chats: this.data.chats,
            serverUserId: this.authService.userId
          },
          internalMessageType: InternalMessageType.NOTIFY_USERS_CHAT_CLAIMED
        };
        this.socketService.sendInternalMessage(internalMessage);
        this.cancel();
        this.router.navigate(['/chats']);
      }
    } catch (err) {
      this.error.handleError('Failed to claim chat', err, 'claim-room-chat-dialog.claimChat()');
      this.toastMessageService.showToastMessage('Failed to claim chat.', 'toast-message-error');
    }
    this.dialogService.showSpinner = false;
  }

  public cancel() {
    this.dialogRef.close();
  }
}