<div *ngIf="showSpinner">
  <spinner></spinner>
</div>

<div *ngIf="sendingTemplate">
  <spinner></spinner>
</div>

<div class="content">
  <div class="page-toolbar toolbar">
    <button mat-icon-button (click)="goBack()" matTooltip="Go back">
      <mat-icon class="toolbar-buttons">arrow_back</mat-icon>
    </button>
    <label>Template Manager</label>
  </div>
  <div class="template" *ngIf="!showSpinner">
    <div class="drop-downs">
      <drop-down (onDropdownOptionSelect)="getTemplateParams($event)" [filter]="true" placeHolder="Select a template"
        [selectedDropDown]="selectedTemplate" filterPlaceHolder="Filter Templates" [options]="templateDropdownOptions"
        [showClear]="true">
      </drop-down>

      <drop-down (onDropdownOptionSelect)="onContactSelected($event)" [filter]="true"
        [selectedDropDown]="selectedContact" placeHolder="Select a WhatsApp User"
        filterPlaceHolder="Filter WhatsApp Users" [showClear]="true" [options]="contactDropdownOptions">
      </drop-down>
    </div>
    <div class="template-content">

      <h2 class="title">
        Template message
      </h2>

      <label class="no-template" *ngIf="!selectedTemplate && !templateParams">Please select a template</label>

      <label *ngIf="selectedTemplate && templateParams" class="template-body">
        <ng-container *ngIf="selectedTemplate.body">
          <ng-container *ngFor="let variableName of selectedTemplate.body.split('#'); let isValue = odd;">
            <ng-container *ngIf="isValue; else nonValue">
              <strong>{{ getVariableValueOrVariableName(variableName) }}</strong>
            </ng-container>
            <ng-template #nonValue>{{ variableName }}</ng-template>
          </ng-container>
        </ng-container>
      </label>

      <h2 *ngIf="templateParams?.length >= 1" class="title">
        Replace the fields
      </h2>

      <div class="replace-fields">
        <form class="template-dialog" #f="ngForm">
          <ng-container *ngFor="let templateParam of templateParams">
            <mat-form-field class="form-field" *ngIf="templateParam.variableType == 'number'">
              <mat-label>{{ templateParam.display }}</mat-label>
              <input type="number" min="0" maxlength="60" name="{{ templateParam.variableName }}" matInput required
                [(ngModel)]="templateParam.value">
            </mat-form-field>

            <mat-form-field class="form-field" *ngIf="templateParam.variableType == 'string'">
              <mat-label>{{ templateParam.display }}</mat-label>
              <input maxlength="60" name="{{ templateParam.variableName }}" matInput required
                [(ngModel)]="templateParam.value">
            </mat-form-field>

            <mat-form-field *ngIf="templateParam.variableType == 'date'" class="form-field">
              <mat-label>{{ templateParam.display }}</mat-label>
              <input hidden name="{{ templateParam.variableName }}" readonly matInput required
                [(ngModel)]="templateParam.value" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <label>{{ templateParam.value | date: 'YYYY/MM/dd' }}</label>
            </mat-form-field>

            <mat-form-field class="form-field" *ngIf="templateParam.variableType == 'datetime'">
              <mat-label>{{ templateParam.display }}</mat-label>

              <input hidden name="{{ templateParam.variableName }}" [(ngModel)]="templateParam.value" type="datetime"
                readonly matInput required [ngxMatDatetimePicker]="dateTimePicker">
              <label>{{ templateParam.value | date: 'YYYY/MM/dd HH:mm a' }}</label>
              <button mat-icon-button matSuffix (click)="dateTimePicker.open(); $event.stopPropagation();">
                <mat-icon>today</mat-icon>
              </button>
              <ngx-mat-datetime-picker #dateTimePicker [showSeconds]="false"></ngx-mat-datetime-picker>
            </mat-form-field>
          </ng-container>
        </form>

        <button *ngIf="selectedTemplate && templateParams" mat-button [disabled]="!f.valid || !selectedContact"
          (click)="sendTemplate()">Broadcast
          Template</button>
      </div>
    </div>
  </div>
</div>
