import { Injectable } from "@angular/core";
import { ChatRooms, RoomChat } from "../../../interfaces/chat.interfaces";
import { ChatRoomTab, Dropdown } from "../../../interfaces/global.interfaces";
import { AuthService } from "../auth/auth.service";
import { ChatApiService } from "../api/chat.api.service";
import { ToastMessageService } from "src/app/components/toast-message/toast.message.service";
import { ErrorService } from "../error.service";
import { ChatStatus, RoomChatFilterBy, RoomChatOrderBy } from "../../enums";
import { ChatService } from "./chat.service";
import * as _ from "lodash";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ChatRoomService {

  private _heading: string = '';
  private _chatRooms: ChatRooms[] = [];
  private _totalChats: number = 0;
  private _chatFilter: RoomChatFilterBy = RoomChatFilterBy.TALK_TO_AN_AGENT;
  private _selectedTab: ChatRoomTab | null = null;
  private _selectedChats: RoomChat[] = [];
  private _chatFilterOptions: Dropdown[] = [
    {
      label: 'Talk To An Agent',
      value: RoomChatFilterBy.TALK_TO_AN_AGENT
    },
    {
      label: 'Chat Date',
      value: RoomChatFilterBy.CHAT_DATE
    },
    {
      label: 'Expired Chats',
      value: RoomChatFilterBy.EXPIRED_CHATS
    },
    {
      label: 'Active Chats',
      value: RoomChatFilterBy.ACTIVE_CHATS
    }
  ];

  private _orderFilter: RoomChatOrderBy = RoomChatOrderBy.DESCENDING;
  private _orderFilterOptions: Dropdown[] = [
    {
      label: 'Ascending',
      value: RoomChatOrderBy.ASCENDING
    },
    {
      label: 'Descending',
      value: RoomChatOrderBy.DESCENDING
    }
  ];


  public get heading(): string { return this._heading; }

  public get chatRooms(): ChatRooms[] { return this._chatRooms; }

  public get totalChats(): number { return this._totalChats; }

  public get selectedTab(): ChatRoomTab | null { return this._selectedTab; }

  public get selectedChats(): RoomChat[] { return this._selectedChats; }

  public get chatFilter(): RoomChatFilterBy { return this._chatFilter; }

  public get orderFilter(): RoomChatOrderBy { return this._orderFilter; }

  public get orderFilterOptions(): Dropdown[] { return this._orderFilterOptions; }

  public get chatFilterOptions(): Dropdown[] { return this._chatFilterOptions; }

  public set heading(heading: string) { this._heading = heading; }

  public set chatRooms(chatRooms: ChatRooms[]) { this._chatRooms = chatRooms; }

  public set totalChats(totalChats: number) { this._totalChats = totalChats; }

  public set selectedTab(tab: ChatRoomTab | null) { this._selectedTab = tab; }

  public set selectedChats(chatRooms: RoomChat[]) { this._selectedChats = chatRooms; }

  public set chatFilterOptions(chatFilterOptions: Dropdown[]) { this._chatFilterOptions = chatFilterOptions; }

  public set chatFilter(chatFilter: RoomChatFilterBy) { this._chatFilter = chatFilter; }

  public set orderFilter(orderByFiler: RoomChatOrderBy) { this._orderFilter = orderByFiler; }

  constructor(
    private authService: AuthService,
    private chatApiService: ChatApiService,
    private chatService: ChatService,
    private error: ErrorService,
    private toastMessageService: ToastMessageService) { }

  public async getChatRooms(): Promise<void> {
    try {
      const resp = await this.chatApiService.getChatRooms();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get chat rooms', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.chatRooms = resp.result;
        if (this.chatRooms?.length > 0) {
          this.selectedChats = [];
          this.calculateChatsInRoom();
          this.handleRoomHeading();
        }
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get chat rooms', 'toast-message-error');
      this.error.handleError('Failed to get chat rooms.', err, 'chat.room.service.getChatRooms()');
    }
  }

  public handleRemovingChatsFromRoom(chatIds: number[]) {
    if (!this.chatRooms) return;
    const chatRooms = this.chatRooms;

    for (const chatRoom of chatRooms) {
      chatRoom.chats = chatRoom.chats.filter(chat => !chatIds.map(chatId => chatId).includes(chat.chatId));
    }

    this.chatRooms = [...chatRooms];
    this.handleRoomHeading();
    this.calculateChatsInRoom();
  }

  public handleRoomHeading() {
    let totalChatCount: number = 0;

    this.chatRooms.map((chatRoom) => {
      const chats = this.filterChats(chatRoom.chats, this.chatFilter, this.orderFilter);
      totalChatCount += chats.length;
    });

    this.heading = `${this.authService.user?.selectedProduct?.txt} Chat Rooms (${totalChatCount})`;
  }

  public handleChatFilterLabels(roomIndex: number) {
    this.selectedTab = {
      index: roomIndex,
      txt: this.chatRooms[roomIndex].roomName
    };

    const activeChatFilterIndex = this.chatFilterOptions.findIndex(chatFilter => chatFilter.value == RoomChatFilterBy.ACTIVE_CHATS);
    const expiredChatFilterIndex = this.chatFilterOptions.findIndex(chatFilter => chatFilter.value == RoomChatFilterBy.EXPIRED_CHATS);
    const talkToAgentChatFilterIndex = this.chatFilterOptions.findIndex(chatFilter => chatFilter.value == RoomChatFilterBy.TALK_TO_AN_AGENT);

    const activeChats = this.chatRooms[roomIndex].chats.filter(chat => !this.chatService.hasChatExpired(chat.chatExpiryDate));
    const expiredChats = this.chatRooms[roomIndex].chats.filter(chat => this.chatService.hasChatExpired(chat.chatExpiryDate));
    const talkToAgentChats = this.chatRooms[roomIndex].chats.filter(chat => chat.chatStatusId === ChatStatus.OPEN_BUT_REQUESTED_AGENT);

    this.chatFilterOptions[activeChatFilterIndex].label = `Active Chats (${activeChats.length})`;
    this.chatFilterOptions[expiredChatFilterIndex].label = `Expired Chats (${expiredChats.length})`;
    this.chatFilterOptions[talkToAgentChatFilterIndex].label = `Talk To An Agent (${talkToAgentChats.length})`;

    //need to do this in order for the dropdown options to trigger a change
    this.chatFilterOptions = [...this.chatFilterOptions];
  }

  public filterChats(chats: RoomChat[], selectedChatFilter: RoomChatFilterBy, selectedOrderFilter: RoomChatOrderBy): RoomChat[] {
    switch (selectedChatFilter) {
      case RoomChatFilterBy.CHAT_DATE:
        return this.filterByChatDate(chats, selectedOrderFilter);

      case RoomChatFilterBy.EXPIRED_CHATS:
        return this.filterByExpiredChats(chats);

      case RoomChatFilterBy.ACTIVE_CHATS:
        return this.filterByActiveChats(chats);

      case RoomChatFilterBy.TALK_TO_AN_AGENT:
        return this.filterByTalkToAnAgent(chats);

      // other types of filters if they come up with any other ones
      default:
        return [] as RoomChat[];
    }
  }

  private filterByChatDate(chats: RoomChat[], selectedOrderFilter: RoomChatOrderBy): RoomChat[] {
    chats = _.sortBy(chats, (chat) => {
      const insertDate = moment(chat.insertDate).format('YYYY/HH/DD hh:mm a');
      const currentDate = moment(new Date()).format('YYYY/HH/DD hh:mm a');
      if (insertDate > currentDate) {
        return chat;
      }
    });
    //if no selected order by, default to desc
    if (!selectedOrderFilter) selectedOrderFilter = RoomChatOrderBy.DESCENDING;

    const orderBy = selectedOrderFilter == RoomChatOrderBy.ASCENDING ? 'asc' : 'desc';

    return _.orderBy(chats, ['insertDate'], [orderBy]);
  }

  private filterByExpiredChats(chats: RoomChat[]): RoomChat[] {
    return _.filter(chats, (chat) => {
      const expired = this.chatService.hasChatExpired(chat.chatExpiryDate);
      return expired;
    });
  }

  private filterByActiveChats(chats: RoomChat[]): RoomChat[] {
    return _.filter(chats, (chat) => {
      const expired = this.chatService.hasChatExpired(chat.chatExpiryDate);
      return !expired;
    });
  }

  private filterByTalkToAnAgent(chats: RoomChat[]): RoomChat[] {
    return _.filter(chats, (chat) => {
      return chat.chatStatusId === ChatStatus.OPEN_BUT_REQUESTED_AGENT;
    });
  }

  public clear() {
    this.chatRooms = [];
    this.selectedTab = null;
    this.heading = '';
    this.totalChats = 0;
  }

  public calculateChatsInRoom() {
    this.chatRooms.map((chatRoom) => {
      const chats = this.filterChats(chatRoom.chats, this.chatFilter, this.orderFilter);
      chatRoom.roomTabName = `${chatRoom.roomName} (${chats.length})`;
    });
  }
}