import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Dropdown } from '../../../interfaces/global.interfaces';
import { MatSelectChange } from '@angular/material/select';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit, OnChanges {

  @Input() public filter: boolean = false;
  @Input() public showClear: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public placeHolder: string = '';
  @Input() public filterPlaceHolder?: string;
  @Input() public options: Dropdown[] = [];
  @Input() public selectedDropDown: any;
  @Output() public onDropdownOptionSelect: EventEmitter<any> = new EventEmitter<any>();

  private _originalOptions: Dropdown[] = [];
  private previousSearch: string = '';
  private filterChanged = new Subject<string>();

  public get originalOptions(): Dropdown[] { return this._originalOptions; }
  public set originalOptions(options: Dropdown[]) { this._originalOptions = options; };
  public get filteredOptions(): Dropdown[] { return this.options; }
  public set filteredOptions(options: Dropdown[]) { this.options = options; }
  public filterSearch: string = '';

  @ViewChild('select', { static: true }) select: any;
  @ViewChild('filterInput') filterInput: ElementRef | null = null;
  
  constructor() { this.filterChanged.pipe(distinctUntilChanged()).subscribe(filter => this.filterDropdownOptions(filter)); }

  ngOnInit() {
    this.processOptions();
    this.preventSpaceBarSelectingDropdownOption();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options && this.originalOptions != changes?.options?.currentValue) {
      this.originalOptions = changes.options.currentValue;
    }
  }

  public dropDownOptionSelect(event: MatSelectChange) {
    this.onDropdownOptionSelect.emit(event.value);
  }

  public onFilter() {
    //if nothing when filtering, set filteredOptions back to the original options array
    if (!this.filterSearch) {
      // this.originalOptions = [...this.originalOptions];
      this.filteredOptions = [...this.originalOptions];
    }

    if (this.filterSearch !== this.previousSearch) {
      this.filterChanged.next(this.filterSearch);
      this.previousSearch = this.filterSearch;
    }
  }

  //auto focus when opening the dropdown
  public focusInput() {
    if (!this.filterInput || !this.filterInput.nativeElement) return;
    this.filterInput.nativeElement.focus();
  }

  private processOptions() {
    //remove all extra spaces in the label property
    this.options = this.options.map(option => ({
      ...option,
      label: option.label.replace(/\s+/g, ' ').trim()
    }));
  }

  private preventSpaceBarSelectingDropdownOption() {
    //preventing the space bar selecting a dropdown option
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.key === ' ') return;
      if (!this.select.disabled) {
        this.select.panelOpen ? this.select._handleOpenKeydown(event) : this.select._handleClosedKeydown(event);
      }
    };
  }

  private filterDropdownOptions(filter: string) { this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase())); }
}