import { Component, OnInit } from '@angular/core';
import { ChatApiService } from '../../services/api/chat.api.service';
import { ClaimedChatsService } from '../../services/chats/claimed.chats.service';
import { Dropdown, MenuOptions } from '../../../interfaces/global.interfaces';
import { AuthService } from '../../services/auth/auth.service';
import { Chat } from '../../../interfaces/chat.interfaces';
import * as _ from 'lodash';
import { UserApiService } from '../../services/api/user.api.service';
import { ProductService } from '../../services/products.service';
import { ToastMessageService } from '../toast-message/toast.message.service';
import { Products } from '../../../interfaces/user.interfaces';
import { DialogType } from '../../enums';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'chats-panel',
  templateUrl: './chats-panel.component.html',
  styleUrls: ['./chats-panel.component.scss']
})
export class ChatsPanelComponent implements OnInit {

  public search: string = '';
  public selectedProduct: Products | undefined | null = null;
  public multiModeOptions: MenuOptions[] = [
    {
      icon: 'cancel',
      label: 'Close Chat',
      method: this.openDialog.bind(this, DialogType.CLOSE_CHAT),
      disabled: false
    },
    {
      icon: 'reply',
      label: 'Reassign Chat',
      method: this.openDialog.bind(this, DialogType.REASSIGN_CHAT),
      disabled: false
    }
  ];
  public selectedClaimedChats: Chat[] = [];
  public productOptions: Dropdown[] = [];
  
  public multiSelectMode: boolean = false;
  public overlay: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    private chatApiService: ChatApiService,
    public claimedChatsService: ClaimedChatsService,
    public authService: AuthService,
    public productService: ProductService,
    private error: ErrorService,
    public dialog: MatDialog,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService
  ) { }

  async ngOnInit() { await this.pageLoad(); }

  private async pageLoad(): Promise<void> {
    this.showSpinner = true;
    await this.getUserProducts();
    this.showSpinner = false;
  }
  
  private async getUserProducts(): Promise<void> {
    try {
      const resp = await this.userApiService.getUserProducts();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get products', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.authService.userProducts = resp.result;
        const selectedProduct = this.authService.userProducts.find(x => x.isDefaultProduct == 1);
        if (!selectedProduct) return;
        this.selectedProduct = selectedProduct;
        this.authService.setProduct = selectedProduct;
        this.productOptions = this.authService.userProducts.map((up: Products) => {
          return {
            label: up.txt,
            value: up
          };
        });
        await this.getClaimedChats();
      }
    } catch (err) {
      this.error.handleError('Failed to get products', err, 'chats-panel.getUserProducts()');
      this.toastMessageService.showToastMessage('Failed to get products', 'toast-message-error');
    }
  }

  private async getClaimedChats(): Promise<void> {
    try {
      const resp = await this.chatApiService.getClaimedChats();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get claimed chats', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.handleChatsWithUnreadMessages(resp.result);
        this.claimedChatsService.applyChatFilterIfThereIsAFilter();
        this.claimedChatsService.handleClaimedChatsPanel();
        this.autoSelectClaimedChat();
      }
    } catch (err) {
      this.error.handleError('Failed to get claimed chats', err, 'chats-panel.getClaimedChats()');
      this.toastMessageService.showToastMessage('Failed to get claimed chats', 'toast-message-error');
    }
  }

  //before we reassign the new incoming chats to the claimed chats we check a few things first
  //if there are no claimed chats we set claimed chats
  //but if there are claimed chats we check if there are any chats with unread messages
  //if there are unread messages we preserve them
  //if not we override claimed chats
  private handleChatsWithUnreadMessages(chats: Chat[]) {
    if (!this.claimedChatsService.getClaimedChats) {
      this.claimedChatsService.setClaimedChats = [...chats];
      this.claimedChatsService.setClaimedChatsOriginal = [...chats];
      return;
    }

    const unreadMessages = this.claimedChatsService.getClaimedChatsOriginal.filter(c => c.unreadMessages && c.unreadMessages > 0);
    //no unread messages
    if (!unreadMessages || unreadMessages.length === 0) {
      this.claimedChatsService.setClaimedChats = [...chats];
      this.claimedChatsService.setClaimedChatsOriginal = [...chats];
      return;
    }

    //there are unread messages so we need to preserve them
    chats.forEach(c => {
      unreadMessages.map(u => {
        if (c.chatId === u.chatId) {
          c.unreadMessages = u.unreadMessages;
        }
      });
    });

    this.claimedChatsService.setClaimedChats = [...chats];
    this.claimedChatsService.setClaimedChatsOriginal = [...chats];
  }

  public chatSelected(chat: Chat) {
    if (this.multiSelectMode) {
      if (chat.chatSelected) {
        this.selectedClaimedChats.push(chat);
      } else {
        const index = this.selectedClaimedChats.indexOf(chat);
        this.selectedClaimedChats.splice(index, 1);
      }
    } else {
      //if there is currently a selected claim chat
      //and the current claimed chat chat id is not the new selected chat id
      if (this.claimedChatsService.getSelectedClaimChat && this.claimedChatsService.getSelectedClaimChat.chatId !== chat.chatId) {
        //find the chat and set chat selected to false 
        //because a new chat has been selected
        this.claimedChatsService.claimedChatsPanel?.map(cg => cg.chats.map(chat => {
          if (chat.chatId == this.claimedChatsService.getSelectedClaimChat?.chatId) {
            chat.chatSelected = false;
            chat.unreadMessages = null;
          };
        }));
      }
      this.claimedChatsService.setSelectedClaimChat = chat;
    }
  }

  private autoSelectClaimedChat() {
    const chatId = this.claimedChatsService.claimedChatIdFromRoom ? this.claimedChatsService?.claimedChatIdFromRoom : this.claimedChatsService.getSelectedClaimChat?.chatId;
    const chat = this.claimedChatsService.getClaimedChats.find(chat => chat.chatId === chatId);
    if (!chat) return;
    chat.chatSelected = true;
    this.claimedChatsService.setSelectedClaimChat = chat;
    this.claimedChatsService.claimedChatIdFromRoom = 0;
  }

  public multiSelectModeChange() {
    this.multiSelectMode = !this.multiSelectMode;
    if (!this.multiSelectMode) {
      // set chat selected to false when we go off multi-select mode
      this.claimedChatsService.claimedChatsPanel?.map(cg => cg.chats.map((chat) => chat.chatSelected = false));
      this.selectedClaimedChats = [];
    } else {
      if (this.selectedClaimedChats.length == 0 && this.claimedChatsService.getSelectedClaimChat) {
        this.selectedClaimedChats.push(this.claimedChatsService.getSelectedClaimChat);
        this.claimedChatsService.setSelectedClaimChat = null;
      }
    }
  }

  public async productChange(productChange: Products) {
    this.showSpinner = true;
    try {
      const resp = await this.userApiService.changeProduct(productChange.productId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to change product', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage('Product Changed', 'toast-message-success');
        this.claimedChatsService.setClaimedChatsOriginal = resp.result;
        this.claimedChatsService.setClaimedChats = resp.result;
        this.claimedChatsService.setSelectedClaimChat = null;
        this.claimedChatsService.claimedChatsPanel = null;
        this.authService.setProduct = productChange;
        this.selectedProduct = productChange;
        this.claimedChatsService.applyChatFilterIfThereIsAFilter();
        this.claimedChatsService.handleClaimedChatsPanel();
      }
    } catch (err) {
      this.error.handleError('Failed to change product', err, 'chats-panel.productChange()');
      this.toastMessageService.showToastMessage('Failed to change product', 'toast-message-error');
    }
    this.showSpinner = false;
  }

  private openDialog(dialogTypeId: DialogType): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { dialogTypeId, chats: this.selectedClaimedChats },
      autoFocus: false
    });

    switch (dialogTypeId) {
      case DialogType.CLOSE_CHAT: this.handleClosingOfDialog(dialogRef); break;
      case DialogType.REASSIGN_CHAT: this.handleReassignChatDialog(dialogRef); break;
      default: return;
    }
  }
  
  private handleClosingOfDialog(dialogRef: MatDialogRef<DialogComponent, any>) {
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      const chatIds: number[] = this.selectedClaimedChats.map(chat => chat.chatId);
      this.claimedChatsService.handleRemovingClaimedChats(chatIds);
      this.selectedClaimedChats = [];
      this.multiSelectMode = false;
    });
  }

  private handleReassignChatDialog(dialogRef: MatDialogRef<DialogComponent, any>): void {
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.selectedClaimedChats = [];
    });
  }
}