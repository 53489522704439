import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductService } from '../../services/products.service';
import { SocketService } from '../../services/socket.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() public socketService!: SocketService;
  @Input() public productService!: ProductService;
  @Output() public onMenuStateChange: EventEmitter<any> = new EventEmitter<boolean>();

  constructor() { }
}