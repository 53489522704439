import { Component, Inject } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../dialog.service';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { CloseChatDialog } from '../../../../interfaces/dialog.interfaces';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'close-chat-dialog',
  templateUrl: './close-chat-dialog.component.html',
  styleUrls: ['./close-chat-dialog.component.scss']
})
export class CloseChatDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private dialogService: DialogService,
    private chatApiService: ChatApiService,
    private toastMessageService: ToastMessageService,
    private error: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: CloseChatDialog
  ) { }

  public async closeChat() {
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.chatApiService.closeChats(this.data.chats);
      if (!resp) this.toastMessageService.showToastMessage('Failed to close chat.', 'toast-message-error');
      else if (resp.errorCode !== 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-success');
        this.dialogRef.close(true);
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to close chat.', 'toast-message-error');
      this.error.handleError('Failed to close chat', err, 'close-chat-dialog.closeChat()');
    }
    this.dialogService.showSpinner = false;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}