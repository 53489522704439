import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from './auth/auth.service';
import { Product } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private _bigLogo: string | null = null;
  private _smallLogo: string | null = null;

  public get smallLogo(): string | null {
    switch (this.authService.user?.selectedProduct?.productId) {
      case Product.NOVADT:
      case Product.NOVADT_OLD:
        this.smallLogo = 'assets/product-logos/novadt-small.png';
        break;
      
      case Product.SAEX:
        this.smallLogo = 'assets/product-logos/saex-small.svg';
        break;
      
      default:
        this.smallLogo = 'assets/product-logos/default-small.png';
        break;
    }
    return this._smallLogo;
  }

  public get bigLogo(): string | null {
    switch (this.authService.user?.selectedProduct?.productId) {
      case Product.NOVADT:
      case Product.NOVADT_OLD:
        this.bigLogo = 'assets/product-logos/novadt-big.svg';
        break;
      
      case Product.SAEX:
        this.bigLogo = 'assets/product-logos/saex-big.svg';
        break;
      
      default:
        this.bigLogo = 'assets/product-logos/default-big.png';
        break;
    }
    return this._bigLogo;
  }

  public set bigLogo(logo: string | null) { this._bigLogo = logo; }

  public set smallLogo(logo: string | null) { this._smallLogo = logo; }

  constructor(private authService: AuthService) { }
}