<div class="page-container">
  <div class="full-fixed" *ngIf="authService.isTokenValid()">
    <app-side-menu [socketService]="socketService" [productService]="productService"></app-side-menu>
  </div>

  <div class="contain"
    [ngClass]="{'content-min': authService.isTokenValid(), 'content-max': !authService.isTokenValid()}">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="(socketService.timeout || !socketService.connected) && authService.userId"
    class="center disable-background">
    <spinner [reason]="socketService.notConnectedReason"></spinner>
  </div>
</div>
