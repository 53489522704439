import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private _showSpinner: boolean = false;

  public get showSpinner(): boolean { return this._showSpinner; }

  public set showSpinner(value: boolean) { this._showSpinner = value; }

  constructor() { }
}