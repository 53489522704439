<div *ngIf="showSpinner">
  <spinner></spinner>
</div>

<ng-container>
  <div class="page-toolbar toolbar">
    <div class="left">
      <button mat-icon-button (click)="goBack()" matTooltip="Go back">
        <mat-icon class="toolbar-buttons">arrow_back</mat-icon>
      </button>
    </div>
    <div class="search-container">
      <div class="search">
        <mat-icon>search</mat-icon>
        <input matInput placeholder="Search Contacts" autocomplete="off"
          (keyup)="searchWhatsappUsers($event.target.value)">
      </div>
    </div>
    <label>Contacts</label>
    <div class="right">
      <button mat-icon-button (click)="addWhatsappUserDialog()" matTooltip="Add New Contact">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="refresh()" matTooltip="Refresh Contacts">
        <mat-icon class="toolbar-buttons">refresh</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<div class="whatsapp-users">
  <table mat-table [dataSource]="whatsappUsers" matSort>
    <!-- Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 150px;">First Name</th>
      <td class="whatsapp-names" mat-cell *matCellDef="let whatsappUser">{{ whatsappUser.firstName }}</td>
    </ng-container>

    <!-- Surname Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 150px;">Last Name</th>
      <td class="whatsapp-names" mat-cell *matCellDef="let whatsappUser">{{ whatsappUser.lastName }}</td>
    </ng-container>

    <!-- Phone Number Column -->
    <ng-container matColumnDef="whatsappUserNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 150px;">Number</th>
      <td mat-cell *matCellDef="let whatsappUser">{{ whatsappUser.whatsappUserNumber }}</td>
    </ng-container>

    <!-- Chat Date Column -->
    <ng-container matColumnDef="lastChatDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 200px;">Last Chat Date</th>
      <td mat-cell *matCellDef="let whatsappUser">{{ whatsappUser.lastChatDate }}</td>
    </ng-container>

    <!-- Notes section -->
    <ng-container matColumnDef="notes">
      <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 300px;">Notes</th>
      <td mat-cell *matCellDef="let whatsappUser" style="white-space: pre;">{{ whatsappUser.notes }}</td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="editUser">
      <th mat-header-cell *matHeaderCellDef style="width: 100px;">Actions</th>
      <td mat-cell *matCellDef="let whatsappUser">
        <button mat-button (click)="editWhatsappUserDialog(whatsappUser)" matTooltip="Edit WhatsApp User"
          matTooltipPosition="left">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="no-records"
    *ngIf="!showSpinner && (whatsappUsers.data.length === 0 || whatsappUsers.filteredData.length == 0)">No
    WhatsApp Users found</div>

  <!-- Paginator -->
  <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of WhatsApp Users">
  </mat-paginator>
</div>
