<div *ngIf="showSpinner">
  <spinner></spinner>
</div>

<div class="content" *ngIf="!showSpinner">
  <div class="page-toolbar toolbar">
    <button mat-icon-button (click)="goBack()" matTooltip="Go back">
      <mat-icon class="toolbar-buttons">arrow_back</mat-icon>
    </button>
    <label>Online Users</label>
    <button mat-icon-button (click)="refreshOnlineUsers()" matTooltip="Refresh online users">
      <mat-icon class="toolbar-buttons">refresh</mat-icon>
    </button>
  </div>
  <div class="container">
    <div class="no-online-users" *ngIf="!showSpinner && onlineUsers.length == 0">
      <label>No online users</label>
    </div>
    <div class="online-users" *ngIf="!showSpinner && onlineUsers.length > 0">
      <div class="loggedInUsers" *ngFor="let user of onlineUsers; let i = index">
        <span></span>
        <label>{{ user.loginName }}</label>
      </div>
    </div>
  </div>
</div>
