import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RoomChatItemComponent } from './components/room-chat-item/room-chat-item.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { LoginComponent } from './page/login/login.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ClosedChatsComponent } from './page/closed-chats/closed-chats.component';
import { LocationComponent } from './components/location/location.component';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { AuthManager } from './services/auth/auth.manager';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { ChatsPanelComponent } from './components/chats-panel/chats-panel.component';
import { ChatsLandingPageComponent } from './page/chats-landing-page/chats-landing-page.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ChatItemComponent } from './components/chat-item/chat-item.component';
import { MessageItemComponent } from './components/message-item/message-item.component';
import { CloseChatDialogComponent } from './components/dialog/close-chat-dialog/close-chat-dialog.component';
import { AddEditChatNotesDialogComponent } from './components/dialog/add-edit-chat-notes-dialog/add-edit-chat-notes-dialog.component';
import { ReassignChatDialogComponent } from './components/dialog/reassign-chat-dialog/reassign-chat-dialog.component';
import { AddEditReferenceNumberDialogComponent } from './components/dialog/add-edit-reference-number-dialog/add-edit-reference-number-dialog.component';
import { EditWhatsappUserDialogComponent } from './components/dialog/edit-whatsapp-user-dialog/edit-whatsapp-user-dialog.component';
import { ChatRoomsComponent } from './page/chat-rooms/chat-rooms.component';
import { ClaimRoomChatDialogComponent } from './components/dialog/claim-room-chat-dialog/claim-room-chat-dialog.component';
import { ChatRoomFilterPipe } from './pipes/chat-room-filter.pipe';
import { ContactsComponent } from './page/contacts/contacts.component';
import { AddWhatsappUserDialogComponent } from './components/dialog/add-whatsapp-user-dialog/add-whatsapp-user-dialog.component';
import { TemplateManagerComponent } from './page/template-manager/template-manager.component';
import { OnlineUsersComponent } from './page/online-users/online-users.component';
import { MessageWindowComponent } from './components/message-window/message-window.component';
import { PreviewMessagesDialogComponent } from './components/dialog/preview-messages-dialog/preview-messages-dialog.component';
import { MessagesPreviewClaimChatDialogComponent } from './components/dialog/messages-preview-claim-chat-dialog/messages-preview-claim-chat-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    RoomChatItemComponent,
    LoginComponent,
    DialogComponent,
    SpinnerComponent,
    LocationComponent,
    ClosedChatsComponent,
    DropDownComponent,
    SideMenuComponent,
    ChatWindowComponent,
    ChatsPanelComponent,
    ChatsLandingPageComponent,
    ChatItemComponent,
    MessageItemComponent,
    CloseChatDialogComponent,
    AddEditChatNotesDialogComponent,
    ReassignChatDialogComponent,
    AddEditReferenceNumberDialogComponent,
    EditWhatsappUserDialogComponent,
    ChatRoomsComponent,
    ClaimRoomChatDialogComponent,
    ChatRoomFilterPipe,
    ContactsComponent,
    AddWhatsappUserDialogComponent,
    TemplateManagerComponent,
    OnlineUsersComponent,
    MessageWindowComponent,
    PreviewMessagesDialogComponent,
    MessagesPreviewClaimChatDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    DragDropModule,
    LeafletModule
  ],
  providers: [
    AuthManager
  ],
  entryComponents: [ToastMessageComponent],
  bootstrap: [AppComponent]
})
  
export class AppModule { }