import { Component, Inject, OnInit } from '@angular/core';
import { Message } from '../../../../interfaces/message.interfaces';
import { ChatApiService } from '../../../services/api/chat.api.service';
import { ChatService } from '../../../services/chats/chat.service';
import { DialogService } from '../dialog.service';
import { ToastMessageService } from '../../toast-message/toast.message.service';
import { DialogComponent } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimChatDialog } from '../../../../interfaces/dialog.interfaces';
import { Dropdown, Groups } from '../../../../interfaces/global.interfaces';
import { UserApiService } from '../../../services/api/user.api.service';
import { Router } from '@angular/router';
import { ClaimedChatsService } from '../../../services/chats/claimed.chats.service';
import { RoomChat } from '../../../../interfaces/chat.interfaces';
import { InternalMessage } from '../../../../interfaces/internal.message.interfaces';
import { InternalMessageType } from '../../../enums';
import { AuthService } from '../../../services/auth/auth.service';
import { SocketService } from '../../../services/socket.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'messages-preview-claim-chat-dialog',
  templateUrl: './messages-preview-claim-chat-dialog.component.html',
  styleUrls: ['./messages-preview-claim-chat-dialog.component.scss']
})
export class MessagesPreviewClaimChatDialogComponent implements OnInit {

  public groupOptions: Dropdown[] = [];
  public selectedGroupOptionId: number | null = null;
  public messages: Message[] = [];
  public whatsAppUserName: string | undefined = '';
  public chat: RoomChat | null = null;

  constructor(
    private chatApiService: ChatApiService,
    private chatService: ChatService,
    private userApiService: UserApiService,
    private dialogService: DialogService,
    private toastMessageService: ToastMessageService,
    public dialogRef: MatDialogRef<DialogComponent>,
    private router: Router,
    private claimedChatsService: ClaimedChatsService,
    private authService: AuthService,
    private socketService: SocketService,
    private error: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: ClaimChatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.chat = this.data.chats[0];
    this.dialogService.showSpinner = true;
    this.whatsAppUserName = this.chatService.getFormattedName(this.chat.firstName, this.chat.lastName, this.chat.whatsappUserNumber);
    await this.getMessagesForChatId();
    await this.getUsersGroups();
    this.dialogService.showSpinner = false;
  }

  private async getMessagesForChatId(): Promise<void> {
    if (!this.chat) return;
    try {
      const resp = await this.chatApiService.getMessagesForChatId(this.chat.chatId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to get messages for chat.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else this.messages = resp.result;
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get messages for chat.', 'toast-message-error');
      this.error.handleError('Failed to get messages for chat.', err, 'messages-preview-claim-chat-dialog.getMessagesForChatId()');
    }
  }

  private async getUsersGroups(): Promise<void> {
    try {
      const resp = await this.userApiService.getUsersGroups();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get groups.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        resp.result.map((group: Groups) => {
          this.groupOptions.push({
            label: group.txt,
            value: group.id
          });
        });
      }
    } catch (err) {
      this.toastMessageService.showToastMessage('Failed to get groups.', 'toast-message-error');
      this.error.handleError('Failed to get groups.', err, 'messages-preview-claim-chat-dialog.getUsersGroups()');
    }
  }

  public onGroupSelect(groupId: number): void {
    this.selectedGroupOptionId = groupId;
  }

  public async claimChat(): Promise<void> {
    if (!this.chat || !this.authService.userId || !this.selectedGroupOptionId) return;
    this.dialogService.showSpinner = true;
    try {
      const resp = await this.chatApiService.claimChat([this.chat.chatId], this.selectedGroupOptionId);
      if (!resp) this.toastMessageService.showToastMessage('Failed to claim chat.', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else {
        this.toastMessageService.showToastMessage('Chat claimed.', 'toast-message-success');
        this.claimedChatsService.claimedChatIdFromRoom = this.chat.chatId;
        const internalMessage: InternalMessage = {
          internalMessage: {
            chats: this.data.chats,
            serverUserId: this.authService.userId
          },
          internalMessageType: InternalMessageType.NOTIFY_USERS_CHAT_CLAIMED
        };
        this.socketService.sendInternalMessage(internalMessage);
        this.cancel();
        this.router.navigate(['/chats']);
      }
    } catch (err) {
      this.error.handleError('Failed to claim chat.', err, 'messages-preview-claim-chat-dialog.claimChat()');
      this.toastMessageService.showToastMessage('Failed to claim chat.', 'toast-message-error');
    }
    this.dialogService.showSpinner = false;
  }

  public cancel() {
    this.dialogRef.close();
  }
}