<div class="content">
  <h2 mat-dialog-title class="title">Add/Update Ref Number</h2>
  <div mat-dialog-content>
    <mat-form-field class="form-field">
      <mat-label>Reference Number</mat-label>
      <input name="referenceNumber" required matInput [(ngModel)]="referenceNumber">
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-button (click)="updateReferenceNumber()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
