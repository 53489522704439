import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Socket, io } from 'socket.io-client/build/index';
import { AuthService } from './auth/auth.service';
import { Message } from '../../interfaces/message.interfaces';
import { ClaimChatInternalMessage, CloseChatInternalMessage, DisconnectInternalMessage, InternalMessage, ReassignChatInternalMessage } from '../../interfaces/internal.message.interfaces';
import { InternalMessageType } from '../enums';
import { ClaimedChatsService } from './chats/claimed.chats.service';
import { NotificationService } from './notification.service';
import { BrowserService } from './browser.service';
import { MessageService } from './message.service';
import { ChatRoomService } from './chats/chat.room.service';
import { Router } from '@angular/router';
import { ToastMessageService } from '../components/toast-message/toast.message.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private _connected: boolean = false;
  private _timeout: boolean = false;
  private _notConnectedReason: string = '';
  private socket: Socket = {} as Socket;

  public get timeout(): boolean { return this._timeout; }

  public get connected(): boolean { return this._connected; }

  public get notConnectedReason(): string { return this._notConnectedReason; }

  public set connected(value: boolean) { this._connected = value; }

  public set timeout(value: boolean) { this._timeout = value; }

  public set notConnectedReason(value: string) { this._notConnectedReason = value; }

  constructor(
    private auth: AuthService,
    private notificationService: NotificationService,
    private claimedChatsService: ClaimedChatsService,
    private chatRoomService: ChatRoomService,
    private messageService: MessageService,
    private browserService: BrowserService,
    private toastMessageService: ToastMessageService,
    private router: Router
  ) { }

  public setup() {
    this.socket = io(environment.socketURL, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000
    });

    //socket events
    this.socket.on('internalMsg', (msg: InternalMessage) => this.internalMessage(msg));
    this.socket.on('disconnect', () => this.disconnect());
    this.socket.on('connect', () => this.connect());
  }

  private connect() {
    this.connected = true;
    this.timeout = false;
    this.socket.emit('join', {
      userId: this.auth.userId,
      loginName: this.auth.loginName
    });
    this.auth.lostConnection = false;
  }

  //used in side menu component html
  public logout() {
    this.socket.disconnect();
    this.auth.logout();
    this.connected = false;
  }

  public disconnect(): void {
    this.timeout = true;
    this.connected = false;
    this.notConnectedReason = 'Connection lost to server. Please wait while we try to reconnect';
  }

  private async internalMessage(msg: InternalMessage) {
    switch (msg.internalMessageType) {
      case InternalMessageType.USER_LOGGED_CLAIM_CHATS:
        this.claimedChatsService.handleUserLoggedClaimedChats(msg.internalMessage.claimedChats, msg.internalMessage.loginName);
        break;

      case InternalMessageType.DISCONNECT:
        await this.handleUserDisconnect(msg.internalMessage);
        break;

      case InternalMessageType.INBOUND_MESSAGE:
        this.inboundWhatsappMessage(msg.internalMessage.message);
        break;

      case InternalMessageType.NOTIFY_USERS_CHAT_CLAIMED:
        this.handleUserClaimChats(msg.internalMessage);
        break;

      case InternalMessageType.CHAT_CLOSE:
        this.handleChatClose(msg.internalMessage);
        break;

      case InternalMessageType.PRODUCT_CHANGE:
        this.claimedChatsService.handleProductChange(msg.internalMessage.userId, msg.internalMessage.loginName, msg.internalMessage.productName);
        break;

      case InternalMessageType.MESSAGE_STATUS_UPDATE:
        this.messageService.handleMessageStatusupdate(msg.internalMessage);
        break;

      case InternalMessageType.REASSIGN_CHAT:
        this.handleReassignChat(msg.internalMessage);
        break;
    }
  }

  private inboundWhatsappMessage(message: Message): void {
    this.claimedChatsService.handleInboundWhatsappMessage(message);

    if ((this.claimedChatsService.getSelectedClaimChat?.chatId !== message.chatId || this.claimedChatsService.getSelectedClaimChat?.chatId === message.chatId) && !this.browserService.browserFocused) {
      this.notificationService.messageReceivedNotification(message);
    } else if (!this.claimedChatsService.getSelectedClaimChat && !this.browserService.browserFocused) {
      this.notificationService.messageReceivedNotification(message);
    } else if (!this.claimedChatsService.getSelectedClaimChat && this.browserService.browserFocused) {
      this.notificationService.messageReceivedNotification(message);
    }
  }

  private async handleUserDisconnect(msg: DisconnectInternalMessage) {
    this.claimedChatsService.handleUserDisconnect(msg.serverUserId, msg.loginName);
    //only get chat rooms if the user is on the chat rooms screen
    if (this.router.url === '/chat-rooms') await this.chatRoomService.getChatRooms();
  }

  private handleUserClaimChats(msg: ClaimChatInternalMessage) {
    const chatIds: number[] = msg.chats.map(c => c.chatId);
    this.chatRoomService.handleRemovingChatsFromRoom(chatIds);
  }

  private handleChatClose(msg: CloseChatInternalMessage) {
    this.claimedChatsService.handleRemovingClaimedChats(msg.chatIds);
    this.chatRoomService.handleRemovingChatsFromRoom(msg.chatIds);
  }

  private handleReassignChat(msg: ReassignChatInternalMessage) {
    if (!this.auth.user || !this.auth.user.id || !this.auth.user.loginName) return;
    this.claimedChatsService.handleChatsReassignToUser(msg.chats, this.auth.user.id, this.auth.user.loginName);
    this.toastMessageService.showToastMessage(`You have been assigned ${msg.chats.length} chat(s) from ${msg.loginName}`, 'toast-message-info');
  }

  public async sendInternalMessage(internalMessage: InternalMessage) {
    if (!this.socket.connected) return;

    this.socket.emit('internalMsg', internalMessage);
    this.auth.resetUserSessionTimer();
  }
}