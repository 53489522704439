import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ChatService } from '../../services/chats/chat.service';
import * as moment from 'moment';
import { RoomChat } from '../../../interfaces/chat.interfaces';
import { Message } from '../../../interfaces/message.interfaces';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'room-chat-item',
  templateUrl: './room-chat-item.component.html',
  styleUrls: ['./room-chat-item.component.scss']
})
export class RoomChatItemComponent {

  @Input() chat: RoomChat = {} as RoomChat;
  @Input() multiselectMode: boolean = false;

  @Output() claimChatMessages: EventEmitter<RoomChat> = new EventEmitter<RoomChat>();
  @Output() onClose: EventEmitter<RoomChat> = new EventEmitter<RoomChat>();
  @Output() onChatSelection: EventEmitter<RoomChat> = new EventEmitter<RoomChat>();

  public whatsAppDisplayName: string = '';
  public lastMessage: Message = {} as Message;
  public messageBody: string = '';
  public messageTimeStamp: string = '';
  public messageStatusIcon: string = '';
  public messageStatusIconClass: string = '';
  public messageMediaIcon: string = '';
  public unreadMessageCount: number = 5;
  public lastClaimedBy: string = '';
  public chatExpired: boolean = false;
  public chatSelected: boolean = false;

  constructor(
    private chatService: ChatService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.lastMessage = this.chat.messages[this.chat.messages.length - 1];
    this.messageBody = this.lastMessage.body;
    this.handleWhatsAppUserName();
    this.handleMessageTimeStamp();
    this.handleMessageStatusIcon();
    this.handleMessageMediaIcon();
    this.handleLastClaimedBy();
    this.chatExpired = this.chatService.hasChatExpired(this.chat.chatExpiryDate)
  }

  public chatSelect() {
    if (!this.multiselectMode) return;
    
    this.chat.chatSelected = !this.chat.chatSelected;
    this.onChatSelection.emit(this.chat);
  }

  public closeChat() {
    this.chat.chatSelected = !this.chat.chatSelected;
    this.onClose.emit(this.chat)
  }

  public claim() {
    this.chat.chatSelected = !this.chat.chatSelected;
    this.claimChatMessages.emit(this.chat);
  }
  
  private handleWhatsAppUserName(): void {
    if (this.chat.firstName && this.chat.lastName) this.whatsAppDisplayName = `${this.chat.firstName} ${this.chat.lastName}`;
    else if (this.chat.firstName) this.whatsAppDisplayName = this.chat.firstName;
    else this.whatsAppDisplayName = this.chat.whatsappUserNumber;
  }

  private handleMessageTimeStamp(): void {
    const message = moment(new Date(this.lastMessage.sendDate));
    if (message.isSame(moment(), 'day')) this.messageTimeStamp = moment(message).format('hh:mm a')
    else if (message.isSame(moment().clone().subtract(1, 'day'), 'day')) this.messageTimeStamp = 'Yesterday';
    else this.messageTimeStamp = moment(message).format('YYYY/MM/DD');
  }

  private handleMessageStatusIcon(): void {
    if (this.lastMessage.undeliveredDate) {
      this.messageStatusIcon = 'error';
      this.messageStatusIconClass = 'error';
    }
    else if (this.lastMessage.readDate) {
      this.messageStatusIcon = 'done_all';
      this.messageStatusIconClass = 'read';
    }
    else if (this.lastMessage.deliveredDate) this.messageStatusIcon = 'done_all';
    else if (this.lastMessage.sendDate) this.messageStatusIcon = 'done';
  }

  private handleMessageMediaIcon(): void {
    //last message is not a media message
    if (!this.lastMessage || !this.lastMessage.mediaUrl || !this.lastMessage.fileName) return;

    this.messageBody = this.lastMessage.fileName;

    const result = this.messageService.isMessageAFile(this.lastMessage.extension);
    if (!result) return;

    //if the media message has a friendly name we will set the message body to the friendly name
    if (this.lastMessage.body && this.lastMessage.friendlyName) this.messageBody = this.lastMessage.body;
      
    //setting the message media icon  
    if (result.isMessageAnImage) this.messageMediaIcon = 'image';
    else if (result.isMessageAFile) this.messageMediaIcon = 'file_copy';
    else if (result.isMessageAudio) this.messageMediaIcon = 'mic';
    else if (result.isMessageVideo) this.messageMediaIcon = 'videocam';
    else this.messageMediaIcon = 'insert_drive_file';
  }

  private handleLastClaimedBy() {
    if (!this.chat.lastClaimedBy) this.lastClaimedBy = '';
    else this.lastClaimedBy = this.chatService.getFormattedName(this.chat.lastClaimedBy.split('@')[0]) ?? this.chat.lastClaimedBy;
  }
}