<div *ngIf="message" class="message"
  [ngClass]="{ 'sent': message.direction === 1, 'received': message.direction === 0 }">
  <div class="content">
    <label *ngIf="!isMessageMedia">
      <span [innerHTML]="messageBody"></span>
    </label>
    <div class="media" *ngIf="isMessageMedia">

      <div [ngClass]="{'file': !isMessageAnImage }">
        <!-- Image -->
        <div class="image-container" *ngIf="isMessageAnImage" (click)="previewImage.emit(message.mediaUrl)">
          <img [src]="messageService.sanitizeImageUrl(message.mediaUrl)">
        </div>

        <!-- Audio -->
        <div *ngIf="isMessageAudio">
          <audio controls [src]="message.mediaUrl" #audioPlayer>
            Your browser does not support the audio element.
          </audio>
        </div>

        <!-- Video -->
        <div *ngIf="isMessageVideo" class="video">
          <video controls [src]="message.mediaUrl" #videoPlayer style="color: white;">
            Your browser does not support the video element.
          </video>
        </div>

        <!-- File (PDF) -->
        <div *ngIf="isMessageAFile">
          {{ message.friendlyName }}
        </div>

        <!-- File Action buttons -->
        <div class="actions" *ngIf="!isMessageAnImage">
          <button mat-button (click)="openFile()">
            Open
          </button>
          <button mat-button (click)="convertHttpResponseToBase64()">
            Save
          </button>
        </div>
      </div>

      <label>{{ message.body  }}</label>
    </div>
    <label class="message-error" *ngIf="!message.extension && !message.body">We had a problem displaying this
      message.</label>
  </div>
  <div class="footer">
    <label class="sent-by">{{ handleMessageSentBy() }}</label>
    <label>{{ messageTimeStamp }}</label>
    <mat-icon *ngIf="message.direction == 1"
      class="status
      {{ messageService.handleMessageStatusIcon(message.sendDate, message.readDate, message.deliveredDate, message.undeliveredDate).messageStatusIconClass }}">
      {{ messageService.handleMessageStatusIcon(message.sendDate, message.readDate, message.deliveredDate, message.undeliveredDate).messageStatusIcon }}
    </mat-icon>
  </div>
</div>
