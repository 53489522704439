export const environment = {
  production: false,

  // live
  socketURL: "https://avecscomms.com:3022",
  serverURL: "https://avecscomms.com:3021",

  //newuat
  // socketURL: "http://whatsapp.marsserver.net:3022",
  // serverURL: "http://whatsapp.marsserver.net:3021",

  // local
  // socketURL: "http://localhost:3022",
  // serverURL: "http://localhost:3021",

  //template broadcasting dev tool to show up
  //set to false when deploying
  devMode: false
};