import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType } from '../../enums';
import { DialogService } from './dialog.service';
import { Dialog } from '../../../interfaces/dialog.interfaces';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  public get dialogType(): typeof DialogType { return DialogType; }

  constructor(
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog
  ) { }
  
  public close(): void { this.dialogRef.close(); }
}