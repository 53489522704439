import { Component } from '@angular/core';
import { ClaimedChatsService } from '../../services/chats/claimed.chats.service';

@Component({
  selector: 'app-chats-landing-page',
  templateUrl: './chats-landing-page.component.html',
  styleUrls: ['./chats-landing-page.component.scss']
})
export class ChatsLandingPageComponent {

  constructor(
    public claimedChatsService: ClaimedChatsService // used in html
  ) { }
}