<div class="loading" *ngIf="!reason">
  <mat-spinner class="spinner" [diameter]="70" color="primary"></mat-spinner>
</div>

<div class="loading full-page" *ngIf="reason">
  <mat-card>
    <mat-spinner [diameter]="70" color="primary"></mat-spinner>
    <div class="reason">
      <label class="reason-label" *ngIf="reason">{{ reason }}</label>
    </div>
  </mat-card>
</div>
