<div *ngIf="imagePreview" class="imagePreview">
  <div class="header">
    <button mat-icon-button matTooltip="Back" matTooltipPosition="right" (click)="imagePreview = null">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="image-container">
    <img (load)="initializeRotation()" #image [src]="messageService.sanitizeImageUrl(imagePreview)">
  </div>
  <div class="footer">
    <button mat-icon-button matTooltip="Rotate Left" matTooltipPosition="right" (click)="rotateImage(-90)">
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Rotate Right" matTooltipPosition="right" (click)="rotateImage(90)">
      <mat-icon>rotate_right</mat-icon>
    </button>
  </div>
</div>
<div #messageWindow class="message-window" (scroll)="onScroll()" [style]="{'opacity': !imagePreview ? '1' : '0'}">
  <div class="message-date-stamp" *ngFor="let dm of messageService.displayMessages">
    <div class="chat-time-stamp">
      <label>{{ dm.date }}</label>
    </div>

    <div *ngFor="let message of dm.messages">
      <message-item id="message-{{ message.messageId }}" [message]="message"
        (previewImage)="imagePreview = $event; currentImageRotation = 0;"
        [messageMatch]="isMessageSearchMatch(message.messageId)" [searchTerm]="searchTerm">
      </message-item>
    </div>
  </div>

  <div class="scroll-bottom">
    <mat-icon (click)="smoothScrollToBottom()" [style]="{'display': showScrollIcon ? 'block' : 'none'}">arrow_downward
    </mat-icon>
  </div>
</div>
