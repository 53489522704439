import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserApiService } from '../../services/api/user.api.service';
import { ToastMessageService } from '../../components/toast-message/toast.message.service';
import { Contacts } from '../../../interfaces/user.interfaces';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogType } from '../../../app/enums';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) public paginator: MatPaginator | null = null;
  @ViewChild(MatSort) public sort: MatSort | null = null;
  public whatsappUsers: MatTableDataSource<Contacts> = new MatTableDataSource<Contacts>([]);
  public displayedColumns: string[] = ['firstName', 'lastName', 'whatsappUserNumber', 'lastChatDate', 'notes', 'editUser'];

  public showSpinner: boolean = false;

  constructor(
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService,
    private location: Location,
    private error: ErrorService,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  ngAfterViewInit(): void {
    this.whatsappUsers.paginator = this.paginator;
    this.whatsappUsers.sort = this.sort;
  }

  public async refresh(): Promise<void> {
    this.showSpinner = true;
    await this.getAllWhatsappUsers();
    this.showSpinner = false;
  }

  private async getAllWhatsappUsers(): Promise<void> {
    try {
      const resp = await this.userApiService.getContacts();
      if (!resp) this.toastMessageService.showToastMessage('Failed to get whatsapp users', 'toast-message-error');
      else if (resp.errorCode != 0) this.toastMessageService.showToastMessage(resp.errorMessage, 'toast-message-error');
      else this.whatsappUsers.data = resp.result;
    } catch (err) {
      this.error.handleError('Failed to get whatsapp users.', err, 'contacts.getAllWhatsappUsers()');
      this.toastMessageService.showToastMessage('Failed to get whatsapp users', 'toast-message-error');
    }
  }

  public addWhatsappUserDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { dialogTypeId: DialogType.ADD_WHATSAPP_USER },
      autoFocus: false
    });
  }

  public editWhatsappUserDialog(whatsappUser: Contacts): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        dialogTypeId: DialogType.EDIT_WHATSAPP_USER,
        whatsappUserId: whatsappUser.whatsappUserId,
        firstName: whatsappUser.firstName,
        lastName: whatsappUser.lastName
      },
      autoFocus: false 
    });

    dialogRef.afterClosed().subscribe((result: Contacts | undefined) => {
      if (!result) return;

      whatsappUser.firstName = result.firstName;
      whatsappUser.lastName = result.lastName;
    });
  }

  public searchWhatsappUsers(search: string) {
    this.whatsappUsers.filter = search.trim().toLowerCase();
  }

  public goBack() {
    this.location.back();
  }
}