<spinner *ngIf="showSpinner"></spinner>

<div class="chats-panel">
  <div class="heading">
    <div class="heading-content">
      <div class="user-information">
        <img *ngIf="productService.bigLogo != null" [src]="productService.bigLogo" alt="Avecs">
        <div class="details">
          <label>{{ authService.formattedLoginName }}</label>
          <drop-down (onDropdownOptionSelect)="productChange($event)" filter="true" placeHolder="Select a product"
            [selectedDropDown]="selectedProduct" filterPlaceHolder="Filter Products" [options]="productOptions"
            [showClear]="false">
          </drop-down>
        </div>
      </div>
      <div class="top">
        <label>{{ claimedChatsService.chatsHeading }} ({{ claimedChatsService.claimedChatsPanelChatCount }})</label>
        <mat-checkbox matTooltip="Multiselect Mode" matTooltipPosition="right" [checked]="multiSelectMode"
          (change)="multiSelectModeChange()">
        </mat-checkbox>
        <button *ngIf="multiSelectMode" class="multimode" mat-icon-button matTooltip="Multiselect Chat Options"
          [matMenuTriggerFor]="multiSelectChatOptions" matTooltipPosition="right">
          <mat-icon>menu</mat-icon>
        </button>
        <button class="filter" mat-icon-button [matMenuTriggerFor]="filterMenu" matTooltip="Filter Options"
          matTooltipPosition="right">
          <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu style="display: none;" #filterMenu="matMenu">
          <div class="filter-options">
            <div class="heading">
              <label>Filter chats by</label>
            </div>
            <button class="option" mat-menu-item *ngFor="let option of claimedChatsService.filterChatsOptions"
              (click)="claimedChatsService.filterChatOption(option)" [ngClass]="{'selected': option ===
              claimedChatsService.selectedFilterChatOption }">
              <mat-icon>{{ option.icon }}</mat-icon>
              {{ option.label }}
            </button>
          </div>
        </mat-menu>
        <mat-menu style="display: none;" #multiSelectChatOptions="matMenu">
          <div class="filter-options">
            <div class="heading">
              <label>Multi-mode options</label>
            </div>
            <button class="option" mat-menu-item *ngFor="let option of multiModeOptions"
              (click)="option.method.call(this)" [disabled]="selectedClaimedChats.length == 0">
              <mat-icon>{{ option.icon }}</mat-icon>
              {{ option.label }} ({{ selectedClaimedChats.length }})
            </button>
          </div>
        </mat-menu>
      </div>
      <mat-form-field appearance="fill" class="search-chats">
        <mat-label>Search Chats</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (input)="claimedChatsService.searchClaimedChats()" matInput [(ngModel)]="claimedChatsService.search"
          placeholder="Firstname, lastname, ref, cell no" type="text">
        <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="claimedChatsService.clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="chats">
    <label
      *ngIf="!showSpinner && (!claimedChatsService.claimedChatsPanel || claimedChatsService.claimedChatsPanel.length == 0)">No
      chats</label>
    <mat-accordion multi *ngIf="claimedChatsService.claimedChatsPanel?.length != 0">
      <mat-expansion-panel *ngFor="let gc of claimedChatsService.claimedChatsPanel" [expanded]="gc.groupExpanded"
        (opened)="gc.groupExpanded = true" (closed)="gc.groupExpanded = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ gc.groupName }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let chat of gc.chats">
          <chat-item [chat]="chat" [multiSelectMode]="multiSelectMode" (onChatSelect)="chatSelected($event)">
          </chat-item>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
