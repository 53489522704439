import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { AuthManager } from './services/auth/auth.manager';
import { ChatsLandingPageComponent } from './page/chats-landing-page/chats-landing-page.component';
import { ChatRoomsComponent } from './page/chat-rooms/chat-rooms.component';
import { ContactsComponent } from './page/contacts/contacts.component';
import { TemplateManagerComponent } from './page/template-manager/template-manager.component';
import { ClosedChatsComponent } from './page/closed-chats/closed-chats.component';
import { OnlineUsersComponent } from './page/online-users/online-users.component';

const routes: Routes = [
  {
    path: 'chats',
    component: ChatsLandingPageComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'chat-rooms',
    component: ChatRoomsComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'online-users',
    component: OnlineUsersComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'template-manager',
    component: TemplateManagerComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'closed-chats',
    component: ClosedChatsComponent,
    canActivate: [AuthManager]
  },
  {
    path: 'error',
    component: LoginComponent
  },
  {
    path: 'accessdenied',
    component: LoginComponent
  },
  {
    path: '404',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }