<div class="content">
  <h2 mat-dialog-title class="title">Reassign Chat</h2>
  <drop-down *ngIf="userOptions && userOptions.length > 0" filter=true showClear="true" placeHolder="Select a user"
    [options]="userOptions" [disabled]="selectedGroupOptionId ? true : false" filterPlaceHolder="Filter users"
    (onDropdownOptionSelect)="onUserSelect($event)">
  </drop-down>

  <drop-down *ngIf="groupOptions && groupOptions.length > 0" [disabled]="selectedUserOptionId ? true : false"
    filter=true showClear="true" placeHolder="Select a group" [options]="groupOptions" filterPlaceHolder="Filter groups"
    (onDropdownOptionSelect)="onGroupSelect($event)">
  </drop-down>

  <div class="actions">
    <button mat-button [disabled]="!(selectedGroupOptionId || selectedUserOptionId)"
      (click)="reassignChat()">Reassign</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
