import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../error.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { HttpResponse } from '../../../interfaces/result.interfaces';
import { Products, User } from '../../../interfaces/user.interfaces';
import { BehaviorSubject } from 'rxjs';
import { LoginRequest } from 'src/interfaces/global.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private _tokenExpiryTime: any = undefined;
  private _sessionValidMins: number = 0;
  private _user: User | null = null;
  private _isTimedOut: boolean = false;
  private _userProducts: Products[] = [];

  public lostConnection: boolean = true;
  public reasonLostConnection: string = '';
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  public loggedIn = this.loggedInSubject.asObservable();

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private router: Router
  ) {
    this.loggedInSubject.next(this.isTokenValid());
    this.setupTokenExpirationCheck();
  }
  
  public get user(): User | null { return this._user; }

  public get loginName(): string | null { return this.user?.loginName || null; }
  
  public get formattedLoginName(): string {
    if (this.user && this.user.loginName) {
      return this.user.loginName[0].toUpperCase() + this.user.loginName.slice(1);
    } else return '';
  }

  public get userProducts(): Products[] { return this._userProducts; }

  public get isTimedOut(): boolean { return this._isTimedOut; }

  public get userId(): number | null { return this._user?.id || null; }

  public get token(): string | null { return this._user?.token || null; }

  public get tokenExpiryTime(): any { return this._tokenExpiryTime; }

  public set user(user: User | null) { this._user = user; }

  public set tokenExpiryTime(value: any) { this._tokenExpiryTime = value; }

  public set timedOut(value: boolean) { this._isTimedOut = value; }

  private set setUser(user: User) { this._user = user; }

  public set userProducts(userProducts: Products[]) { this._userProducts = userProducts; }

  public set setProduct(product: Products) {
    if (this.user && this.userProducts) {
      const existingProduct = this.userProducts.find(p => p.isDefaultProduct == 1);

      //if the existing product id is the same as the product id we are changing to
      //don't need to change isDefaultProduct
      if (existingProduct?.productId == product.productId) {
        this.user.selectedProduct = product;
        this.setSessionStorage();
        return;
      }
      
      const index = this.userProducts.findIndex(x => x.isDefaultProduct == 1);
      if (index >= 0) this.userProducts[index].isDefaultProduct = 0;
      
      this.user.selectedProduct = product;
      this.setSessionStorage();

      const newIndex = this.userProducts.findIndex(x => x.productId == product.productId);
      if (newIndex >= 0) this.userProducts[newIndex].isDefaultProduct = 1;
    }
  }

  public async loginUser(username: string, password: string): Promise<HttpResponse | null> {
    try {
      const requestBody: LoginRequest = {
        loginName: username,
        password: password
      };

      const resp = await this.http.post<HttpResponse>(`${environment.serverURL}/user/loginUser`, requestBody).toPromise();
      if (resp.errorCode === 0) {
        this.loggedInSubject.next(true);
        this.handleSuccessfulLogin(username, resp);
      }
      return resp;
    }
    catch (err) {
      this.error.handleError('Failed to login user', err, 'auth.service.loginUser');
      throw err;
    }
  }

  public async regUser(username: string, password: string): Promise<HttpResponse | null> {
    try {
      const requestBody: LoginRequest = {
        loginName: username,
        password: password
      };

      const resp = await this.http.post<HttpResponse>(`${environment.serverURL}/user/registerUser`, requestBody).toPromise();
      if (resp.errorCode === 0) {
        this.handleSuccessfulLogin(username, resp);
      } else {
        this.error.handleError(resp.errorMessage, null, 'AuthService.loginUser');
      }
      return resp;
    }
    catch (err) {
      this.error.handleError('Failed to register user', err, 'AuthService.regUser');
      throw err;
    }
  }

  private handleSuccessfulLogin(username: string, resp: HttpResponse) {
    this.user = {
      id: resp.result.userId,
      loginName: username,
      token: resp.result.sessionId,
      selectedProduct: null
    };
    this._sessionValidMins = resp.result.sessionValidMins;
    this.resetUserSessionTimer();
    this._isTimedOut = false;
    this.setSessionStorage();
  }

  public isTokenValid(): boolean {
    if (this.user && this.user.token) {
      return true;
    } else {
      const user = this.getSessionStorage();
      if (user && user.token) {
        this.lostConnection = false;
        return true;
      }
      else return false;
    }
  }

  public clearUser() {
    this.user = null;
    this.clearSessionStorage();
    this.tokenExpiryTime = undefined;
  }

  private clearSessionStorage() {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('loginName');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('selectedProduct');
    sessionStorage.removeItem('sessionValidMins');
    sessionStorage.clear();
  }

  private setSessionStorage(): void {
    sessionStorage.userId = this.user?.id;
    sessionStorage.loginName = this.user?.loginName;
    sessionStorage.token = this.user?.token;
    sessionStorage.selectedProduct = JSON.stringify(this.user?.selectedProduct);
    sessionStorage.sessionValidMins = this._sessionValidMins;
  }

  private getSessionStorage(): User | null {
    this.setUser = {
      id: +sessionStorage.userId,
      loginName: sessionStorage.loginName,
      token: sessionStorage.token,
      selectedProduct: sessionStorage.selectedProduct ? JSON.parse(sessionStorage.selectedProduct) as Products : null
    };
    this._sessionValidMins = +sessionStorage.sessionValidMins;

    return this.user;
  }

  public logout() {
    this.loggedInSubject.next(false);
    this.clearUser();
    this.router.navigate(['/login']);
  }

  public resetUserSessionTimer() {
    if (!this.user) return;
    this.tokenExpiryTime = moment(new Date).add(this._sessionValidMins, 'm').toDate();
  }

  private setupTokenExpirationCheck() {
    setInterval(() => {
      if (this.user && this.user.token && this.tokenExpiryTime) {
        const duration = moment.duration(moment(this.tokenExpiryTime).diff(moment(new Date()).toDate()));
        this.timedOut = duration.asSeconds() < 0;
        if (this.isTimedOut) {
          this.logout();
        }
      }
    }, 15000);
  }
}