import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastMessageComponent } from './toast-message.component';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(private snackBar: MatSnackBar) { }

  //available horizontal position = 'start' | 'center' | 'end' | 'left' | 'right'
  //available vertical position = 'top' | 'bottom'
  //panelClass is the css class for the toast message, e.g. toast-message-error
  public showToastMessage(message: string, panelClass: string = '', durationInMs: number = 3000, verticalPosition: string = 'top', horizontalPosition: string = 'right') {
    const config: MatSnackBarConfig = {
      data: { message },
      duration: durationInMs,
      horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
      verticalPosition: verticalPosition as MatSnackBarVerticalPosition,
      panelClass: panelClass
    };

    this.snackBar.openFromComponent(ToastMessageComponent, config);
  }
}