<div class="content">
  <h2 class="title">Add Whatsapp User</h2>
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input name="firstName" required matInput [(ngModel)]="firstName">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input name="lastName" required matInput [(ngModel)]="lastName">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Number</mat-label>
    <mat-icon matSuffix>phone</mat-icon>
    <input name="number" required matInput [(ngModel)]="cellPhoneNumber">
  </mat-form-field>
  <div class="actions">
    <button mat-button [disabled]="canAddWhatsAppUser()" (click)="addWhatsappUser()">Save</button>
    <button mat-button (click)="cancel()" class="form-button">Cancel</button>
  </div>
</div>
