import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { ErrorService } from './error.service';
import { environment } from '../../environments/environment';
import { HttpResponse } from '../../interfaces/result.interfaces';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private error: ErrorService
  ) { }

  public async get(caller: string, route: string): Promise<HttpResponse | null> {
    try {
      if (!this.auth.token) return null;
      const headers = new HttpHeaders()
        .set('token', this.auth.token)
        .set('user-id', `${this.auth.userId}`)
        .set('user-name', `${this.auth.loginName}`)
        .set('content-type', 'application/json')
        .set('accept-language', ['en-US', 'en', 'q=0.9'])
        .set('accept', ['application/json', 'text/plain', '*/*']);
        
      const resp = await this.http.get<any>(`${environment.serverURL}/${route}`, { headers: headers }).toPromise();
      if (resp.errorCode == 999) this.auth.logout();
      else this.auth.resetUserSessionTimer();
      return resp;
    }
    catch (err) {
      this.error.handleError(`${caller} failed`, err, caller);
    }
    return null;
  }

  public async post(caller: string, route: string, json: any): Promise<HttpResponse | null> {
    try {
      if (!this.auth.token) return null;
      const headers = new HttpHeaders()
        .set('token', this.auth.token)
        .set('user-id', `${this.auth.userId}`)
        .set('user-name', `${this.auth.loginName}`)
        .set('content-type', 'application/json')
        .set('accept-language', ['en-US', 'en', 'q=0.9'])
        .set('accept', ['application/json', 'text/plain', '*/*']);
      
      const resp = await this.http.post<any>(`${environment.serverURL}/${route}`, json, { headers: headers }).toPromise();
      if (resp.errorCode == 999) this.auth.logout();
      else this.auth.resetUserSessionTimer();
      return resp;
    }
    catch (err) {
      this.error.handleError(`${caller} failed`, err, caller);
    }
    return null;
  }
}