<div class="content">
  <h2 class="title">Add / Edit Notes</h2>
  <mat-form-field class="form-field">
    <textarea placeholder="Enter chat notes here..." matInput [(ngModel)]="notes"></textarea>
  </mat-form-field>
  <div class="actions">
    <button mat-button (click)="saveNotes()">Save</button>
    <button mat-button (click)="cancel()" class="form-button">Cancel</button>
  </div>
</div>
